<template>
    <div class="flex justify-between mb-4" >
        <div class="flex space-x-2">
            <p class="text-sm flex items-center">Rows per page</p>
            <select v-model="rowPerpage" class="border p-0.5 focus:outline-none " name="" id="">
                <option value="10">10</option>
                <option value="25" >25</option>
                <option value="50" >50</option>
            </select>
        </div>
        <div class="flex space-x-3 border border-gray-100 p-0.5 px-3">
            <input type="text" @input="sortList" v-model="this.search"  placeholder="Search" class="focus:outline-none p-2">
            <button>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
            </button>
        </div>
    </div>
    <table class="w-full" >
        <thead class="bg-gray-100 border-gray-100 border">
            <tr class="">
                <th v-for="row in rows" :key="row" class="text-sm py-3 px-3 text-left" >{{ row }}</th>
            </tr>
        </thead>
        <tbody v-if="Resturants.length < 1">
            <tr class="border border-t-0" >
                <td  class="text-sm p-3 text-center" :colspan="rows.length">{{ msg }}</td>  
            </tr>
        </tbody>
        <tbody>
            <tr v-for="col in Resturants.slice(startPage, Endpage)" :key="col" class="border border-t-0">
                <td class="text-sm p-3" >{{ col.name }} </td>
                <td class="text-sm p-3">{{ col.email }} </td>
                <td class="text-sm p-3">{{ col.phoneNumber}} </td>
                <td class="text-sm p-3">{{ col.address }} </td>
                <td class="text-sm p-3">{{ col.contactPersonName}} </td>
                <td class="text-sm p-3">
                     <button @click="togglePublish(col.available,col.restaurantId)" :data-name ="'restaurant'+ col.restaurantId" :class="col.available ? 'bg-green-400' : null" class="border border-gray-200 bg-gray-200 h-5 w-10 flex items-center rounded-full" >
                        <div :class="col.available ? 'translate-x-5' : null" class="h-4 w-4 flex bg-white rounded-full transition duration-500 transform" ></div>
                     </button>
                     <span v-if="col.available" class="text-xs" >Active</span>
                     <span v-else>inactive</span>
                 </td>
                <td class="w-32">
                    <button @click="getsingleRestaurant(col.restaurantId)" class="text-purple-500" >
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                      </svg>
                    </button>
                    <button @click="editModal(col.restaurantId)" class="hidden" >
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                        </svg>
                    </button>
                    <button @click="setCommision(col.restaurantId)" class="text-gray-900" >
                       <svg class="h-6 w-6"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="4" y="3" width="16" height="18" rx="2" />  <rect x="8" y="7" width="8" height="3" rx="1" />  <line x1="8" y1="14" x2="8" y2="14.01" />  <line x1="12" y1="14" x2="12" y2="14.01" />  <line x1="16" y1="14" x2="16" y2="14.01" />  <line x1="8" y1="17" x2="8" y2="17.01" />  <line x1="12" y1="17" x2="12" y2="17.01" />  <line x1="16" y1="17" x2="16" y2="17.01" /></svg>
                    </button>
                    
                </td>
            </tr>
        </tbody>
    </table>
    <div class="flex justify-between bg-gray-100 h-12 px-4  items-center " >
        <div> {{ page + 1 }} of {{ numberofPages }} pages (Showing {{ getFirstItem }} - {{ getLastItem }} of {{ columns.totalElements }} Entries)</div>
        <div class="flex flex-grow  justify-end" >
            <div class="flex space-x-1">
                <button @click="prev(currentPage)" class="pagination-btn active text-sm">Prev</button>
                <input type="number" :min="1" :max="numberofPages" :value="page + 1 " @input="changeEnteredPage($event)"  @change="changeEnteredPage($event)"  class="border focus:outline-none w-14 border-gray-300  px-3 ">  
                <button @click="next(currentPage)" class="pagination-btn active text-sm">Next</button>
            </div>
        </div>
    </div>
    <Modal v-if="showModal" width="w-768" :show="true">
        <div class="flex justify-between">
            <h1 class="text-xl font-semibold">View Restaurant information</h1>
            <div @click="toggleModal">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div>
        <div class="grid grid-cols-3 mt-4 gap-8" >
            <div>
                <p class="text-sm" >Restaurant Name</p>
                <p class="font-semibold" >{{  singleResturantInfo.name}}</p>
            </div>
            <div>
                <p class="text-sm" >Restaurant Address</p>
                <p class="font-semibold" >{{  singleResturantInfo.address}}</p>
            </div>
            <div>
                <p class="text-sm" >E-mail address</p>
                <p class="font-semibold" >{{  singleResturantInfo.email }}</p>
            </div>
            <div>
                <p class="text-sm" >Vendor's Name</p>
                <p class="font-semibold" >{{  singleResturantInfo.restaurantOwner.firstName }} {{  singleResturantInfo.restaurantOwner.lastName }}</p>
            </div>
            <div>
                <p class="text-sm" >Vendor's Email address</p>
                <p class="font-semibold" >{{  singleResturantInfo.restaurantOwner.email }}</p>
            </div>
            <div>
                <p class="text-sm" >Contact Person</p>
                <p class="font-semibold" >{{ singleResturantInfo.contactPersonName }}</p>
            </div>
            <div>
                <p class="text-sm" >Contact Person's Number</p>
                <p class="font-semibold" >{{ singleResturantInfo.phoneNumber }}</p>
            </div>
            <div>
                <p class="font-semibold">Available Timebelts</p>
                <div class="flex flex-wrap gap-2" >
                    <div v-for="timebelt in singleResturantInfo.availableTimeBelts" :key="timebelt">
                        <div v-if="timebelt.name ==='Instant'" >
                            <span  class="bg-purple-400 px-2 py-1 text-xs rounded-full" >{{ timebelt.name }}</span>
                        </div>
                        <div v-else-if="timebelt.name === 'Breakfast'" >
                            <span class="bg-red-400 px-2 py-1 text-xs rounded-full" >{{ timebelt.name }}</span>
                        </div>
                        <div v-else-if="timebelt.name === 'Lunch' " >
                            <span class="bg-yellow-400 px-2 py-1 text-xs rounded-full" >{{ timebelt.name }}</span>
                        </div>
                        <div v-else-if="timebelt.name === 'Brunch' " >
                            <span class="bg-green-400 px-2 py-1 text-xs rounded-full" >{{ timebelt.name }}</span>
                        </div>
                        <div v-else-if="timebelt.name === 'Dinner' " >
                            <span class="bg-indigo-400 px-2 py-1 text-xs rounded-full" >{{ timebelt.name }}</span>
                        </div>                
                    </div>
                </div>
            </div>
            <div v-if="singleResturantInfo.serviceChargeScheme">
                <p class="text-sm">Service Charge Scheme</p>
                <p class="font-semibold">{{ singleResturantInfo.serviceChargeScheme.name }}</p>
            </div>
            <div v-if="singleResturantInfo.serviceChargeScheme">
                <p class="font-semibold">Buy for me</p>
                <p class="text-sm">
                    <input type="checkbox" @change="toogleBuyformeRestaurant(singleResturantInfo.restaurantId)" v-model="singleResturantInfo.buyForMeEnabled" class="mr-2" name="" id="buyforme"> 
                    <label for="buyforme" v-if="!singleResturantInfo.buyForMeEnabled">Enable Buy for Me</label>               
                    <label for="buyforme" v-else>Disable Buy for Me</label>               
                </p>
            </div>
            <div v-if="singleResturantInfo.serviceChargeScheme">
                <p class="font-semibold">Publish Restaurant</p>
                <p class="text-sm">
                    <input type="checkbox" @change="tooglePublicUse(singleResturantInfo.restaurantId,singleResturantInfo.activatedForPublicUse)" v-model="singleResturantInfo.activatedForPublicUse" class="mr-2" name="" id="publishRestaurant"> 
                    <label for="publishRestaurant" v-if="!singleResturantInfo.activatedForPublicUse">Enable</label>               
                    <label for="publishRestaurant" v-else>Disable</label>               
                </p>
            </div>
        </div>
        <button class="p-2 bg-red-500 text-gray-200 rounded mt-4 flex space-x-2" >
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
            </div>
            <p class="text-sm" >Delete</p>
        </button>
        <div class="mt-4 flex-grow flex space-x-2 justify-end">
            <button @click="toggleModal" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                Close
            </button>
        </div>
    </Modal>
    <Modal v-if="showeditModal" width="w-768" :show="true">
        <div class="flex justify-between">
            <h1 class="text-xl font-semibold">Edit Restaurant information</h1>
            <div @click="toggleeditModal">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div>
        <div v-if="error" class="text-sm text-red-500 mt-3">{{ error }}</div>
        <form @submit.prevent="updateRestaurant" method="post">
            <div class="grid grid-cols-2 gap-2 mb-3">
                <div>
                    <label for="restaurantName" class="text-sm" >Restaurant Name</label>
                    <input type="text" v-model="editRestaurantData.name" class="input" placeholder="Restaurant Name">
                </div>
                <div>
                    <label for="email" class="text-sm" >Email Address</label>
                    <input type="email" v-model="editRestaurantData.email" class="input" placeholder="Email address">
                </div>
            </div>
            <div class="mb-3">
                <label for="address" class="text-sm" >Restaurant Address</label>
                <vue-google-autocomplete
                    ref="address"
                    id="map"
                    placeholder="Restaurant Address"
                    classname="input" 
                    v-on:placechanged="getAddressData"
                    country="ng"
                    v-model="editRestaurantData.address"
                    autocomplete="off" 
                ></vue-google-autocomplete> 
            </div>
            <div class="grid grid-cols-2 gap-2 mb-3">
                <div>
                    <label for="phone" class="text-sm" >Phone Number</label>
                    <input type="text" v-model="editRestaurantData.phoneNumber" name="phone" id="phone" class="input" placeholder="Phone Number">
                </div>
                <div>
                    <label for="contactPerson" class="text-sm" >Contact Person</label>
                    <input type="text" v-model="editRestaurantData.contactPersonName" class="input" placeholder="Contact Person">
                </div>
            </div>
            <div class="grid grid-cols-4 gap-2 mb-3">
                <div>
                    <label for="shortestTime" class="text-sm" >Earliest Time(mins)</label>
                    <input type="number" v-model="editRestaurantData.shortestMealPreparationTime"  class="input" placeholder="Earliest Time">
                </div>
                <div>
                    <label for="longestTime" class="text-sm" >Latest Time(mins)</label>
                    <input type="number" v-model="editRestaurantData.longestMealPreparationTime" class="input" placeholder="Latest Time">
                </div>
                <div>
                    <label for="state" class="text-sm" >Select State</label>
                    <select @change="findCities()" v-model="stateId" class="input" >
                        <option value="">Select State</option>
                        <option v-for="state in states" :key="state" :value="state.StateID">{{ state.Name }}</option>
                    </select>
                </div>
                <div>
                    <label for="city" class="text-sm" >Select City</label>
                    <select v-model="cityId" class="input" >
                        <option value="">Select City</option>
                        <option v-for="city in cities" :key="city" :value="city.CityID">{{ city.Name }}</option>
                    </select>
                </div>
            </div>
            <div class="grid grid-cols-2 gap-2 mt-4" >
                <div>
                    <div class=" flex" >
                        <label class="flex space-x-2" >
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                            </svg>
                            <p>Upload Banner</p>
                            <input type="file" ref="headerImg" accept="image/*" @input="previewImg" class="hidden" name="file" id="file">
                        </label>
                    </div>
                    <div ref="imgHolder" class="h-24 w-24 bg-gray-100 rounded-sm" >
                        <img :src="(editRestaurantData.images[0].image) ? editRestaurantData.images[0].image : '#'" ref="preview" class="h-24 w-24 rounded-md object-cover" alt="">
                    </div>
                </div>
                <div>
                    <div class=" flex" >
                        <label class="flex space-x-2" >
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                            </svg>
                            <p>Upload logo</p>
                            <input type="file" ref="headerLogo" accept="image/*" @input="previewLogo" class="hidden" name="logo" id="logo">
                        </label>
                    </div>
                    <div ref="LogoHolder" class="h-24 w-24 bg-gray-100 rounded-sm" >
                        <img :src="(editRestaurantData.logo.image) ? editRestaurantData.logo.image : '#'" ref="logoPreview" class="h-24 w-24 rounded-md object-cover" alt="">
                    </div>
                </div>
            </div>
            <div class="flex space-x-2 items-center my-2">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </div>
                <div class="text-sm">File upload size should not exceed 2MB</div>
            </div>
            <div class="mt-4 flex-grow flex space-x-2 justify-end">
              <button @click="toggleeditModal" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                Close
              </button>
                <button type="submit" :disabled="showEditPreloader" class="bg-brand px-3 text-sm py-2 font-semibold rounded">
                    <Preloader v-if="showEditPreloader"/>
                    <span v-else >Update Resturant</span>      
                </button>
            </div>  
        </form>

    </Modal>
    <Modal v-if="showSetComission"  width="w-500" :show="true" >
        <div class="flex justify-between">
            <h1 class="text-xl font-semibold">Set Commission & Service Charge</h1>
            <div @click="tooglesetComission">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div>
        <div v-if="error" class="text-sm text-red-500 mt-3">{{ error }}</div>
        <form @submit.prevent="SetRestaurantComission">
            <div>
                <label for="commission" class="text-sm" >Set Commision</label>
                <input type="number" min="1" max="50" v-model="comission" class="input" placeholder="Set Commision">
            </div>
            <div class="mt-4 flex-grow flex space-x-2">
                <button type="submit" :disabled="showComissionPreloader" class="bg-brand px-3 text-sm py-2 font-semibold rounded">
                    <Preloader v-if="showComissionPreloader"/>
                    <span v-else >Set Commission</span>      
                </button>
            </div>  
        </form>
        <div class="mt-8">
            <div v-if="errorServiceCharge" class="text-sm text-red-500 mt-3">{{ errorServiceCharge }}</div>
            <form @submit.prevent="assignChargeToRestaurant" class="mt-3">
                <div class="mb-3">
                    <label for="serviceCharge" class="text-sm" >Select Service Charge</label>
                    <select v-model="schemeId" id="serviceCharge" class="input" >
                        <option value="">Select Service Charge</option>
                        <option v-for="charge in serviceCharges" :key="charge" :value="charge.id">{{ charge.name }}</option>
                    </select>
                </div>
                <div class="mt-4 flex-grow flex space-x-2">
                    <button type="submit" :disable="showPreloader" ref="submit" class="bg-brand px-3 text-sm py-2 font-semibold rounded">
                        <Preloader v-if="showPreloader"/>
                        <span v-else >Assign</span>      
                    </button>
                </div>  
            </form>
        </div>
        <div class="flex flex-grow justify-end">
            <button @click="tooglesetComission" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                Close
            </button>
        </div>
    </Modal>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import Modal from "@/components/Modal.vue";
import Preloader from "@/components/Preloader.vue";

export default {
    name:['Table'],
    props:['rows','columns','states','page','msg'], 
    components:{
        Modal,Preloader,VueGoogleAutocomplete
    },   
    data(){
        return{
            search:"",
            error:null,
            errorServiceCharge:null,
            showPreloader:false,
            showModal:false,
            showeditModal:false,
            rowPerpage:10,
            Resturants:[],
            showEditPreloader:false,
            showSetComission:false,
            singleResturantInfo:{},
            editRestaurantData:{
                
            },
            stateId:"",
            cityId:"",
            cities:[],
            Endpage: 10,
            startPage : 0,
            numberofPages:0,
            currentPage:1,
            comission:"",
            showComissionPreloader:false, 
            assignRestaurant:{},
            showAssignModal:false,
            schemeId:"",
            serviceCharges:[]
        }
    },
    emits:['cities','changePage','search'],
    computed:{
        getFirstItem(){
            return this.page * this.rowPerpage || 1
        },
        getLastItem(){
            return  this.page * this.rowPerpage + this.Resturants.length
        },
    },
    methods:{
        sortList(){
            this.Resturants = []
            this.$emit('search',this.search)
        },
        toggleeditModal(){
            this.showeditModal = !this.showeditModal
            if(this.showeditModal){
                setTimeout(()=>{
                    this.$refs.address.update(this.editRestaurantData.address);
                },300)
            }
        },
        async togglePublish(published,id){
            let toogle = document.querySelector(`[data-name='restaurant${id}']`);
            let span = document.querySelector(`[data-name='restaurant${id}']`).nextSibling;
            if (published) {
                //Unpublish
                toogle.classList.remove('bg-green-400');
                toogle.classList.add('bg-gray-300');
                toogle.lastElementChild.classList.remove('translate-x-5')
                span.innerText = "Inactive" 
                this.Resturants.map(item =>{
                   if (item.restaurantId == id){
                        item.available = false
                   }
                })
            }else{
                //publish
                toogle.classList.remove('bg-gray-300');
                toogle.classList.add('bg-green-400');
                toogle.lastElementChild.classList.add('translate-x-5')
                span.innerText = "Published" 
                this.Resturants.map(item =>{
                   if (item.restaurantId == id){
                       item.available = true;
                   }
                })
            }
            let url = this.$store.state.base_url + 'restaurant/'+ id + '/change-status';
            let token = await this.$store.getters.getToken
            this.axios.post(url,null,{
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
        },
        previewImg(event){
            let type = ["image/jpeg", "image/png", "image/gif"]
            if (type.find( (item) => item === event.target.files[0].type ) ) {
                this.$refs.imgHolder.classList.remove('hidden')
                this.$refs.preview.src = URL.createObjectURL(event.target.files[0])
                this.error = null;
                this.filename = event.target.files[0].name
            }else{
                this.$refs.imgHolder.classList.add('hidden')
                this.error = "Invaild file format";
            }
        },
        previewLogo(event){
            let type = ["image/jpeg", "image/png", "image/gif"]
            if (type.find( (item) => item === event.target.files[0].type ) ) {
                this.$refs.LogoHolder.classList.remove('hidden')
                this.$refs.logoPreview.src = URL.createObjectURL(event.target.files[0]);
                this.error = null;
                this.logoname = event.target.files[0].name;
            }else{
                this.$refs.LogoHolder.classList.add('hidden')
                this.error = "Invaild file format"
            }
        },
        editModal(id){
            this.editRestaurantData = this.columns.content.find( (item) => item.restaurantId === id);
            this.toggleeditModal();
        },
        async updateRestaurant(){
            let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            let regexPhone = /^[0]\d{10}$/gm;
            let RestaurantData = this.columns.content.find( (item) => item.id === this.editRestaurantData.id);
            if (!this.editRestaurantData.name){
                this.error = "Restaurant name is required"
            }else if(!this.editRestaurantData.email.match(regexEmail)){
                this.error = "Invaild email address"
            }else if(!regexPhone.test(this.editRestaurantData.phoneNumber)){
                this.error = "Invaild phone number"
            }else if(!this.editRestaurantData.contactPersonName){
                this.error = "Contact person field is required"
            }else if(!this.editRestaurantData.address)  {
                this.error = "Restaurant address field is required"
            }else if(!this.address && RestaurantData.address !== this.editRestaurantData.address){
                this.error = "Restaurant address is not recognised by google"
            }else if(this.editRestaurantData.shortestMealPreparationTime > this.editRestaurantData.longestMealPreparationTime || this.editRestaurantData.shortestMealPreparationTime == this.editRestaurantData.longestMealPreparationTime){
                this.error = "Earliest Time is greater or equal to Latest Time"
            }else{
                this.showEditPreloader = true;
                if(this.filename || this.logoname){
                    if(this.filename){
                        //Upload Restaurant banner first
                        let formData = new FormData();
                        let file = this.$refs.headerImg.files[0];
                        formData.append("file", file);
                        let url = this.$store.state.base_url + 'file/upload/';
                        let token = await this.$store.getters.getToken
                        let res = await this.axios.post(url,formData,{
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                Authorization: 'Bearer ' + token
                            }
                        })
                        this.filenameUrl = res.data
                        this.putRestaurant(RestaurantData);
                    }
                    if(this.logoname){
                        let formData = new FormData();
                        let logo = this.$refs.headerLogo.files[0];
                        formData.append("file", logo);
                        let url = this.$store.state.base_url + 'file/upload/';
                        let token = await this.$store.getters.getToken
                        let res = await this.axios.post(url,formData,{
                            headers: {
                                Authorization: 'Bearer ' + token,
                                'Content-Type': 'multipart/form-data'
                            }
                        })

                        this.logonameUrl = res.data
                        this.putRestaurant(RestaurantData);
                    }
                
                }else{
                    this.putRestaurant(RestaurantData)
                }
            }      
        },
        async putRestaurant(RestaurantData){
            //Create restaurants
            let State = this.states.find(element => element.StateID == this.stateId);
            let City = this.cities.find(element => element.CityID == this.cityId);
            let data = {
                "address": this.editRestaurantData.address,
                "contactPersonName": this.editRestaurantData.contactPersonName,
                "description": "string",
                "imageList": [
                    (this.filenameUrl) ? this.filenameUrl : this.editRestaurantData.images[0].image
                ],
                "latitude": (RestaurantData.address !== this.editRestaurantData.address) ? this.address.latitude : this.editRestaurantData.latitude,
                "logo": (this.logonameUrl) ? this.logonameUrl : this.editRestaurantData.logo.image,
                "longestMealPreparationTime": this.editRestaurantData.longestMealPreparationTime,
                "longitude":  (RestaurantData.address !== this.editRestaurantData.address) ? this.address.longitude : this.editRestaurantData.longitude,
                "phoneNumber": this.editRestaurantData.phoneNumber,
                "restaurantName": this.editRestaurantData.name,
                "shortestMealPreparationTime": this.editRestaurantData.shortestMealPreparationTime,
                "state":State.Name,
                "city":City.Name,
                "country":"NG"
            }

            try {
                let url = this.$store.state.base_url + 'admin/restaurant/'+ this.editRestaurantData.restaurantId + '/update';
                let token = await this.$store.getters.getToken
                let res = await  this.axios.put(url,data,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
            
                this.Resturants.map((item) => {
                    if(item.id === this.editRestaurantData.restaurantId){
                        item = this.editRestaurantData;
                    }
                })
                this.showEditPreloader = false;
                this.toggleeditModal();
            } catch (error) {
                this.showEditPreloader = false;
                this.error = error.response.data.message
            }
        },
        toggleModal(){
            this.showModal = !this.showModal
        },
        getsingleRestaurant(id){
            let singleResturant = this.columns.content.find( (item) => item.restaurantId=== id);
            this.singleResturantInfo = singleResturant;
            this.toggleModal()
        },
        prev(){
            if(this.page - 1 >= 0){
                this.Resturants = []
                this.$emit('changePage',{
                    page: this.page - 1,
                    size: this.rowPerpage
                })
            }
        },
        next(){
            if(this.page + 1 < this.columns.totalPages){
                this.Resturants = []
                this.$emit('changePage',{
                    page: this.page + 1,
                    size: this.rowPerpage
                })
            }
        },
        changeEnteredPage($event){
            this.Resturants = []
            this.$emit('changePage',{
                page: parseInt($event.target.value) - 1,
                size: this.rowPerpage
            })
        },
        getAddressData: function (addressData, placeResultData, id) {
            this.address = addressData;
            this.address.address = (addressData.street_number) ?`${addressData.street_number}, ${addressData.route}, ${addressData.locality}, ${addressData.country}`:`${addressData.route} ${addressData.locality}, ${addressData.country}`
            this.editRestaurantData.address.address = this.address.address
            this.$refs.address.update(this.editRestaurantData.address.address);
        },
        findCities(){
          this.cityId = ""
          this.cities = []
          let url = this.$store.state.dellyman_base_url + 'Cities'
          delete this.axios.defaults.headers.common['Authorization'];
            this.axios.get(url,{
                params:{
                    "StateID": this.stateId
                }
            })
            .then((res)=>{
                this.cities = res.data
            })
        },
        tooglesetComission(){
            this.showSetComission = !this.showSetComission
            let restaurant = this.columns.content.find((item) => item.restaurantId === this.setCommisionID);
            this.schemeId = (restaurant.serviceChargeScheme) ? restaurant.serviceChargeScheme.id : ""
        },
        setCommision(id){
            let restaurant = this.columns.content.find((item) => item.restaurantId === id);
            this.comission = restaurant.restaurantPlatformCommission;
            this.setCommisionID = id;
            this.tooglesetComission();
        },
        async SetRestaurantComission(){
            this.showComissionPreloader = true;
            let url = this.$store.state.base_url + 'admin/restaurant/commission/'+ this.setCommisionID
            try {
                let token = await this.$store.getters.getToken
                await this.axios.post(url,null,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    },
                    params:{
                        newCommission: this.comission
                    }
                });
                this.showComissionPreloader = false;
                this.columns.content.map((item) =>{
                    if(item.restaurantId === this.setCommisionID){
                        item.restaurantPlatformCommission = this.comission
                    }
                    return item;
                }); 
                this.comission = 0;
                this.tooglesetComission();
            } catch (error) {
                console.log(error)
            }
            
        },
        assignCharge(id){
            this.assignRestaurant = this.columns.content.find( (item) => item.restaurantId === id);
            this.toggleAssignModal()
        },
        toggleAssignModal(){
            this.showAssignModal = !this.showAssignModal
        },
        async getServiceScheme(){
            let url = this.$store.state.base_url + 'admin/service-charge-scheme';
            let token = await this.$store.getters.getToken
            let res = await this.axios.get(url,{
                headers:{
                    Authorization: 'Bearer ' + token
                }
            });
            this.serviceCharges = res.data;
        },
        async assignChargeToRestaurant(){
            if(!this.schemeId){
                this.errorServiceCharge = "Select a service scheme";
            }else{
                this.showPreloader = true;
                try{
                    let url = this.$store.state.base_url + 'admin/service-charge-scheme/assign';
                    let token =  await this.$store.getters.getToken
                    await this.axios.post(url,null,{
                        headers: {
                            Authorization: 'Bearer ' + token
                        },
                        params: {
                            schemeId:this.schemeId,
                            restaurantId:this.setCommisionID
                        }
                    })
                    this.errorServiceCharge = null;
                    let scheme = this.serviceCharges.find(item => item.id == this.schemeId) 
                    this.showPreloader = false;
                    this.Resturants.map((item) => {
                        if(item.restaurantId === this.setCommisionID){
                            item.serviceChargeScheme = scheme
                        }
                    });
                      this.tooglesetComission();
                }catch(error){
                    this.showPreloader = false;
                }
            }
        },
        async toogleBuyformeRestaurant(id){

            this.error = {}

            let url = this.$store.state.base_url + 'admin/restaurant/eligible-for-buy-me-restaurant/' + id;

            try{
                let token = await this.$store.getters.getToken
                let res = await this.axios.put(url,null,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
            }catch(error){
                console.log(error)
            }
        },
        async tooglePublicUse(id,value){

            this.error = {}

            let url = this.$store.state.base_url + 'admin/publish-restaurants/' + id;

            try{
                let token = await this.$store.getters.getToken
                let res = await this.axios.put(url,null,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
            }catch(error){
                console.log(error)
            }
        }
    },
    mounted(){
        this.getServiceScheme();
    },
    watch:{
        columns: function () {
            this.Resturants = this.columns.content
            this.numberofPages =this.columns.totalPages
        },
        rowPerpage : function () {
            this.Resturants = []
            this.$emit('changePage',{
                page: 0,
                size: this.rowPerpage
            })
        }
    }
}
</script>