<template>
    <div ref="sidebar" class="w-64 flex-shrink-0 flex flex-col transition duration-300 transform top-0 sticky h-screen bg-gray-100 py-5" >
        <div ref="logo" class="px-6" >
            <router-link :to="{name :'Dashboard'}">
                <img v-show="showDetails" src="@/assets/svg/logo-side.svg" class="h-14" alt="">
                <img v-show="!showDetails" src="@/assets/img/short.png" class="h-10 object-fill" alt="">        
            </router-link>
        </div>
        <div class="px-3 mt-4" >
            <div @click="collapseMenu" class="px-3 hidden">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
            </div>
        </div>
        <div class="flex-1 overflow-y-auto scrollbar scrollbar-thumb-rounded-full scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-200 scrollbar-track-rounded-full">
            <ul class="px-3 mt-4 mx-auto grid grid-rows-1 gap-3 text-sm " >
                <li :class="{'active': this.$route.name == 'Dashboard'}" class="rounded">   
                    <router-link :to="{ name: 'Dashboard'}" :class="{'justify-center': !showDetails}" class="flex space-x-4 p-3">
                        <img src="@/assets/svg/dashboard.svg" class="h-4 w-4" alt="">
                        <p v-if="showDetails">Dashboard</p>   
                    </router-link>
                </li>
                <li :class="{'active': this.$route.name == 'AdminRestaurant'}" class="rounded" >
                    <router-link :to="{ name: 'AdminRestaurant'}" :class="{'justify-center': !showDetails}"  class="flex space-x-4 p-3" >
                        <img src="@/assets/svg/restaurant.svg" class="h-4 w-4" alt="">
                        <p v-if="showDetails">Restaurants</p> 
                    </router-link >
                </li>
                 
                <li :class="{'active': this.$route.name == 'AdminMeals'}" class="rounded" >
                    <router-link :to="{ name: 'AdminMeals'}" :class="{'justify-center': !showDetails}"  class="flex space-x-4 p-3" >
                        <img src="@/assets/svg/menu.svg" class="h-4 w-4" alt="">
                        <p v-if="showDetails">Meals</p>
                    </router-link>    
                </li>
                <li :class="{'active': this.$route.name == 'MealsCategory'}" class="rounded" >
                    <router-link :to="{ name: 'MealsCategory'}" :class="{'justify-center': !showDetails}"  class="flex space-x-4 p-3" >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z" />
                        </svg>
                        <p v-if="showDetails">Tag Category</p>
                    </router-link>    
                </li>
                <li :class="{'active': this.$route.name == 'MealsTag'}" class="rounded" >
                    <router-link :to="{ name: 'MealsTag'}" :class="{'justify-center': !showDetails}"  class="flex space-x-4 p-3" >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z" />
                        </svg>
                        <p v-if="showDetails">Meals Tags</p>
                    </router-link>    
                </li>
                <li @click="showBuyForMeCollapse" class="rounded p-3">
                    <div :class="{'justify-center': !showDetails}"  class="flex space-x-4" >
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                                </svg>  
                            </div>
                            <div v-if="showDetails" class="flex flex-grow">
                                <p>Buy for me</p>
                                <div class="flex items-center justify-end flex-grow" >
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 transition duration-200 transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                                    </svg>
                                </div>
                            </div>
                    </div> 
                    <Collapse v-if="showBuyForMeList" :links="buyFormMeCollapseList"/>
                </li>
                <li :class="{'active': this.$route.name == 'AdminOrders'}" class="rounded" >
                    <router-link :to="{ name: 'AdminOrders'}" :class="{'justify-center': !showDetails}"  class="flex space-x-4 p-3" >
                        <img src="@/assets/svg/order.svg" class="h-4 w-4" alt="">
                        <p v-if="showDetails" >Orders</p>
                    </router-link>
                </li>
                <li :class="{'active': this.$route.name == 'Referral'}" class="rounded" >
                    <router-link :to="{ name: 'Referral'}" :class="{'justify-center': !showDetails}"  class="flex space-x-4 p-3" >
                        <img src="@/assets/svg/restaurant.svg" class="h-4 w-4" alt="">
                        <p v-if="showDetails">Referral</p> 
                    </router-link >
                </li>
                <!-- <li @click="showOrderCollapse" class="rounded p-3">
                <div class="flex space-x-4" >
                        <div>
                        <img src="@/assets/svg/order.svg" class="h-4 w-4" alt="">
                        </div>
                        <div v-if="showDetails" class="flex flex-grow">
                            <p>Orders</p>
                            <div class="flex items-center justify-end flex-grow" >
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 transition duration-200 transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                                </svg>
                            </div>
                        </div>
                </div> 
                <Collapse v-if="showOrderCollapselist" :links="orderCollapseList"/>
                </li> -->
                <li @click="showCollapse" class="rounded p-3">
                    <div :class="{'justify-center': !showDetails}"  class="flex space-x-4" >
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                            </svg>
                        </div>
                        <div v-if="showDetails" class="flex flex-grow">
                            <p>Users</p>
                            <div class="flex items-center justify-end flex-grow" >
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 transition duration-200 transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                                </svg>
                            </div>
                        </div>
                    </div> 
                <Collapse v-if="showCollapseList" :links="collapseList"/>
                </li>
                <li :class="{'active': this.$route.name == 'AdminWallet'}" class="rounded " >
                    <router-link :to="{ name: 'AdminWallet'}" :class="{'justify-center': !showDetails}"  class="flex space-x-4 p-3" >
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
                        </svg>
                        <p v-if="showDetails">Wallet</p>
                    </router-link>   
                </li>
                <li :class="{'active': this.$route.name == 'AdminLogs'}" class="rounded" >
                    <router-link :to="{ name: 'AdminLogs'}" :class="{'justify-center': !showDetails}"  class="flex space-x-4 p-3" >
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-4l-2-2H5a2 2 0 00-2 2z" />
                        </svg>
                        <p v-if="showDetails" >Audit logs</p>
                    </router-link>
                </li>
                <li :class="{'active': this.$route.name == 'AdminDiscount'}" class="rounded" >
                    <router-link  :to="{ name: 'AdminDiscount'}" :class="{'justify-center': !showDetails}"  class="flex space-x-4 p-3" >
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z" />
                        </svg>
                        <p v-if="showDetails" >Discount Codes</p>
                    </router-link>
                </li>
                <li :class="{'active': this.$route.name == 'ServiceCharge'}" class="rounded" >
                    <router-link  :to="{ name: 'ServiceCharge'}" :class="{'justify-center': !showDetails}"  class="flex space-x-4 p-3" >
                        <svg class="h-4 w-4 text-black"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2" /></svg>
                        <p v-if="showDetails" >Service Charge</p>
                    </router-link>
                </li>
                <li :class="{'active': this.$route.name == 'AdminReasons'}" class="rounded" >
                    <router-link  :to="{ name: 'AdminReasons'}" :class="{'justify-center': !showDetails}"  class="flex space-x-4 p-3" >
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                        </svg>
                        <p v-if="showDetails" >Rejection Reasons</p>
                    </router-link>
                </li>
                   <li :class="{'active': this.$route.name == 'Timebelts'}" class="rounded" >
                    <router-link  :to="{ name: 'Timebelts'}" :class="{'justify-center': !showDetails}"  class="flex space-x-4 p-3" >
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <p v-if="showDetails">Timebelts</p>
                    </router-link>
                </li>
                <li @click="showSettlementCollapse" class="rounded p-3">
                    <div :class="{'justify-center': !showDetails}"  class="flex space-x-4" >
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </div>
                            <div v-if="showDetails" class="flex flex-grow">
                                <p>Settlements</p>
                                <div class="flex items-center justify-end flex-grow" >
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 transition duration-200 transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                                    </svg>
                                </div>
                            </div>
                    </div> 
                    <Collapse v-if="showSettlementCollapseList" :links="SettlementCollapseList"/>
                </li>
                 <li :class="{'active': this.$route.name == 'DellymanSettlement'}" class="rounded" >
                    <router-link  :to="{ name: 'DellymanSettlement'}" :class="{'justify-center': !showDetails}"  class="flex space-x-4 p-3" >
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z" />
                        </svg>
                        <p v-if="showDetails">External Services</p>
                    </router-link>
                </li>
                 <li :class="{'active': this.$route.name == 'AdminReviews'}" class="rounded" >
                    <router-link  :to="{ name: 'AdminReviews'}" :class="{'justify-center': !showDetails}"  class="flex space-x-4 p-3" >
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                        </svg>
                        <p v-if="showDetails">Reviews</p>
                    </router-link>
                </li>
                <li :class="{'active': this.$route.name == 'Zones'}" class="rounded" >
                    <router-link :to="{ name: 'Zones'}" :class="{'justify-center': !showDetails}"  class="flex space-x-4 p-3" >
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                            </svg>  
                        </div>
                        <p v-if="showDetails" >Zones</p>
                    </router-link>                
                </li>
                <li :class="{'active': this.$route.name == 'AdminSettings'}" class="rounded" >
                    <router-link :to="{ name: 'AdminSettings'}" :class="{'justify-center': !showDetails}"  class="flex space-x-4 p-3" >
                        <img src="@/assets/svg/settings.svg" class="h-4 w-4" alt="">
                        <p v-if="showDetails" >Settings</p>
                    </router-link>                
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Collapse from "@/components/Collapse.vue";
export default {
    name:'sidebar',
    components:{
        Collapse
    },
    data(){
        return{
            showCollapseList: false,
            showDetails: true,
            showOrderCollapselist:false,
            showBuyForMeList:false,
            showSettlementCollapseList:false,
            collapseList:[
                {
                    'name':'Admin',
                    'url' : '/user/admin'
                },
                {
                    'name':'Vendors',
                    'url' : '/user/vendors'
                },
                {
                    'name':'Customers',
                    'url' : '/user/customers'
                },
                {
                    'name':'Waiting Users',
                    'url' : '/user/waiting-users'
                }
            ],
            orderCollapseList:[
                {
                    'name':'All Orders',
                    'url' : '/admin/user/admin'
                },
                {
                    'name':'Placed Orders',
                    'url' : '/admin/user/admin'
                },
                {
                    'name':'Cancelled Orders',
                    'url' : '/admin/user/admin'
                },
                {
                    'name':'Paid Orders',
                    'url' : '/admin/user/customers'
                },
                 {
                    'name':'Shipped Orders',
                    'url' : '/admin/user/customers'
                },
                {
                    'name': 'Delivered Orders',
                    'url' : '/admin/user/vendors'
                },
                {
                    'name':'Refunded Orders',
                    'url' : '/admin/user/customers'
                }
            ],
            SettlementCollapseList:[
                {
                    'name':'Restaurants',
                    'url' : '/restaurants/settlements'
                },
                {
                    'name':'Service Settlements',
                    'url' : '/service/settlements'
                },

            ],
            buyFormMeCollapseList:[
                {
                    'name':'Restaurants',
                    'url' : '/buy-for-me/restaurants'
                },
                {
                    'name':'Orders',
                    'url' : '/buy-for-me/orders'
                },

            ]
        }
    },
    methods:{
        makeActive(){
            let parent= document.querySelector(".router-link-active").parentElement;
            parent.classList.add('active')
        },
        showOrderCollapse(event){
            this.showOrderCollapselist = !this.showOrderCollapselist
            let liTag = event.currentTarget;
            let icon = liTag.firstChild.lastElementChild.lastElementChild.lastElementChild;
            if (this.showCollapseList) {
               icon.classList.add("rotate-90");
            }
            else{
               icon.classList.remove("rotate-90");
            }
        },
        showCollapse(event){
            this.showCollapseList = !this.showCollapseList
            let liTag = event.currentTarget;
            let icon = liTag.firstChild.lastElementChild.lastElementChild.lastElementChild;
            if (this.showCollapseList) {
               icon.classList.add("rotate-90");
            }
            else{
               icon.classList.remove("rotate-90");
            } 
        },
        showBuyForMeCollapse(event){
            this.showBuyForMeList = !this.showBuyForMeList
            let liTag = event.currentTarget;
            let icon = liTag.firstChild.lastElementChild.lastElementChild.lastElementChild;
            if (this.showSettlementCollapseList) {
               icon.classList.add("rotate-90");
            }
            else{
               icon.classList.remove("rotate-90");
            }
           
        },
        collapseMenu(){
            this.showDetails = !this.showDetails;
            this.show = false;
            this.$refs.sidebar.classList.toggle("w-44");
        },
        showSettlementCollapse(event){
            this.showSettlementCollapseList = !this.showSettlementCollapseList
            let liTag = event.currentTarget;
            let icon = liTag.firstChild.lastElementChild.lastElementChild.lastElementChild;
            if (this.showSettlementCollapseList) {
               icon.classList.add("rotate-90");
            }
            else{
               icon.classList.remove("rotate-90");
            }
        }
    },
    mounted(){
        //this.makeActive();
    }
}
</script>

<style>

</style>