<template>
    <div class="bg-gray-100 flex">
        <Sidebar/>
        <div class="flex-grow">
            <Header :adminData="getUser"/>
            <Main>
            <slot></slot>
            </Main>   
        </div>
    </div>

</template>
<script>
import Sidebar from "@/components/admin/Sidebar.vue";
import Header from "@/components/admin/Header.vue";
import Main from "@/components/admin/Main.vue";

export default {
 name:['AdminTemplate'],
 data(){
    return{
        adminData:""
    }
 },
 components:{
     Sidebar,Header,Main
 },
computed:{
    getUser(){
        return (this.$store.getters.getUser) ? this.$store.getters.getUser : {}
    }
}
}
</script>

<style>

</style>