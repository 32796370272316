import store from "./store";
import axios from "./axios/index";
export default {
    formatNumber: (num) => {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    },
    getAllRestaurants: async ()=>{
        let token = await store.getters.getToken
        let url = store.state.base_url + 'admin/all-restaurant';
        let res = await axios.get(url,{
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
        return res.data
    },
    largeNumberConverter: (num) => {
        if(num > 999 && num < 1000000){
            return (num/1000).toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + 'K'; // convert to K for number from > 1000 < 1 million 
        }else if(num > 1000000){
            return (num/1000000).toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + 'M'; // convert to M for number from > 1 million 
        }else if(num < 900){
            return this.formatNumber(num); 
        }
        
    }
}