<template>
    <Admin>
        <div class="flex justify-between">
            <div class="flex items-center" > 
                <div class="h-2 w-2 bg-brand" ></div>  
                 <h1 class="font-semibold text-3xl" >Service Settlements History</h1>
            </div>
        </div>
        <div class="flex justify-center my-20">
            <div class="lg:w-3/5 w-full bg-gray-100 shadow-md rounded-full">
                <select v-model="serviceID" name="" class="bg-transparent p-4 w-full text-sm focus:outline-none" id="">
                    <option value="">Select Service</option>
                    <option v-for="name in services" :key="name" :value="name.id">{{ name.name }}</option>
                </select>
            </div>
        </div>
        <Table :rows="rows" :columns="columns" :msg="msg" />
    </Admin>
</template>

<script>
import Admin from "@/components/admin/AdminTemplate.vue";
import Modal from "@/components/Modal.vue";
import Preloader from '@/components/Preloader.vue';
import Table from "@/components/admin/Tables/ServiceSettlementsTable"

export default {
    name:"Settlements",
    components:{
        Admin,Modal,Preloader,Table 
    },
    data(){
        return{
            rows:['Transaction Id','Amount','Narration','Status','Created', 'Actions'],
            columns:[],
            services:[],
            serviceID:"",
            details:"",
            msg:"Loading ..."
        }
    },
    methods:{
        async getExternalService(){
            let url = this.$store.state.base_url + 'admin/external-services/all'
            let token = await this.$store.getters.getToken;
            let res = await this.axios.get(url,{
                headers:{
                    Authorization: 'Bearer ' + token
                }
            })
            this.services = res.data.sort(function (a, b) {
                let dateA = new Date(a.id)
                let dateB = new Date(b.id)
                return dateA - dateB
            });

            this.serviceID = this.services[0].id

        },
        async getExternalServiceSettlementHIstory(id){
            let url = this.$store.state.base_url + `admin/external-services/settlement/history/${id}`;
            this.msg = "Loading ..."
            let token = await this.$store.getters.getToken
            let res = await this.axios.get(url,{
                headers:{
                    Authorization: 'Bearer ' + token
                }
            })
            this.msg = "No data found"
            this.columns = res.data.sort(function (a, b) {
                let dateA = new Date(a.createdAt)
                let dateB = new Date(b.createdAt)
                return dateB - dateA 
            });

          

        },
        selectedService(){
            this.getExternalServiceSettlementHIstory(this.serviceID);   
        }
    },
    async mounted(){
        await this.getExternalService();
        this.getExternalServiceSettlementHIstory(this.serviceID);
    }
}
</script>

<style>

</style>