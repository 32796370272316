<template>
    <div class="my-8" >
        <div class="flex justify-between mb-4" >
            <div class="flex space-x-2">
                <p class="text-sm flex items-center">Rows per page</p>
                <select @change="changeRow" v-model="rowPerpage" class="border p-0.5 focus:outline-none " name="" id="">
                    <option value="10">10</option>
                    <option value="25" >25</option>
                    <option value="50" >50</option>
                </select>
            </div>
            <div class="flex space-x-3 border border-gray-100 p-0.5 px-3">
                <input type="text" @input="sortList" v-model="search" placeholder="Search" class="focus:outline-none" >
                <button>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                </button>
            </div>
        </div>
    </div>
    <table class="w-full" >
        <thead class="bg-gray-100 border-gray-100 border">
            <tr class="">
                <th v-for="row in rows" :key="row" class="text-sm py-3 px-3 text-left" >
                   {{ row }}
                </th>
            </tr>
        </thead>
        <tbody v-if="getWalletHistory.length < 1">
            <tr class="border border-t-0" >
                <td  class="text-sm p-3 text-center" :colspan="rows.length">{{ msg }}</td>  
            </tr>
        </tbody>
        <tbody v-else>
            <tr v-for="col in getWalletHistory.slice(startPage, rowPerpage)" :key="col" class="border border-t-0">
                <td class="text-sm p-3">{{ col.accountName }}</td>
                <td class="text-sm p-3">{{ col.transactionReference }}</td>
                <td class="text-sm p-3 capitalize">{{ col.transactionType.replace("_"," ").toLowerCase() }}</td>
                <td class="text-sm p-3" >NGN {{ formatNumber(col.amount) }}</td>
                <td class="text-sm p-3" >NGN {{ formatNumber(parseInt(col.accountBalance)) }}</td>
                <td class="text-sm p-3">{{ changeDate(col.transactionDate) }}</td>
            </tr>
        </tbody>
    </table>
    <div class="flex justify-between bg-gray-100 h-12 px-4  items-center " >
       <div> {{ page + 1 }} of {{ numberofPages }} pages (Showing {{ getFirstItem }} - {{ getLastItem }} of {{ getWalletHistory.length }} Entries)</div>
        <div class="flex flex-grow  justify-end" >
            <div class="flex space-x-1">
                <button @click="prev()" class="pagination-btn active text-sm">Prev</button>
                <div  class="border border-gray-300  px-3 py-0.5" > {{ page + 1 }} </div>
                <button @click="next()" class="pagination-btn active text-sm">Next</button>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
export default {
    name:"SettlementTable",
    props:['rows','columns','msg','page'],
    emits:['changePage'],
    data(){
        return{
            rowPerpage:10,
            search:"",
            startPage : 0,
            currentPage: 1,
            Endpage:10,
            numberofPages:0
        }
    },
    computed:{
        getWalletHistory(){
            this.numberofPages = this.columns.totalPages
            return this.columns.content
        },
        getFirstItem(){
            return this.page * this.rowPerpage || 1
        },
        getLastItem(){
            return  this.page * this.rowPerpage + this.getWalletHistory.length
        }
    },
    methods:{
        changeDate(date){
           return moment(date).format('LLL');
        },
        formatNumber(num) {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        },
        prev(){
            if(this.page - 1 >= 0){
                this.$emit('changePage',{
                    page: this.page - 1,
                    size: this.rowPerpage
                })
            }
        },
        next(){
            if(this.page + 1 < this.columns.totalPages){
                this.$emit('changePage',{
                    page: this.page + 1,
                    size: this.rowPerpage
                })
            }
        },
        changeRow(){
            this.$emit('changePage',{
                page: 0,
                size: this.rowPerpage
            })
        }
    }

}
</script>

<style>

</style>