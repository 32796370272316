<script>
import Admin from "@/components/admin/AdminTemplate.vue";
import Modal from "@/components/Modal.vue";
import Table from "@/components/admin/Tables/BuyForMeRestaurantTable.vue";
import Preloader from "@/components/Preloader.vue";
export default {
    name:"BuyForMeRestaurant",
    components:{
        Admin,Modal,Table,Preloader
    },
    data(){
        return {
            showCreateBuyForMe:false,
            restaurants:[],
            restaurantId:"",
            error:{},
            page:0,
            size:10,
            BuyForMeRestaurants:{
                content:[],
                totalElements:0,
                totalPages:0
            },
            msg:"Loading ..."
        }
    },
    computed:{
        getDisabledRestaurant(){
            return this.restaurants.filter((item) => item.buyForMeEnabled == false)
        }
    },
    methods:{
        toggleModal(){
            this.showCreateBuyForMe = !this.showCreateBuyForMe
        },
        async getAllResturants(){
            let url = this.$store.state.base_url + 'admin/all-restaurant';
            try{
                let token = await this.$store.getters.getToken;
                let res = await this.axios.get(url,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    },
                    params:{
                        page:0,
                        size:1000
                    }
                })
                this.restaurants = res.data.content;
            }catch(error){
                console.log(error)
            }
        },
        async addRestaurantBuy4me(){
            if(!this.restaurantId){
                return this.error.restaurantId = "Select a restaurant"
            }

            this.error = {}

            let url = this.$store.state.base_url + 'admin/restaurant/eligible-for-buy-me-restaurant/' + this.restaurantId;

            try{
                this.showPreloader =true
                let token = await this.$store.getters.getToken;
                let res = await this.axios.put(url,null,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                await this.getRestaurantBuy4me()
                this.showPreloader = false
                this.restaurants.map((item) => {
                    if(item.restaurantId == this.restaurantId){
                        item.buyForMeEnabled = true
                    }
                })
                this.restaurantId = ""
                this.toggleModal()
            }catch(error){
                console.log(error)
                this.showPreloader =true
            }

        },
        async getRestaurantBuy4me(){
            let url = this.$store.state.base_url + 'admin/restaurant/buy-for-me-restaurant';

            try{
                let token = await this.$store.getters.getToken
                let res = await this.axios.get(url,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    },
                    params:{
                        page: this.page,
                        size: this.size
                    }
                })
                this.BuyForMeRestaurants = res.data;
            }catch(error){
                console.log(error)
            }
        },
        async changePage(data){
            this.BuyForMeRestaurants.content = [] 
            this.msg = "Loading ..."
            this.page = data.page
            this.size = data.size
            await this.getRestaurantBuy4me()           
        },
        async changeStatus(data){
            this.BuyForMeRestaurants.content.map((item) => {
                if(data.restaurantId == item.restaurantId){
                    item.eligibleForBuyForMeDelivery = !data.status
                }
            })

            this.restaurants.map((item) => {
                if(data.restaurantId == item.restaurantId){
                    item.buyForMeEnabled = !data.status
                }
            })
        }
    },
    mounted() {
        this.getAllResturants()
        this.getRestaurantBuy4me()
    },
}
</script>



<template>
    <Admin>
        <div class="flex justify-between">
            <div class="flex" > 
                <div class="h-2 w-2 bg-brand" ></div>  
                <h1 class="font-semibold text-3xl" >Buy for me (Restaurants)</h1>
            </div>
            <div class="flex space-x-3 items-center" >
            <button @click="toggleModal"  class="p-3 bg-brand rounded flex space-x-2" >
                <p class="text-sm">Add Restaurant</p>
            </button>
            </div>
        </div>
        <div class="my-12">
            <Table :page="page" :size="size" :msg="msg" :columns="BuyForMeRestaurants" @changePage="changePage" @changeStatus="changeStatus"/>
        </div>
    </Admin>
    <Modal v-if="showCreateBuyForMe" width="w-500"  :show="true">
        <div class="flex justify-between">
            <h1 class="text-xl font-semibold">Add Restaurant</h1>
            <div @click="toggleModal">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div> 
        <form  @submit.prevent="addRestaurantBuy4me" method="post">
            <div class="my-3" >
                <label for="restaurant" class="text-sm" >Select Restaurant</label>
                <select v-model="restaurantId" class="input" >
                    <option value="">Select Restaurant</option>
                    <option v-for="restaurant in getDisabledRestaurant" :key="restaurant" :value="restaurant.restaurantId" >{{ restaurant.name }}</option>
                </select>
                <div v-if="error.restaurantId" class="text-sm text-red-500">{{ error.restaurantId }}</div>
            </div>
    
            <div class="mt-4 flex-grow flex space-x-2 justify-end">
                <button @click="toggleModal" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                    Close
                </button>
                <button type="submit" :disabled="showPreloader" class="bg-brand px-3 text-sm py-2 font-semibold rounded">
                    <Preloader v-if="showPreloader"/>
                    <span v-else >Add Restaurant</span>      
                </button>
            </div>
        </form>
    </Modal> 
</template>