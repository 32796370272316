<template>
  <div class="my-8">
    <div class="flex justify-between mb-4">
      <div class="flex space-x-2">
        <p class="flex items-center text-sm">Rows per page</p>
        <select
          v-model="rowPerpage"
          class="border p-0.5 focus:outline-none"
          name=""
          id=""
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
        </select>
      </div>
      <div class="flex space-x-3 border border-gray-100 p-0.5 px-3">
        <input
          type="text"
          @input="sortList"
          v-model="search"
          placeholder="Search"
          class="focus:outline-none"
        />
        <button>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-4 h-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
  <table class="w-full">
    <thead class="bg-gray-100 border border-gray-100">
      <tr class="">
        <th v-for="row in rows" :key="row" class="px-3 py-3 text-sm text-left">
          {{ row }}
        </th>
      </tr>
    </thead>
    <tbody v-if="getSettlements.length < 1">
      <tr class="border border-t-0">
        <td class="p-3 text-sm text-center" :colspan="rows.length">
          {{ msg }}
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr
        v-for="col in getSettlements.slice(startPage, Endpage)"
        :key="col"
        class="border border-t-0"
      >
        <td class="p-3 text-sm">{{ col.reference }}</td>
        <td class="p-3 text-sm w-44">&#8358;{{ formatNumber(col.amount) }}</td>
        <td class="p-3 text-sm">{{ col.restaurantName }}</td>
        <td class="p-3 text-sm">
          <span v-if="col.status == 'SUCCESSFUL'">
            <span
              class="px-2 py-1 text-xs text-gray-100 bg-green-500 rounded-full"
            >
              Settled
            </span>
          </span>
          <span v-else>
            <span
              class="px-2 py-1 text-xs text-gray-100 bg-red-500 rounded-full"
            >
              Not Settled
            </span>
          </span>
        </td>
        <td class="p-3 text-sm w-44">{{ changeDate(col.createdAt) }}</td>
        <td class="flex text-center">
          <button @click="getSettlementsbyID(col.id)" class="text-purple-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              />
            </svg>
          </button>
          <button
            @click="downloadSettlement(col.id)"
            class="px-2 py-2 mx-2 text-xs font-semibold text-black rounded "
            style="margin-left: 0.2rem"
          >
            <svg
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 16v2a2 2 0 002 2h12a2 2 0 002-2v-2M12 12v6m-3-3l3 3 3-3"
              ></path>
            </svg>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="flex items-center justify-between h-12 px-4 bg-gray-100">
    <div>
      {{ page + 1 }} of {{ numberofPages }} pages (Showing {{ getFirstItem }} -
      {{ getLastItem }} of {{ columns.totalElements }} Entries)
    </div>
    <div class="flex justify-end flex-grow">
      <div class="flex space-x-1">
        <button @click="prev()" class="text-sm pagination-btn active">
          Prev
        </button>
        <div class="border border-gray-300 px-3 py-0.5">{{ page + 1 }}</div>
        <button @click="next()" class="text-sm pagination-btn active">
          Next
        </button>
      </div>
    </div>
  </div>

  <Modal v-if="showSingleSettlemntPopup" width="w-768" :show="true">
    <div class="flex justify-between">
      <h1 class="text-xl font-semibold">View Settlement Details</h1>
      <div @click="tooglesingleSettlement">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-6 h-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>
    <div class="relative my-4">
      <div class="grid grid-rows-1 gap-1 text-sm">
        <p>
          <span class="font-semibold">TransactionID: </span>
          {{ singleSettlement.reference }}
        </p>
        <p>
          <span class="font-semibold">Transaction Date: </span>
          {{ changeDate(singleSettlement.createdAt) }}
        </p>
        <p>
          <span class="font-semibold">Amount Settled: </span>
          NGN {{ formatNumber(singleSettlement.amount) }}
        </p>
      </div>
      <div class="absolute top-0 -right-4">
        <span v-if="singleSettlement.status == 'SUCCESSFUL'">
          <span
            class="px-2 py-1 text-xs text-gray-100 bg-green-500 rounded-l-full"
          >
            Settled
          </span>
        </span>
        <span v-else>
          <span
            class="px-2 py-1 text-xs text-gray-100 bg-red-500 rounded-l-full"
          >
            Not Settled
          </span>
        </span>
      </div>
    </div>
    <div class="gap-6 lg:flex lg:justify-between lg:gap-0">
      <h1 class="mt-4 text-xl font-semibold">Order List</h1>
      <div
        class="flex justify-between px-3 py-1 space-x-3 border border-gray-100"
      >
        <input
          type="text"
          @input="sortOrders"
          v-model="searchOrder"
          placeholder="Search by order id"
          class="focus:outline-none"
        />
        <button>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-4 h-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </button>
      </div>
    </div>
    <div
      class="pr-3 my-4 overflow-y-scroll h-72 scrollbar scrollbar-thumb-rounded-full scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-200 scrollbar-track-rounded-full"
    >
      <div v-if="searchOrder" class="h-full">
        <div
          v-if="searchOrderResult.length"
          class="grid grid-rows-1 gap-4 my-6 lg:grid-cols-2"
        >
          <div
            v-for="order in searchOrderResult"
            :key="order"
            class="relative p-4 border rounded-md"
          >
            <div class="grid grid-rows-1 gap-1 text-sm">
              <p>
                <span class="font-semibold">OrderID: </span>
                {{ order.orderReferenceNumber }}
              </p>
              <p>
                <span class="font-semibold">Order Date: </span>
                {{ changeDate(order.createdAt) }}
              </p>
              <p>
                <span class="font-semibold">Delivery Date: </span>
                {{ changeDate(order.expectedDeliveryDate) }}
              </p>
              <p>
                <span class="font-semibold">Timebelt: </span>
                {{ order.cart.timeBelt.name }}
              </p>
              <p>
                <span class="font-semibold">Comission: </span>
                &#8358;{{ formatNumber(order.platformCommission) }}
              </p>
              <p>
                <span class="font-semibold">Settled Amount:</span>
                &#8358;{{ formatNumber(order.restaurantRevenue) }}
              </p>
            </div>
            <div class="absolute right-0 top-2">
              <span>
                <span
                  class="px-2 py-1 text-xs text-gray-100 bg-green-500 rounded-l-full"
                >
                  Delivered
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div
          v-if="singleSettlement.orders"
          class="grid grid-rows-1 gap-4 my-6 lg:grid-cols-2"
        >
          <div
            v-for="order in singleSettlement.orders"
            :key="order"
            class="relative p-4 border rounded-md"
          >
            <div class="grid grid-rows-1 gap-1 text-sm">
              <p>
                <span class="font-semibold">OrderID: </span>
                {{ order.orderReferenceNumber }}
              </p>
              <p>
                <span class="font-semibold">Order Date: </span>
                {{ changeDate(order.createdAt) }}
              </p>
              <p>
                <span class="font-semibold">Delivery Date: </span>
                {{ changeDate(order.expectedDeliveryDate) }}
              </p>
              <p>
                <span class="font-semibold">Timebelt: </span>
                {{ order.cart.timeBelt.name }}
              </p>
              <p>
                <span class="font-semibold">Comission: </span>
                &#8358;{{ formatNumber(order.platformCommission) }}
              </p>
              <p>
                <span class="font-semibold">Settled Amount:</span>
                &#8358;{{ formatNumber(order.restaurantRevenue) }}
              </p>
            </div>
            <div class="absolute right-0 top-2">
              <span>
                <span
                  class="px-2 py-1 text-xs text-gray-100 bg-green-500 rounded-l-full"
                >
                  Delivered
                </span>
              </span>
            </div>
          </div>
        </div>
        <div v-else class="flex items-center justify-center h-full">
          <div>
            <div class="flex justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-16 h-16"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <div class="text-sm text-center">Order was not found</div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import moment from "moment";
import Modal from "@/components/Modal.vue";
export default {
  name: "SettlementTable",
  props: ["rows", "columns", "msg", "page"],
  emits: ["changePage"],
  components: {
    Modal,
  },
  data() {
    return {
      rowPerpage: 10,
      search: "",
      startPage: 0,
      Endpage: 10,
      currentPage: 1,
      Settlements: [],
      singleSettlement: null,
      showSingleSettlemntPopup: false,
      searchOrder: "",
      searchResult: [],
      searchOrderResult: [],
    };
  },
  computed: {
    getSettlements() {
      if (this.search && this.searchResult.length) {
        this.numberofPages = Math.ceil(
          this.searchResult.length / this.rowPerpage
        );
        this.Settlements = this.searchResult;
      } else {
        this.numberofPages = this.columns.totalPages;
        this.Settlements = this.columns ? this.columns.content : [];
      }

      return this.Settlements.sort(function (a, b) {
        let dateA = new Date(a.createdAt);
        let dateB = new Date(b.createdAt);
        return dateB - dateA;
      });
    },
    getFirstItem() {
      return this.page * this.rowPerpage || 1;
    },
    getLastItem() {
      return this.page * this.rowPerpage + this.getSettlements.length;
    },
  },
  methods: {
    changeDate(date) {
      return moment(date).format("LLL");
    },
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    prev() {
      if (this.page - 1 >= 0) {
        this.Settlements = [];
        this.$emit("changePage", {
          page: this.page - 1,
          size: this.rowPerpage,
        });
      }
    },
    next() {
      if (this.page + 1 < this.columns.totalPages) {
        this.Settlements = [];
        this.$emit("changePage", {
          page: this.page + 1,
          size: this.rowPerpage,
        });
      }
    },
    tooglesingleSettlement() {
      this.showSingleSettlemntPopup = !this.showSingleSettlemntPopup;
    },
    getSettlementsbyID(id) {
      this.singleSettlement = this.getSettlements.find((item) => item.id == id);
      this.tooglesingleSettlement();
    },
    sortOrders() {
      if (this.searchOrder) {
        this.searchOrderResult = this.singleSettlement.orders.filter((item) =>
          item.orderReferenceNumber
            .toLowerCase()
            .includes(this.searchOrder.toLowerCase())
        );
      }
    },
    sortList() {
      if (this.search) {
        this.searchResult = this.getSettlements.filter((item) =>
          item.reference.toLowerCase().includes(this.search.toLowerCase())
        );
      }
    },
     async downloadSettlement(id) {
      this.singleSettlement = this.getSettlements.find((item) => item.id == id);
      console.log(this.singleSettlement,'this.singleSettlement');
      try {
        const settlementId = this.singleSettlement.id;
        let token = await this.$store.getters.getToken;
        const url = `/admin/settlement/${settlementId}/report`;
        const response = await this.axios.get(url, {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `report_${settlementId}.xlsx`;
        link.click();
        window.URL.revokeObjectURL(link.href);
      } catch (error) {
        console.error("Error downloading report:", error);
      }
    },
  },
};
</script>

<style>
</style>