
<script>
import Admin from "@/components/admin/AdminTemplate.vue";
import Modal from "@/components/Modal.vue";
import Table from "@/components/admin/Tables/Buy4meOrders.vue";
import Preloader from "@/components/Preloader.vue";
export default {
    name:"BuyForMeOrders",
    components:{
        Admin,Modal,Table,Preloader
    },
    data(){
        return {
            orders:{
                content:[]
            },
            rows:['Reference ID','Customer','Restaurant','Price','Timebelt','Delivery date','Status','Action'],
            page:0,
            msg:"Loading  ..."
        }
    },
    methods:{
        async getOrders(){

            let url = this.$store.state.base_url + 'admin/orders/all-buy-for-me-orders';

            try{
                let token = await  this.$store.getters.getToken
                let res = await this.axios.get(url,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    },
                    params:{
                        page: this.page,
                        size: this.size
                    }
                })
                this.orders = res.data;
            }catch(error){
                console.log(error)
            }
        },
        async changeOrderPage(){
            this.columns.content = [] 
            this.msg = "Loading ..."
            this.page = data.page
            this.size = data.size
            await this.getOrders()       
        }
    },
    mounted(){
        this.getOrders()
    }
}
</script>
<template>
    <Admin>
        <div class="flex justify-between">
            <div class="flex"> 
                <div class="h-2 w-2 bg-brand" ></div>  
                 <h1 class="font-semibold text-3xl" >Buy for me Orders</h1>
            </div>
        </div>

        <div class="mt-8" >
            <Table :rows="rows" :columns="orders" :page="page" :msg="msg" @changePage="changeOrderPage"/>
        </div>
    </Admin>
  
</template>
