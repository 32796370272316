
<template>
  <Admin>
        <div class="flex justify-between ">
            <div class="flex" > 
                <div class="w-2 h-2 bg-brand" ></div>  
                <h1 class="text-3xl font-semibold" >Reviews</h1>
            </div>
        </div>

        <form @submit.prevent="getRestaurantReview" class="flex my-8 space-x-3">
            <div>
                <select v-model="restaurantId" class="input max-h-28 w-96">
                    <option value="">Select restaurant</option>
                    <option v-for="Restaurant in Restaurants" :key="Restaurant" :value="Restaurant.restaurantId">{{ Restaurant.name }}</option>
                </select>
            </div>
            <button type="submit" :disabled="showPreloader" class="px-3 py-2 mt-2 text-sm font-semibold rounded bg-brand">
                <Preloader v-if="showPreloader"/>
                <span v-else >Get Reviews</span>      
            </button>
        </form>
        <div>
            <div class="flex justify-between mb-4" >
                <div class="flex space-x-2">
                    <p class="flex items-center text-sm">Rows per page</p>
                    <select v-model="rowPerpage" class="border p-0.5 focus:outline-none " name="" id="">
                        <option value="10">10</option>
                        <option value="25" >25</option>
                        <option value="50" >50</option>
                    </select>
                </div>
                <div class="flex space-x-3 border border-gray-100 p-0.5 px-3">
                    <input type="text" @input="sortList" @change="sortList" v-model="search" placeholder="Search Reviews" class="focus:outline-none" >
                    <button>
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                        </svg>
                    </button>
                </div>
            </div>
            <table class="w-full" >
                <thead class="bg-gray-100 border border-gray-100">
                    <tr class="">
                        <th v-for="row in rows" :key="row" class="px-3 py-3 text-sm text-left" >
                            <p>{{ row }}</p>    
                        </th>
                    </tr>
                </thead>
                <tbody v-if="Reviews.length < 1">
                    <tr class="border border-t-0" >
                        <td  class="p-3 text-sm text-center" :colspan="rows.length">{{ msg }}</td>  
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr v-for="col in Reviews.slice(startPage, Endpage)" :key="col" class="border border-t-0">
                        <td class="p-3 text-sm" >{{ col.orderReferenceNumber }}</td>
                        <td class="p-3 text-sm" >{{ parseInt(col.rating).toFixed(1) }}</td>
                        <td class="p-3 text-sm" >{{ col.reviewerName }}</td>
                        <td class="p-3 text-sm" >{{ col.reviewTitle }}</td>
                        <td class="p-3 text-sm" >{{ col.reviewComment }}</td>
                        <td>
                            <span :class="{'bg-green-400': col.adminResponse,'bg-red-400': !col.adminResponse }" class="px-3 py-1 text-xs rounded-full">
                                 {{ (col.adminResponse) ? "Replied" : "Nil" }}
                            </span>
                        </td>
                        <td class="w-24 p-3 text-sm" >{{ this.moment(col.createdAt).format('LLLL') }}</td>
                        <td class="p-3 text-sm">
                            <button @click="togglePublish(col.isPublished,col.id)" :data-name ="'review'+col.id" :class="col.isPublished ? 'bg-green-400' : null" class="flex items-center w-10 h-5 bg-gray-200 border border-gray-200 rounded-full" >
                                <div :class="col.isPublished ? 'translate-x-5' : null" class="flex w-4 h-4 transition duration-500 transform bg-white rounded-full" ></div>
                            </button>
                            <span v-if="col.isPublished == true" class="text-xs" >Published</span>
                            <span class="text-xs"  v-else>Unpublished</span>
                        </td>
                        <td>
                            <button @click="getSingleReview(col.id)" class="text-purple-500" >
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                </svg>
                            </button>
                            <button @click="editReview(col.id)" >
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                </svg>
                            </button>
                            <button @click="toogledelete(col.id)" class="text-red-500" >
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                </svg>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="flex items-center justify-between h-12 px-4 bg-gray-100 " >
                <div> {{ currentPage }} of {{ numberofPages }} pages (Showing {{ getFirstItem }} - {{ getLastItem }} of {{ Reviews.length }} Entries)</div>
                <div class="flex justify-end flex-grow" >
                    <div class="flex space-x-1">
                        <button @click="prev(currentPage)" class="text-sm pagination-btn active">Prev</button>
                        <div  class="border border-gray-300  px-3 py-0.5" > {{ currentPage }} </div>
                        <button @click="next(currentPage)" class="text-sm pagination-btn active">Next</button>
                    </div>
                </div>
            </div>
        </div>
  </Admin>

    <Modal v-if="showView" width="w-768" :show="true">
         <div class="flex justify-between">
            <h1 class="text-xl font-semibold">Review</h1>
            <div @click="toogleShowView">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div>
        <div class="grid grid-cols-3 gap-6 mt-4" >
            <div>
                <p class="text-sm" >Reference No</p>
                <p class="font-semibold" >{{ singleReview.orderReferenceNumber }}</p>
            </div>
            <div>
                <p class="text-sm" >Author</p>
                <p class="font-semibold" >{{ singleReview.reviewerName }}</p>
            </div>
            <div>
                <p class="text-sm" >Rating</p>
                <p class="font-semibold" >{{ parseInt(singleReview.rating).toFixed(1) }}</p>
            </div>
        </div>
        <div class="my-4">
            <p class="text-sm font-semibold" >Title</p>
            <p class="">{{ singleReview.reviewTitle }}</p>
        </div>
        <div class="mb-4">
            <p class="text-sm font-semibold" >Comment</p>
            <p class="" >{{ singleReview.reviewComment }}</p>
        </div>
        <div v-if="singleReview.adminResponse">
            <p class="text-sm font-semibold" >Admin Response</p>
            <p class="" >{{ singleReview.adminResponse }}</p>
        </div>
        <div class="flex justify-end flex-grow mt-4 space-x-2">
            <button @click="toogleShowView" class="px-3 py-2 text-sm font-semibold bg-gray-300 rounded">
                Close
            </button>
        </div>
    </Modal>

    <Modal v-if="showResponse" width="w-768" :show="true">
         <div class="flex justify-between">
            <h1 class="text-xl font-semibold">Send a response</h1>
            <div @click="toogleShowResponse">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div>
        <div v-if="error" class="mt-3 text-sm text-red-500">{{ error }}</div>
        <form @submit.prevent="sendResponse" class="mt-4">
            <textarea class="input" name="" id="" cols="30" rows="10" v-model="response"  placeholder="Send a response"></textarea>
              <div class="flex justify-end flex-grow mt-4 space-x-2">
                    <button @click="toogleShowResponse" class="px-3 py-2 text-sm font-semibold bg-gray-300 rounded">
                        Close
                    </button>
                    <button type="submit" :disabled="showResponsePreloader" class="px-3 py-2 text-sm font-semibold rounded bg-brand">
                        <Preloader v-if="showResponsePreloader"/>
                        <span v-else>Send Response</span>      
                    </button>
                </div>
        </form>
    </Modal>
    <Modal v-if="deleteModal" :show="true">
       <h1 class="text-2xl font-semibold">Are you sure</h1> 
       <p class="py-2" >Do you really want to delete this record? This action cannot be undone</p>
        <div class="flex justify-end flex-grow mt-4 space-x-2">
            <button @click="toogledelete" class="px-3 py-2 text-sm font-semibold bg-gray-300 rounded">
                No
            </button>
            <button @click="deleteMealReq"  type="submit" ref="submit" class="px-3 py-2 text-sm font-semibold rounded bg-brand">
                <Preloader v-if="showDeletePreloader"/>
                <span v-else>Yes</span>      
            </button>
        </div>
    </Modal>
</template>

<script>
import Admin from "@/components/admin/AdminTemplate.vue"

import Preloader from "@/components/Preloader.vue";
import Modal from "@/components/Modal.vue";
export default {
    name:"Reviews",
    components:{
        Admin,Preloader,Modal
    },
    data(){
        return{
            Reviews:[],
            restaurantId:"",
            showPreloader:false,
            rows:['Reference No','Rating','Author','Title','Comment','Replied','Created','Status','Action'],
            Restaurants:[],
            msg:"Loading ...",
            rowPerpage:10,
            search:"",
            startPage:0,
            Endpage:10 ,
            currentPage:1, 
            singleReview:{},
            showView:false,
            showResponse:false,
            response:"",
            error:null,
            numberofPages:0,
            deleteModal:false,
            showResponsePreloader:false,
            showDeletePreloader:false,
            searchResult:[],
            data:[]
        }
    },
     computed:{
        getFirstItem(){
            return this.Reviews.findIndex((item)=> item.id == this.Reviews.slice(this.startPage, this.Endpage)[0].id ) + 1
        },
        getLastItem(){
            return this.Reviews.findIndex((item)=> item.id == this.Reviews.slice(this.startPage, this.Endpage)[this.Reviews.slice(this.startPage, this.Endpage).length - 1].id ) + 1
        }
    },
    methods:{
            prev(currentPage){
            let prev = currentPage - 1;
            if (prev) {
            this.currentPage = prev;
            this.startPage  = ((prev - 1) * this.rowPerpage);
            this.Endpage = prev * this.rowPerpage            
            }
        },
        next(currentPage){
            let next = currentPage + 1;
            if (next <= this.numberofPages) {
                this.currentPage = next;
                this.startPage  = ((next - 1) * this.rowPerpage);
                this.Endpage = next * this.rowPerpage;
            }
        },
        async getRestaurantReview(){
            let url = this.$store.state.base_url + 'admin/review/all/restaurant/' + this.restaurantId;
            if(this.restaurantId){               
                try {
                    this.showPreloader = true;
                    let token = await this.$store.getters.getToken
                    let res = await this.axios.get(url,{
                        headers: {
                            Authorization: 'Bearer ' + token
                        }
                    });
                    this.Reviews = res.data.sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt));
                    this.showPreloader = false
                      this.msg = "No data found"
                } catch (error) {
                    console.log(error)
                    this.msg = "No data found"
                }   
            }
        },
        async getAllResturantsReview(){
              try {
                    let token = await this.$store.getters.getToken
                    let res = await this.axios.get('admin/review/all',{
                    headers: {
                            Authorization: 'Bearer ' + token
                        }
                    });
                    this.data = this.Reviews = res.data.sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt));
                    this.msg = "No data found"
                } catch (error) {
                    console.log(error)
                    this.msg = "No data found"
                }  
        },
        async getAllResturants(){
            let url = this.$store.state.base_url + 'admin/all-restaurant';
            let token = await this.$store.getters.getToken
            let res = await this.axios.get(url,{
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
            this.Restaurants = res.data;
        },
        toogleShowView(){
            this.showView = !this.showView
        },
        getSingleReview(id){
            this.singleReview = this.Reviews.find(item => item.id == id);
            this.toogleShowView();
        },
        async togglePublish(published,id){
            let toogle = document.querySelector(`[data-name='review${id}']`);
            let span = document.querySelector(`[data-name='review${id}']`).nextSibling;
            if (published) {
                //Unpublish
                toogle.classList.remove('bg-green-400');
                toogle.classList.add('bg-gray-300');
                toogle.lastElementChild.classList.remove('translate-x-5')
                span.innerText = "Unpublished" 
                this.Reviews.map(item =>{
                   if (item.id == id){
                       item.isPublished = false
                   }
                })
            }else{
                //publish
                toogle.classList.remove('bg-gray-300');
                toogle.classList.add('bg-green-400');
                toogle.lastElementChild.classList.add('translate-x-5')
                span.innerText = "Published" 
                this.Reviews.map(item =>{
                   if (item.id == id){
                       item.isPublished = true;
                   }
                })
            }
            let url = this.$store.state.base_url + `admin/review/toggle-status/${id}`;
            let token = await this.$store.getters.getToken
            this.axios.post(url,null,{
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
        },
        toogleShowResponse(){
            this.showResponse = ! this.showResponse
        },
        editReview(id){
            this.singleReview = this.Reviews.find(item => item.id == id);
            this.response = this.singleReview.adminResponse
            this.toogleShowResponse();
        },
        async sendResponse(){
            if(this.response){
                let url = this.$store.state.base_url +  `admin/review/respond/${this.singleReview.id}?adminResponse=${this.response}`;
                try {
                    this.showResponsePreloader = true
                    let token = await this.$store.getters.getToken;
                    let res = await this.axios.post(url,null,{
                        headers: {
                            Authorization: 'Bearer ' + token
                        }
                    })
                     this.showResponsePreloader = false
                     this.Reviews.map(item =>{
                         if(item.id == this.singleReview.id){
                            item.adminResponse = this.response
                         }
                     })
                     this.toogleShowResponse();
                     this.response =  this.error = "";
                } catch (error) {
                    console.log(error);
                     this.showResponsePreloader = false
                }
            }else{
                this.error = "Response field is required"
            }
        },
        toogledelete(id){
            this.deleteid = id
            this.deleteModal = !this.deleteModal
        },
        async deleteMealReq(){
            try {
                this.showDeletePreloader = true
                let url = this.$store.state.base_url + `admin/review/${this.deleteid}`;
                let token = await this.$store.getters.getToken;
                await this.axios.delete(url,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                });
                this.Reviews.filter(item => item.id != this.deleteid)
                this.deleteid = null
                this.deleteModal = false
                this.showDeletePreloader = false
            } catch (error) {
                console.log(error);
                this.showDeletePreloader = false
            }
        },
        sortList(){
            if(this.search){
                this.searchResult =  this.Reviews.filter(col => col.orderReferenceNumber.toLowerCase().includes(this.search.toLowerCase()) ||  col.rating == this.search ||  col.reviewerName.toLowerCase().includes(this.search.toLowerCase())
                || col.reviewTitle.toLowerCase().includes(this.search.toLowerCase())  || col.reviewComment.toLowerCase().includes(this.search.toLowerCase()) || this.moment(col.createdAt).format('LLLL').includes(this.search.toLowerCase()))
                this.Reviews = this.searchResult
            }else{
                this.Reviews = this.data
            }
        }
    },
    mounted() {
        this.getAllResturants();
        this.getAllResturantsReview()
    },
    watch: {
        Reviews: function () {
            this.numberofPages = Math.ceil(this.Reviews.length/this.rowPerpage)
        },
        rowPerpage : function () {
            this.numberofPages = Math.ceil(this.Reviews.length/this.rowPerpage)
            this.Endpage = this.rowPerpage;
            this.startPage  = 0;
            this.currentPage = 1
        }
    },
}
</script>

<style>

</style>