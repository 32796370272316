<template>
<Admin>
    <div class="flex justify-between">
        <div class="flex"> 
            <div class="h-2 w-2 bg-brand" ></div>  
                <h1 class="font-semibold text-3xl" >Audit Logs</h1>
        </div>   
    </div>
    <div class="mt-8" >
        <Table :rows="rows" :columns="columns" />
    </div>
</Admin>
</template>

<script>
import Admin from "@/components/admin/AdminTemplate.vue";
import Table from "@/components/admin/Tables/AuditTable.vue";


export default {
    name:'AdminAudit',
    components:{
        Admin,Table
    },
    data(){
        return{
            rows:['Admin','Order','Action','Date'],
            columns:[]
        }
    },
    methods:{
        async getAllLogs(){
            let url = this.$store.state.base_url + 'audit/all';
            try {
                let token = await this.$store.getters.getToken
                let res = await this.axios.get(url,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
        
                this.columns = this.logs = res.data
            } catch (error) {
                console.log(error);
            }
        }
    },
    beforeMount(){
        this.getAllLogs();
    }
    
}
</script>