<template>
    <div class="flex justify-between mb-4" >
        <div class="flex space-x-2">
            <p class="text-sm flex items-center">Rows per page</p>
            <select v-model="rowPerpage" class="border p-0.5 focus:outline-none " name="" id="">
                <option value="10">10</option>
                <option value="25" >25</option>
                <option value="50" >50</option>
            </select>
        </div>
        <div class="flex space-x-3 border border-gray-100 p-0.5 px-3">
            <input type="text" @input="sortList" v-model="search" placeholder="Search" class="focus:outline-none" >
            <button>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
            </button>
        </div>
    </div>
    <table class="w-full" >
        <thead class="bg-gray-100 border-gray-100 border">
            <tr class="">
                <th v-for="row in rows" :key="row" class="text-sm py-3 px-3 text-left" >{{ row }}</th>
            </tr>
        </thead>
        <tbody v-if="logs.length < 1">
            <tr class="border border-t-0" >
                <td  class="text-sm p-3 text-center" :colspan="rows.length">No data found</td>  
            </tr>
        </tbody>
        <tbody>
            <tr v-for="col in logs.slice(startPage, Endpage)" :key="col" class="border border-t-0">
                <td class="text-sm p-3 text-left">{{ col.admin.firstName }}</td>  
                <td class="text-sm p-3 text-left">{{ col.order.orderReferenceNumber }}</td> 
                <td class="text-sm p-3 text-left">{{ col.action }}</td>  
                <td class="text-sm p-3 text-left">{{ datewithTime(col.createdAt) }}</td>  
            </tr>
        </tbody>
    </table> 
    <div class="flex justify-between bg-gray-100 h-12 px-4  items-center " >
        <div>{{ currentPage }} of {{ numberofPages }}  pages </div>
        <div class="flex flex-grow  justify-end" >
            <div class="flex space-x-1">
                <button @click="prev(currentPage)" class="pagination-btn active text-sm">Prev</button>
                <div  class="border border-gray-300  px-3 py-0.5" > {{ currentPage }} </div>
                <button @click="next(currentPage)" class="pagination-btn active text-sm">Next</button>
            </div>
        </div>
    </div>   
</template>

<script>
import moment from "moment";
    export default{
        name:'Audit',
        props:['rows','columns'],
        data(){
            return{
                search:"",
                rowPerpage:10,
                startPage : 0,
                currentPage:1,
                numberofPages:0,
                Endpage:10,
                logs:[]
            }
        },
        methods:{
             prev(currentPage){
                let prev = currentPage - 1;
                if (prev) {
                this.currentPage = prev;
                this.startPage  = ((prev - 1) * this.rowPerpage);
                this.Endpage = prev * this.rowPerpage            
                }
            },
            next(currentPage){
                let next = currentPage + 1;
                if (next <= this.numberofPages) {
                    this.currentPage = next;
                    this.startPage  = ((next - 1) * this.rowPerpage);
                    this.Endpage = next * this.rowPerpage;
                }
            },
            datewithTime(date){
              return moment(date).format('lll');   
            },
            sortList(){
                if(this.search){
                    this.searchResult = this.columns.filter(col => col.admin.firstName .toLowerCase().includes(this.search.toLowerCase()) || col.order.orderReferenceNumber.toLowerCase().includes(this.search.toLowerCase()) );  
                    this.logs = this.searchResult;
                    this.currentPage = 1
                    this.startPage  = 0;
                    this.numberofPages = Math.ceil(this.logs.length/this.rowPerpage)
                    if (this.searchResult < 1 ) {
                        this.logs = []
                    }
                }else{
                    this.logs = this.columns
                    this.numberofPages = Math.ceil(this.columns.length/this.rowPerpage)
                    this.Endpage = this.rowPerpage;
                    this.startPage  = 0;
                    this.currentPage = 1
                }
            
            }
        },
        watch: {
            columns: function () {
                this.logs = this.columns
                this.numberofPages = Math.ceil(this.columns.length/this.rowPerpage);
            },
            rowPerpage : function () {
            this.numberofPages = Math.ceil(this.columns.length/this.rowPerpage)
            this.Endpage = this.rowPerpage;
            this.startPage  = 0;
            this.currentPage = 1
        }

        }
    }
</script>