<template>
    <div class="flex justify-between mb-4" >
        <div class="flex space-x-2">
            <p class="text-sm flex items-center">Rows per page</p>
            <select v-model="rowPerpage" class="border p-0.5 focus:outline-none " name="" id="">
                <option value="10">10</option>
                <option value="25" >25</option>
                <option value="50" >50</option>
            </select>
        </div>
        <div class="flex space-x-3 border border-gray-100 p-0.5 px-3">
            <input type="text" @input="sortList" v-model="search" placeholder="Search" class="focus:outline-none" >
            <button>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
            </button>
        </div>
    </div>
    <table class="w-full" >
        <thead class="bg-gray-100 border-gray-100 border">
            <tr class="">
                <th v-for="row in rows" :key="row" class="text-sm py-3 px-3 text-left" >
                   <div class="flex justify-between" >
                        <p>{{ row }}</p>
                   </div>
                </th>
            </tr>
        </thead>
        <tbody v-if="categories.length < 1">
            <tr class="border border-t-0" >
                <td class="text-sm p-3 text-center" :colspan="rows.length">{{ msg }}</td>  
            </tr>
        </tbody>
        <tbody v-else>
            <tr v-for="col in categories.slice(startPage, Endpage)" :key="col" class="border border-t-0">
                <td class="text-sm p-3 w-2/12" >{{ col.name }}</td>
                <td class="text-sm p-3" >{{ col.description }}</td>
                <td class="text-sm p-3 flex flex-wrap gap-4" >
                    <div v-for="category in col.productTagCategoryDtos" :key="category" class="bg-gray-200 px-4 py-2 text-xs rounded-full">
                        {{ category.name }}
                    </div>
                </td>
                <td class="w-1/12 text-center" >
                    <button @click="editModal(col.id)" >
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                        </svg>
                    </button>
                    <button @click="toogledelete(col.id)" class="text-red-500" >
                       <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="flex justify-between bg-gray-100 h-12 px-4  items-center " >
       <div> {{ page + 1 }} of {{ numberofPages }} pages (Showing {{ getFirstItem }} - {{ getLastItem }} of {{ columns.totalElements }} Entries)</div>
        <div class="flex flex-grow  justify-end" >
            <div class="flex space-x-1">
                <button @click="prev()" class="pagination-btn active text-sm">Prev</button>
                <div  class="border border-gray-300  px-3 py-0.5" > {{ page + 1 }} </div>
                <button @click="next()" class="pagination-btn active text-sm">Next</button>
            </div>
        </div>
    </div>
    <Modal  width="w-500" v-if="showeditModal" :show="true">
        <div class="flex justify-between">
            <h1 class="text-xl font-semibold">Edit Tag</h1>
            <div @click="toggleEditModal">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div>
        <div v-if="error" class="text-sm text-red-500 mt-3">{{ error }}</div>
        <form @submit.prevent="updateTag" method="post">
            <div class="mb-3">
                <label for="name" class="text-sm" >Tag name</label>
                <input type="text" v-model="editCategory.name" class="input" placeholder="Tag name">
            </div>
            <div class="mb-3 relative">
                <label for="category" class="text-sm block mb-3">Tag Category</label>
                <select2-multiple-control :settings="setting" :value="getUpdatedCategories"  @change="selectedCategory" :options="getSelectCategories"  />
            </div>
            <div class="mt-4">
                <div class="flex">
                    <label for="description" class="text-sm" >Description</label>
                    <span class="text-sm flex-grow flex justify-end" ></span>
                </div>
                <textarea type="text" maxlength="200" v-model="editCategory.description" cols="10" rows="2" class="input" placeholder="Enter Description"></textarea>
            </div>
            <div class="mt-4 flex-grow flex space-x-2 justify-end">
                <button @click="toggleModal" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                    Close
                </button>
                <button type="submit" :disabled="showPreloader" class="bg-brand px-3 text-sm py-2 font-semibold rounded">
                    <Preloader v-if="showPreloader"/>
                    <span v-else >Update tags</span>      
                </button>
            </div>
        </form>
    </Modal>
    <Modal  width="w-500" v-if="showdeleteModal" :show="true">
        <h1 class="text-2xl font-semibold">Are you sure</h1> 
       <p class="py-2 text-sm" >Do you really want to delete this record? This action cannot be undone</p>
        <div class="mt-4 flex-grow flex space-x-2 justify-end">
            <button @click="toogledelete" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                No
            </button>
            <button @click="deleteTag"  type="submit" ref="submit" class="bg-brand px-3 text-sm py-2 font-semibold rounded">
                <Preloader v-if="showPreloader"/>
                <span v-else>Yes</span>      
            </button>
        </div>
    </Modal>
</template>

<script>
import Preloader from "@/components/Preloader.vue";
import moment from "moment";
import Modal from "@/components/Modal.vue";
import Select2MultipleControl from 'v-select2-multiple-component'
export default {
    name:['Table'],
    props:['rows','columns','page','msg','TagCategory'],   
    emits:['changePage','updateTag','removeTag'], 
    components:{
        Modal,Preloader,Select2MultipleControl
    }, 
    data(){
        return{
            toggleValue: false,
            search:"",
            showPreloader:false,
            error:'',
            showModal:false,
            searchResult: [],
            categories:[],
            rowPerpage:10,
            Endpage: 10,
            startPage : 0,
            currentPage:1,
            numberofPages:0,
            showeditModal:false,
            editCategory:{
                productTagCategoryDtos:[]
            },
            setting:{
                placeholder: "Select category",
                width:'100%'
            },
            showdeleteModal:false,
            deleteId:""
        }
    },
    computed:{
        getFirstItem(){
            return this.page * this.rowPerpage || 1
        },
        getLastItem(){
            return  this.page * this.rowPerpage + this.categories.length
        },
        getSelectCategories(){
            let categories  = this.TagCategory.map(item =>{

                let computedItem = {
                    id: item.id, 
                    text: item.name,
                }

                return computedItem
            })

            return categories.sort((a,b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0));
        },
        getUpdatedCategories(){
            let categories  = this.editCategory.productTagCategoryDtos.map(item =>{
                return item.id;
            })

            return categories; 
        }
    },
    methods:{
       prev(){
            if(this.page - 1 >= 0){
                this.categories = []
                this.$emit('changePage',{
                    page: this.page - 1,
                    size: this.rowPerpage
                })
            }
        },
        next(){
            if(this.page + 1 < this.columns.totalPages){
                this.categories = []
                this.$emit('changePage',{
                    page: this.page + 1,
                    size: this.rowPerpage
                })
            }
        },
        changeDate(date){
           return moment(date).format('llll');
        },
        toggleEditModal(){
            this.showeditModal = !this.showeditModal
        },
        editModal(id){
           this.editCategory = this.categories.find(item => item.id == id);
           this.editCategory.productTagCategories = this.getUpdatedCategories
           this.toggleEditModal()
        },
        async updateTag(){
            if(!this.editCategory.name){
                return this.error = "Tag name field is required"
            }

            if(!this.editCategory.productTagCategories.length){
                return this.error = "Select at least a tag category"
            }

            if(!this.editCategory.description){
                return this.error = "Tag description field is required"
            }

            this.error = null
            
            try{
                this.showPreloader = true
                let url = this.$store.state.base_url + 'product-tag/' + this.editCategory.id;
                let data = {
                    "description": this.editCategory.description,
                    "name":this.editCategory.name,
                    "productTagCategories": this.editCategory.productTagCategories
                }
                let token = await this.$store.getters.getToken
                let res = await this.axios.put(url,data,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                this.$emit('updateTag',res.data);
                this.showPreloader = false  
                this.toggleEditModal();
            }catch(error){
                if(error.response.status == 400){
                    this.showPreloader = false  
                    return this.error = error.response.data.message
                }
                console.log(error)
            }
        },
        selectedCategory(data){
            this.editCategory.productTagCategories = data.map(item => parseInt(item));
        },
        toogledelete(id){
            this.deleteId = id
            this.showdeleteModal = !this.showdeleteModal
        },
        async deleteTag(){
            try{
                this.showPreloader = true
                let url = this.$store.state.base_url + 'product-tag/' + this.deleteId;
                let token = await this.$store.getters.getToken
                let res = await this.axios.delete(url,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                this.showPreloader = false  
                this.$emit('removeTag', this.deleteId);
                this.toogledelete("");
            }catch(error){
                console.log(error)
            }

        }
    },
    watch: {
        columns: function () {
            this.categories = this.columns.content.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
            this.numberofPages =this.columns.totalPages
        },
        rowPerpage : function () {
            this.categories = []
            this.$emit('changePage',{
                page: 0,
                size: this.rowPerpage
            })
        }
    },
}
</script>

<style>

</style>