<template>
    <Auth>
        <div class="md:mx-14 mx-3 w-screen h-screen   flex items-center">
            <div class="flex-grow lg:flex-grow-0">
                <div v-if="!success">
                    <h1 class="font-semibold text-4xl">Forgot password</h1>
                    <p class="mt-4 text-sm">Enter an e-mail associated with your account and we will send an email with an instructions to reset your password</p>
                    <form @submit.prevent="ResetPassword" class="mt-4">
                        <div v-if="error.empty" class="text-sm text-red-500 my-4" >{{ error.empty }}</div>
                        <div v-if="success" class="text-sm text-green-500 my-4" >{{ success }}</div>
                        <div>
                            <label for="email" class="text-sm">Email address</label>
                            <div class="input flex space-x-2" >
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                </svg>
                                <input type="text" v-model="email" class="focus:outline-none w-full" placeholder="Email address">
                            </div>
                        </div>
                        <div class="mt-4" >
                            <button :disabled="showPreloader" class="bg-brand p-3 font-semibold rounded w-full flex justify-center">
                                <Preloader v-if="showPreloader"/>
                                <span v-else >Reset password</span> 
                            </button>
                            <p class="flex-grow flex items-center justify-center md:justify-start mt-4 text-sm" >Already have an account? <router-link :to="{ name: 'Login' }" class="text-brand font-semibold ml-1">Login</router-link></p>
                        </div>
                    </form> 
                </div>   
                <div v-else>
                    <h1 class="font-semibold text-4xl" >Check E-mail</h1>
                    <p class="mt-4">We have sent a password recover instructions to your email.<br>Didn't receive email? Check your spam filter</p>
                    <button ref="loginBtn" @click="resend" class="bg-brand px-3 mt-4 py-2 font-semibold rounded">
                        Resend
                    </button>
                    <p class="mt-4"></p>
                </div> 
            </div>        
        </div>
    </Auth>
</template>
<script>
import Auth from "@/components/admin/Auth.vue";
import Preloader from "@/components/Preloader.vue";
export default {
    name:['ForgotPassword'],
    components:{
        Auth,Preloader
    },
    data(){
        return{
            email:"",
            success:false,
            showPreloader:false,
            error:{
                email:null,
                empty:null,
            }
        }
    },
    methods: {
        async ResetPassword(){
            let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if(!this.email.match(regexEmail)){
                this.error.empty = "Invaild email address"
            }else{
                this.error.empty = null;
                this.showPreloader = true;
                let data = {
                    email: this.email,
                    url: window.location.origin
                }
                try {
                    let url = this.$store.state.base_url + 'user/forgot-password'
                    await this.axios.post(url,data)
                    this.success = true;
                    this.showPreloader = false;
                } catch (error) {
                    this.showPreloader =  false
                    this.error.empty = (err.response.data.statusCode == '404') ? "Email address do not exist" : null
                }
            }
        },
        resend(){
            this.success = false;
        }
    
    }
 
}
</script>

<style>
:root{
--loader-width: 20px;
--loader-height: 20px;
--loader-color-primary: #000000;
--loader-color-secondary: #eee;
--line-width: 2px;
--animation-duration: 2s;
--loader-initial-scale: 0.1;
}
</style>