<script>
export default {
    props:['columns','page','msg','size'], 
    name:"BuyForMeRestaurantTable",
    components:{

    },
    data(){
        return {
            rowPerpage:10,
            search:"",
            rows:[
                'Restaurant name',
                'Email address',
                'Phone number',
            ],
            rowPerpage:10,
        }
    },
    emits:['changePage','changeStatus'],
    computed:{
        getFirstItem(){
            return this.page * this.rowPerpage || 1
        },
        getLastItem(){
            return  this.page * this.rowPerpage + this.columns.content.length
        },
    },
    methods:{
        prev(){
            if(this.page - 1 >= 0){
                this.$emit('changePage',{
                    page: this.page - 1,
                    size: this.rowPerpage
                })
            }
        },
        next(){
            if(this.page + 1 < this.columns.totalPages){
                this.$emit('changePage',{
                    page: this.page + 1,
                    size: this.rowPerpage
                })
            }
        },
        sortList(){

        },
        async changeStatus(restaurantId, status){
            this.error = {}

            let url = this.$store.state.base_url + 'admin/restaurant/eligible-for-buy-me-restaurant/' + restaurantId;

            let data = {
                restaurantId: restaurantId,
                status: status
            }

            this.$emit('changeStatus', data);
           
            try{
                let token = await this.$store.getters.getToken
                let res = await this.axios.put(url,null,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
            }catch(error){
                console.log(error)
            }
        }
    }
}
</script>
<template>
    <div class="flex justify-between mb-4" >
        <div class="flex space-x-2">
            <p class="text-sm flex items-center">Rows per page</p>
            <select v-model="rowPerpage" class="border p-0.5 focus:outline-none " name="" id="">
                <option value="10">10</option>
                <option value="25" >25</option>
                <option value="50" >50</option>
            </select>
        </div>
        <div class="flex space-x-3 border border-gray-100 p-0.5 px-3">
            <input type="text" @input="sortList" v-model="search"  placeholder="Search restaurants" class="focus:outline-none" >
            <button>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
            </button>
        </div>
    </div>
    <table class="w-full" >
        <thead class="bg-gray-100 border-gray-100 border">
            <tr class="">
                <th v-for="row in rows" :key="row" class="text-sm py-3 px-3 text-left" >{{ row }}</th>
            </tr>
        </thead>
        <tbody v-if="columns.content.length < 1">
            <tr class="border border-t-0" >
                <td  class="text-sm p-3 text-center" :colspan="rows.length">{{ msg }}</td>  
            </tr>
        </tbody>
        <tbody>
            <tr v-for="col in columns.content" :key="col" class="border border-t-0">
                <td class="text-sm p-3" >{{ col.name }} </td>
                <td class="text-sm p-3">{{ col.email }} </td>
                <td class="text-sm p-3">{{ col.phoneNumber}} </td>
                <!-- <td class="text-sm p-3">
                    <button @click="changeStatus(col.restaurantId,col.eligibleForBuyForMeDelivery)" v-if="!col.eligibleForBuyForMeDelivery" class="bg-green-500 text-xs py-2 px-4 rounded-full text-gray-100">Enable</button>
                    <button v-else  @click="changeStatus(col.restaurantId,col.eligibleForBuyForMeDelivery)" class="bg-red-500 text-xs py-2 px-4 rounded-full text-gray-100">Disable</button>
                </td> -->
            </tr>
        </tbody>
    </table>
    <div class="flex justify-between bg-gray-100 h-12 px-4  items-center " >
        <div> {{ page + 1 }} of {{ columns.totalPages }} pages (Showing {{ getFirstItem }} - {{ getLastItem }} of {{ columns.totalElements }} Entries)</div>
        <div class="flex flex-grow  justify-end" >
            <div class="flex space-x-1">
                <button @click="prev(currentPage)" class="pagination-btn active text-sm">Prev</button>
                <div  class="border border-gray-300  px-3 py-0.5" > {{ page + 1 }} </div>
                <button @click="next(currentPage)" class="pagination-btn active text-sm">Next</button>
            </div>
        </div>
    </div>
</template>
