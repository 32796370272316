<template>
    <Admin>
        <div class="flex justify-between">
            <div class="flex" > 
                <div class="h-2 w-2 bg-brand" ></div>  
                 <h1 class="font-semibold text-3xl" >Waiting Users</h1>
            </div>
            <div class="flex space-x-3 items-center" >
                <button @click="downloadExcel" class="p-3 bg-brand rounded flex space-x-2" >
                    <div>
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                        </svg>
                    </div>
                    <p class="text-sm" >Download</p>
                </button>
            </div>
        </div>
        <div class="mt-8" >
            <Table :rows="rows" :columns="columns"/>
        </div>
    </Admin>
</template>

<script>
import Admin from "@/components/admin/AdminTemplate.vue";
import Table from "@/components/admin/Tables/WaitingTable.vue";
import Modal from "@/components/Modal.vue";

import Preloader from "@/components/Preloader.vue";
const xlxs = require("xlsx");
export default {
    name:['AdminUser'],
    components:{
        Admin,Table,Modal,Preloader
    },
    data(){
        return{
            firstname:"",
            lastname:"",
            email:"",
            gender:"",
            error:"",
            rows:['Email Address','Phone Number','Created'],
            columns:[],
            showModal:false,
            showPreloader:false
        }
    },
    methods:{
        toggleModal(){
            this.showModal = !this.showModal
        },
        async getAllUser(){
            let url = this.$store.state.base_url + 'wait-list';
            try {
                let token = await this.$store.getters.getToken
                let res = await this.axios.get(url,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                this.columns = res.data
            } catch (error) {
                console.log(error)   
            }
   
        },
        downloadExcel(){
            let excel = [];
            this.columns.forEach((item)=>{
                let obj = {
                    "Email address":item.email, 
                    "Phone Number" : (item.phone != "string") ? item.phone : "-"                
                }
               excel.push(obj);
            })
            let newWB = xlxs.utils.book_new()
            let newWs = xlxs.utils.json_to_sheet(excel)
            newWs['!cols'] = [{ width: 49 },{ width: 49 }];

            xlxs.utils.book_append_sheet(newWB,newWs,`Noshtribe waiting list`);
            xlxs.writeFile(newWB,`Noshtribe _waiting_list.xlsx`)
            
        }
        
    },
    beforeMount(){
        this.getAllUser();
    }
}
</script>

<style>
:root{
	--loader-width: 20px;
	--loader-height: 20px;
	--loader-color-primary: #000000;
	--loader-color-secondary: #eee;
	--line-width: 2px;
	--animation-duration: 2s;
	--loader-initial-scale: 0.1;
}
</style>