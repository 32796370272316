<template>
  <div class="bg-white flex-grow rounded-tl-3xl p-8" >
    <slot></slot>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>