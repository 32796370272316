<template>
    <Admin>
        <div class="flex justify-between">
            <div class="flex" > 
                <div class="h-2 w-2 bg-brand" ></div>  
                 <h1 class="font-semibold text-3xl" >Customers</h1>
            </div>
        </div>
        <div class="mt-8" >
            <Table :rows="rows" :columns="columns" :msg="msg" @changePage="changePage" :page="page"/>
        </div>
    </Admin>
</template>

<script>
import Admin from "@/components/admin/AdminTemplate.vue";
import Table from "@/components/admin/Tables/VendorTable.vue";
import Modal from "@/components/Modal.vue";

import Preloader from "@/components/Preloader.vue";
export default {
    name:['AdminUser'],
    components:{
        Admin,Table,Modal,Preloader
    },
    data(){
        return{
            firstname:"",
            lastname:"",
            email:"",
            gender:"",
            error:"",
            rows:['Fullname','Email Address','Phone Number','Status','Created', 'Actions'],
            columns:[],
            showModal:false,
            showPreloader:false,
            msg:"Loading ...",
            page:0,
            size:10
        }
    },
    methods:{
        toggleModal(){
            this.showModal = !this.showModal
        },
        async getAllUser(){
            let url = this.$store.state.base_url + 'admin/customers';
            try{
                let token  = await this.$store.getters.getToken;
                let res = await  this.axios.get(url,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    },
                    params:{
                        page:this.page,
                        size:this.size
                    }
                })
                this.columns = res.data
                if(!this.columns.content.length){
                    this.msg = "No data found"
                }
            }catch(error){
                console.log(error)
            }

        },
        async addUser(){   
            let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (!this.firstname || !this.lastname || !this.gender || !this.email.match(regexEmail)) {
                this.error = "Please in the form"
            }else{
                this.error = null;
            }
            //Check your error
            if (!this.error) {
                 this.error = null;
                this.showPreloader = true;
                this.$refs.submit.disabled = true;
                let data = {
                    firstName: this.firstname,
                    lastName: this.lastname,
                    email: this.email,
                    gender: this.gender
                }   
                let url = this.$store.state.base_url + 'register'
                try{
                    let token = await this.$store.getters.getToken
                    let res = await this.axios.post(url,data,{
                        headers: {
                            Authorization: 'Bearer ' + token 
                        }
                    })
                    await this.getAllUser();
                    this.toggleModal();
                    this.$refs.addUser.reset();
                }catch(error){
                    let form = document.getElementById("form");
                    form.reset();
                    console.log(form);
                    this.error = error.response.data.message
                    this.showPreloader = false;
                    this.$refs.submit.disabled = false;
                }      
            }
        },
        async changePage(data){
            this.columns.content = [] 
            this.msg = "Loading ..."
            this.page = data.page
            this.size = data.size
            await this.getAllUser()           
        }
    },
    beforeMount(){
        this.getAllUser();
    }
}
</script>

<style>
:root{
	--loader-width: 20px;
	--loader-height: 20px;
	--loader-color-primary: #000000;
	--loader-color-secondary: #eee;
	--line-width: 2px;
	--animation-duration: 2s;
	--loader-initial-scale: 0.1;
}
</style>