import { createStore } from 'vuex'
var CryptoJS = require("crypto-js");
import axios from "../axios";
import moment from 'moment';

export default createStore({
  state: {
    Address: {},
    base_url: process.env.VUE_APP_BASE_URL,
    dellyman_base_url:'https://dellyman.com/api/v3.0/',
    cart: [],
    navigatorError:"",
    errAddress:"",
    order:"",
    orderSummary:"",
    deliverydate : "",
    timebelt : "not availble",
    countCart: 0,
    OrderStoreName : "",
    user:"",
    newChange:false,
    userProfile:"",
    delievryStatus:[
      { name: "Ready for Pickup", status:"ORDER_READY_FOR_PICKUP"},
      { name: "Order Shipped", status: "ORDER_SHIPPED"},
      { name: "Order Delivered", status: "ORDER_DELIVERED" },
      { name: "Order Cancelled", status: "ORDER_CANCELLED" },
      { name: "Order Refunded", status: "ORDER_REFUNDED" },
    ]
    },
  getters: {
    getAddress(state) {
      let deliveryAddress = localStorage.getItem('noshtribe_address') ? JSON.parse(localStorage.getItem('noshtribe_address')) : [];
      if (typeof deliveryAddress !== 'undefined') {
        state.Address = deliveryAddress
      }      
      return state.Address
    },
    getNewChange(state){
       return state.newChange
    },
    getTimebelt(state){
      let timebelt = localStorage.getItem('nushtribe_timebelt') ? JSON.parse(localStorage.getItem('nushtribe_timebelt')): [];
      if (typeof timebelt !== 'undefined') {
        state.timebelt = JSON.parse(localStorage.getItem('nushtribe_timebelt'));
      }
      return state.timebelt
    },
    async getToken(state){
      let token = localStorage.getItem('user')
      if (token) {
        var bytes = CryptoJS.AES.decrypt(token, process.env.VUE_APP_SECRET_PHRASE);
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        state.user = decryptedData;
        if(moment().format() > moment(state.user.accessToken.expiryDate).format()){
          try {
            let response = await axios.post('/auth/token-refresh',{},{
                headers:{
                  'Refresh-Token': state.user.refreshToken.token
                }
            });
            state.user.accessToken = response.data
            var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(state.user), process.env.VUE_APP_SECRET_PHRASE).toString();
            localStorage.setItem("user",ciphertext) 

            return state.user.accessToken.token
          } catch (error) {
            console.log(error)
          } 
        }
        return state.user.accessToken.token
      } else{
        state.user = ""
        return ;
      } 
      
    },
    getUser(state){
      let token = localStorage.getItem('user') ? localStorage.getItem('user') : null;
      if (token) {
          var bytes = CryptoJS.AES.decrypt(token, process.env.VUE_APP_SECRET_PHRASE);
          var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          state.user = decryptedData;
      }
      return state.user
    },
    getDeliverydate(state) {
      return state.deliverydate
    },
    getDate(state){
      const date = localStorage.getItem('nushtribe_delivery_date') ? localStorage.getItem('nushtribe_delivery_date') : '';
      if (date) {
        state.deliverydate = date;
      }
      return state.deliverydate
    },
    getBaseUrl(state){
      return state.base_url
    },
    getCart(state){
      const cartDetails = localStorage.getItem('nushtribe_cart') ? JSON.parse(localStorage.getItem('nushtribe_cart')):[];
      if (typeof cartDetails !== 'undefined'){
        state.cart = cartDetails;
      }
      return state.cart
    },
    getcountCart(state) {
      const cartDetails = localStorage.getItem('nushtribe_cart') ? JSON.parse(localStorage.getItem('nushtribe_cart')) : [];
      if (typeof cartDetails !== 'undefined') {
        state.countCart = cartDetails.length
      }
      return state.countCart
    },
    getOrderStoreName(state){
      const StoreName = localStorage.getItem('nushtribe_resturant_name') ? localStorage.getItem('nushtribe_resturant_name') : '';
      if (StoreName) {
        state.OrderStoreName = StoreName;
      }
      return state.OrderStoreName
    },
    getnavigatorError(){
      return state.navigatorError
    },
    getOrder(state) {
      const orderDetails = localStorage.getItem('noshtribe_order_created') ? JSON.parse(localStorage.getItem('noshtribe_order_created')) : [];
      if (typeof orderDetails !== 'undefined') {
        state.order = orderDetails;
      }
      return state.order
    },
    getCost(state){
      return state.orderSummary
    }

  },
  mutations: {
    addCart(state,addCart){
      state.cart = addCart;
      localStorage.setItem('nushtribe_cart',addCart);
      if (state.user) {
        let url = state.base_url + 'cart';
        axios.post(url, JSON.parse(state.cart), {
          headers: {
            Authorization: 'Bearer ' + state.user.access_token
          }
        })
      }
    },
    addResturantName(state, name) {
      state.OrderStoreName = name;
      localStorage.setItem('nushtribe_resturant_name', name);
    },
    errAddress(state,error){
      state.errAddress = error
    },
    emptyCart(state){
      state.cart = []
      localStorage.removeItem('nushtribe_cart');
      if (state.user) {
        let url = state.base_url + 'cart';
        axios.post(url,[], {
          headers: {
            Authorization: 'Bearer ' + state.user.access_token
          }
        })
      }
    },
    addOrder(state,order){
      state.order = order;
      localStorage.setItem('noshtribe_order_created', order);
    },
    addCost(state,cost){
      state.orderSummary = cost;
    },
    addTimeBelt(state,timebelt){
      localStorage.setItem('nushtribe_timebelt', timebelt);
      state.timebelt = timebelt
    },
    addDeliveryaddress(state,deliveryAddress){
      state.Address = deliveryAddress;
      localStorage.setItem('noshtribe_address', deliveryAddress);
    },
    addDeliverydate(state,date){
      localStorage.setItem('nushtribe_delivery_date',date);
      state.deliverydate = date
    },
    addUserToken(state,token){
        localStorage.setItem('user',token);
        state.user = token
    },
    addUserProfile(state,profile){
        state.userProfile = profile
    },
    emptyResurantName(state){
      state.OrderStoreName = "";
      localStorage.removeItem('nushtribe_resturant_name');
    },
    removeOrder(state){
      state.order = "";
      localStorage.removeItem('noshtribe_order_created');
    },
    removeUserToken(state){
      state.user = null;
      localStorage.removeItem('user');
    },
    emptyTimebelt(state){
      state.deliverydate = "";
      state.timebelt = "";
      localStorage.removeItem('nushtribe_timebelt');
      localStorage.removeItem('nushtribe_delivery_date');
    },
    addCountcart(state,count){
      state.countCart = count
    },
    setNewChange(state,bool){
        state.newChange = bool
    }
  },
  actions: {
    addDeliveryaddress(context,deliveryAddress){
      context.commit('addDeliveryaddress',deliveryAddress)
    },
    addCart(context,addCart){
      context.commit('addCart',addCart)
    },
    errAddress(context, error) {
      context.commit('errAddress', error)
    },
    emptyCart(context){
      context.commit('emptyCart')
    },
    addOrder(context,order){
      context.commit('addOrder',order)
    },
    addCost(context,cost){
      context.commit("addCost",cost)
    },
    addTimeBelt(context,timebelt){
      context.commit('addTimeBelt',timebelt)
    },
    addDeliverydate(context,date){
      context.commit('addDeliverydate',date)
    },
    addResturantName(context,name){
      context.commit('addResturantName',name)
    },
    emptyResurantName(context) {
      context.commit('emptyResurantName')
    },
    emptyTimebelt(context) {
      context.commit('emptyTimebelt')
    },
    addCountcart(context,count){
      context.commit('addCountcart',count)
    },
    addToken(context,token){
      context.commit('addToken',token)
    },
    removeUserToken(context){
      context.commit('removeUserToken');
    },
    addUserToken(context,token){
      context.commit('addUserToken',token)
    },
    addUserProfile(context,profile){
      context.commit('addUserProfile',profile)
    },
    removeOrder(context) {
      context.commit('removeOrder')
    },
    setNewChange(context,bool){
      context.commit('setNewChange', bool)    
    }
  },
  modules: {
  }
})
