<template>
    <Admin>
        <div class="flex justify-between">
            <div class="flex" > 
                <div class="h-2 w-2 bg-brand" ></div>  
                 <h1 class="font-semibold lg:text-3xl text-xl" >Wallet</h1>
            </div>
            <div class="flex space-x-3 items-center" >
                <button @click="toggleModal" class="p-3 bg-brand rounded flex space-x-2" >
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    <p class="text-sm font-semibold" >Fund wallet</p>
                </button>
            </div>
        </div>
        <Table :rows="rows" :columns="walletTransactions" :page="page" :msg="msg" @changePage="changePage" />
    </Admin>
    <Modal v-if="showModal" width="w-500"  :show="true">
        <div class="flex justify-between">
            <h1 class="text-xl font-semibold">Fund Wallet</h1>
            <div @click="toggleModal">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div>  
         <div v-if="error" class="text-sm text-red-500 mt-3">{{ error }}</div>
        <form  @submit.prevent="fundUser" class="mt-3">
            <div>
                <label for="vendor" class="text-sm" >Select Customer</label>
                <select v-model="userId" class="input" >
                    <option value="">Select Customer</option>
                    <option v-for="customer in customers" :key="customer" :value="customer.userId">{{ customer.firstName }} {{ customer.lastName }} ({{ customer.email }})</option>
                </select>
            </div>
            <div class="mt-4" >
                <label for="confirm" class="text-sm">Amount Paid</label>
                <input type="number" step="0.01" v-model="amount" class="input" placeholder="Amount Paid">
            </div>
            <div class="mt-4" >
                <label for="confirm" class="text-sm">Comment</label>
                <textarea type="text" maxlength="200" v-model="comment" cols="10" rows="2" class="input" placeholder="Enter Comment"></textarea>
            </div>
            <div class="mt-4 flex-grow flex space-x-2 justify-end">
              <button @click="toggleModal" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                Close
              </button>
            <button type="submit" ref="submit" class="bg-brand px-3 text-sm py-2 font-semibold rounded">
                <Preloader v-if="showPreloader"/>
                <span v-else >Fund Wallet</span>      
            </button>
            </div>  
        </form>
    </Modal>
</template>
<script>
import Admin from "@/components/admin/AdminTemplate.vue";
import Modal from "@/components/Modal.vue";
import Preloader from "@/components/Preloader.vue";
import Table from "@/components/admin/Tables/WalletTable"

    export default{
        name:"Wallet",
        components:{
            Admin,Modal,Preloader,Table
        },
        data(){
            return{
                customers:[],
                showModal:false,
                userId:"",
                amount:"",
                comment:"",
                error:null,
                rows:['Customer','Transaction reference','Transaction type','Current charge','Wallet balance','Date'],
                showPreloader:false,
                walletTransactions:{
                    content:[]
                },
                msg:"Loading ...",
                page:0,
                size:10
            }
        },
        methods:{
            toggleModal(){
                this.showModal = !this.showModal
                this.error = null; 
                this.userId = this.amount = this.comment = ""
            },
            async getAllCustomer(){
                let url = this.$store.state.base_url + 'admin/customers';
                try{
                    let token = await this.$store.getters.getToken
                    let res = await this.axios.get(url,{
                        headers: {
                            Authorization: 'Bearer ' + token
                        },params:{
                            page:0,
                            size:3000
                        }
                    })
                    this.customers = res.data.content
                }catch(error){
                    console.log(error)
                }
            },
            async fundUser(){
                this.error = (!this.amount || !this.userId) ? "All fields are required" : null
                if(!this.error){
                    this.showPreloader = true
                    let data = {
                        "amountPaid": this.amount,
                        "userId": this.userId,
                        "adminFundingReason": this.comment
                    }
                    let url = this.$store.state.base_url + `admin/fund-wallet`;
                    try {
                        let token = await this.$store.getters.getToken;
                        await this.axios.post(url,data,{
                            headers: {
                                Authorization: 'Bearer ' + token
                            }
                        })
                        this.showPreloader = false;
                        await this.getWalletTransaction();
                        this.toggleModal() 
                    } catch (error) {
                        console.log(error);
                    }
                }
            },
            async getWalletTransaction(){
                try {
                    let url = this.$store.state.base_url + `admin/wallet/transactions`;
                    let token = await this.$store.getters.getToken;
                    let res  = await this.axios.get(url,{
                        headers: {
                            Authorization: 'Bearer ' + token
                        },
                        params:{
                            page:this.page,
                            size:this.size
                        }
                    });
                    this.msg = "No data found";
                    this.walletTransactions = res.data
                } catch (error) {
                    this.msg = "No data found";
                }
            },
            async changePage(data){
                this.walletTransactions.content = [] 
                this.msg = "Loading ..."
                this.page = data.page
                this.size = data.size
                await this.getWalletTransaction()           
            }

        },
        beforeMount(){
            this.getAllCustomer();
            this.getWalletTransaction();
        }
    }
</script>