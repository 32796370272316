<template>
    <Admin>
        <div class="flex justify-between">
            <div class="flex" > 
                <div class="h-2 w-2 bg-brand" ></div>  
                <h1 class="font-semibold text-3xl">Restaurant Settlements</h1>
            </div>
            <div class="flex space-x-3 items-center" >
                <button @click="toogleQuickSettlements"  class="p-3 bg-brand rounded flex space-x-2" >
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
                        </svg>
                    </div>
                    <p class="text-sm" >Quick Settlements</p>
                </button>
                <button @click="toogleAddUserQueue"  class="p-3 bg-brand rounded flex space-x-2" >
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    <p class="text-sm" >Add Vendor/Restaurant</p>
                </button>
            </div>
        </div>

        <div class="flex justify-center my-20">
            <div class="w-3/5 bg-gray-100 shadow-md rounded-full">
                <select @change="filterResturant" v-model="selectedRestaurant" class="bg-transparent p-4 w-full text-sm focus:outline-none" id="">
                    <option value="">Select Restaurant</option>
                    <option v-for="name in resturants" :key="name" :value="name.name">{{ name.name }}</option>
                </select>
            </div>
        </div>

        <Table :rows="rows" :page="page" :columns="computedColumns" :msg="msg"  @changePage="changePage" />
    </Admin>

    <Modal v-if="settleAllRestaurant" width="w-500" :show="true">
        <h1 class="text-2xl font-semibold">Are you sure</h1> 
        <p class="py-2" >Do you really want to settle all restaurant</p>
        <div class="mt-4 flex-grow flex space-x-2 justify-end">
            <button @click="tooglesettleAllRestaurant" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                No
            </button>
            <button @click="postsettleAllRestaurant"  type="submit" ref="submit" class="bg-brand px-3 text-sm py-2 font-semibold rounded">
                <Preloader v-if="showPreloaderRestaurant"/>
                <span v-else>Yes</span>      
            </button>
        </div> 
    </Modal>
    
    <Modal v-if="showAddUserQueue" width="w-500" :show="true">
         <div class="flex justify-between">
            <h1 class="text-xl font-semibold">Add Vendor/Restaurant</h1>
            <div @click="toogleAddUserQueue">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div>
        <div v-if="error" class="text-sm text-red-500">{{ error }}</div> 
        <form @submit.prevent="QueueUsers">
            <div>
                <select class="input" v-model="queueType">
                    <option value="">Select Queue Type</option>
                    <option v-for="name in QueueTypes" :key="name" :value="name.value">{{ name.name }}</option>
                </select>
            </div>
            <div v-if="this.queueType == 'restaurant'">
                <select class="input" v-model="restaurantId">
                    <option value="">Select Restaurant</option>
                    <option v-for="name in resturants" :key="name" :value="name.restaurantId">{{ name.name }}</option>
                </select>
            </div>
            <div v-else-if="this.queueType == 'vendor'">
                <select v-model="vendorId" class="input" >
                    <option value="">Select Vendor</option>
                    <option v-for="vendor in vendors" :key="vendor" :value="vendor.userId">{{ vendor.firstName }} {{ vendor.lastName }} ({{ vendor.email }})</option>
                </select>
            </div>
            <div class="mt-4 flex-grow flex space-x-2 justify-end">
                <button @click="toogleQuickSettlements" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                    Close
                </button>
                <button type="submit" :disabled="showPreloaderRestaurant" ref="submit" class="bg-brand px-3 text-sm py-2 font-semibold rounded">
                    <Preloader v-if="showPreloaderRestaurant"/>
                    <span v-else>Settle</span>      
                </button>
            </div>
        </form>
    </Modal>
    <Modal v-if="showQuickSettlement" width="w-500" :show="true">           
        <div class="flex justify-between">
            <h1 class="text-xl font-semibold">Quick Settlements</h1>
            <div @click="toogleQuickSettlements">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div>
        <div v-if="error" class="text-sm text-red-500">{{ error }}</div> 
        <form @submit.prevent="settleAllForm">
            <div>
                <select class="input" v-model="settlementType">
                    <option value="">Select Settlement Type</option>
                    <option v-for="name in settlementTypes" :key="name" :value="name.value">{{ name.name }}</option>
                </select>
            </div>
            <div class="mt-4 flex-grow flex space-x-2 justify-end">
                <button @click="toogleQuickSettlements" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                    Close
                </button>
                <button type="submit" :disabled="showPreloaderRestaurant" ref="submit" class="bg-brand px-3 text-sm py-2 font-semibold rounded">
                    <Preloader v-if="showPreloaderRestaurant"/>
                    <span v-else>Settle</span>      
                </button>
            </div>
        </form>
    </Modal>
</template>

<script>
import Admin from "@/components/admin/AdminTemplate.vue";
import Modal from "@/components/Modal.vue";
import Preloader from "@/components/Preloader.vue";
import Table from "@/components/admin/Tables/SettlementTable"

export default {
    name:"Settlements",
    components:{
        Admin,Modal,Preloader,Table
    },
    data(){
        return{
            rows:['Transaction id','Amount','Restaurant','Status', 'Created','Action'],
            columns:{
                content:[]
            },
            msg:"Loading ...",
            settleAllRestaurant:false,
            showPreloaderRestaurant:false,
            showQuickSettlement:false,
            showAddUserQueue:false,
            settlementType:"",
            settlementTypes:[
                {name:"Settle All Restaurant in Next Cycle",value:"settlementAllRestaurantInNextSettlementCycle"},
                {name:"Settle All Restaurant(Due)",value:"settleAllRestaurantDueNow"},
                {name:"Settle All Restaurant Now",value:"settleAllRestaurantNow"},
                
            ],
            error:"",
            QueueTypes:[
                {name:"Restaurant",value:"restaurant",url:'admin​/settlement​/queue​/restaurant​/'},
                {name:"Vendor",value:"vendor",url:'admin​/settlement​/queue​/vendor/'},
            ],
            queueType:"",
            restaurantId:"",
            resturants:[],
            vendors:[],
            vendorId:"",
            selectedRestaurant:"",
            computedColumns:{
                content:[]
            },
            page:0,
            size:10
        }
    },
    methods:{
        async getAllSettlement(){
            try {
                let url = this.$store.state.base_url + 'admin/settlement/all';
                let token = await this.$store.getters.getToken
                let res  = await this.axios.get(url,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    },
                    params:{
                        page:this.page,
                        size:this.size
                    }
                })
                this.columns = res.data; 
                
                this.computedColumns = res.data
                this.msg = "No data found"
            }
            catch(err){
                this.msg = "No data found"
            }           
        },
        tooglesettleAllRestaurant(){
            this.settleAllRestaurant = !this.settleAllRestaurant
        },
        async postsettleAllRestaurant(){
            this.showPreloaderRestaurant = true;
            try{
                let url = this.$store.state.base_url + 'admin/settlement/settle/all';
                let token = await this.$store.getters.getToken
                let res  = await this.axios.post(url,null,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                this.showPreloaderRestaurant = false;
                this.toogleQuickSettlements()
            } catch(err){
                this.showPreloaderRestaurant = false;
            }
        },
        toogleQuickSettlements(){
            this.showQuickSettlement = !this.showQuickSettlement
            this.settlementType = ""
        },
        settleAllForm(){
            if(!this.settlementType){
                this.error = "Select a settlement type"
            }
            else if(this.settlementType == "settlementAllRestaurantInNextSettlementCycle"){
                this.error = ""
                this.AllRestaurantInNextSettlementCycle();
            }
            else if(this.settlementType == "settleAllRestaurantDueNow"){
                this.error = ""
                this.settleAllRestaurantDueNow();
            }
            else if(this.settlementType == "settleAllRestaurantNow"){
                this.error = ""
                this.postsettleAllRestaurant();
            }
        },
        async AllRestaurantInNextSettlementCycle(){
            this.showPreloaderRestaurant = true;
            try{
                let url = this.$store.state.base_url + 'admin/settlement/queue/all';
                let token = await this.$store.getters.getToken;
                let res  = await this.axios.post(url,null,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                this.showPreloaderRestaurant = false;
                this.toogleQuickSettlements();
            } catch(err){
                this.showPreloaderRestaurant = false;
            }
        },
        async settleAllRestaurantDueNow(){
            this.showPreloaderRestaurant = true;
            try{
                let url = this.$store.state.base_url + 'admin/settlement/settle/due';
                let token = await this.$store.getters.getToken;
                let res  = await this.axios.post(url,null,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    },
                    params:{
                        page:0,
                        size:100
                    }
                })
                this.showPreloaderRestaurant = false;
                this.toogleQuickSettlements();
            } catch(err){
                this.showPreloaderRestaurant = false;
            }
        },
        toogleAddUserQueue(){
            this.showAddUserQueue = !this.showAddUserQueue
        },
        async getVendors(){
            let url = this.$store.state.base_url + 'admin/vendor';
            try {
                let token = await this.$store.getters.getToken;
                let res = await this.axios.get(url,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    },
                    params:{
                        page:0,
                        size:100
                    }
                })
                this.vendors = res.data.content
            } catch (err) {
                console.error(err);
            }
        },
        async getAllResturants(){
            let url =  this.$store.state.base_url + 'admin/all-restaurant';
            let token = await this.$store.getters.getToken;
            let res = await this.axios.get(url,{
                headers: {
                    Authorization: 'Bearer ' + token
                },
                params:{
                    page:0,
                    size:100
                }
            })
            this.resturants = res.data.content;
        },
        QueueUsers(){
            if(!this.queueType){
                this.error = "Select queue type"
            }
            else if (this.queueType == "restaurant" ){
                if(!this.restaurantId){
                    this.error = "Select a restaurant"
                }else {
                    this.QueueRestaurant();
                }
            }
            else if (this.queueType == "vendor" ){
                if(!this.vendorId){
                    this.error = "Select a vendor"
                }else {
                    this.QueueVendor();
                }
            }
        },
        async QueueRestaurant(){
            this.showPreloaderRestaurant = true;
            try{
                let url = this.$store.state.base_url + 'admin/settlement/queue/restaurant/' + this.restaurantId;
                let token  = await this.$store.getters.getToken;
                let res  = await this.axios.post(url,null,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                this.showPreloaderRestaurant = false;
                this.toogleAddUserQueue();
            } catch(err){
                this.showPreloaderRestaurant = false;
            }
        },
        async QueueVendor(){
            this.showPreloaderRestaurant = true;
            try{
                let url = this.$store.state.base_url + 'admin/settlement/queue/vendor/' + this.vendorId;
                let token = await this.$store.getters.getToken
                let res  = await this.axios.post(url,null,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                this.showPreloaderRestaurant = false;
                this.toogleAddUserQueue();
            } catch(err){
                this.showPreloaderRestaurant = false;
            }
        },
        filterResturant(){
            if(!this.selectedRestaurant){
                this.computedColumns = this.columns 
            }else{            
                this.msg = "No data found"
                let filterSettlementObject = this.columns
                filterSettlementObject.content = filterSettlementObject.content.filter((item) => item.restaurantName == this.selectedRestaurant)
                this.computedColumns = filterSettlementObject
            }

        },
        async changePage(data){
            this.computedColumns.content = []
            this.msg = "Loading ..."
            this.page = data.page
            this.size = data.size
            await this.getAllSettlement()           
        }
    },
    mounted(){
        this.getAllSettlement();
        this.getVendors();
        this.getAllResturants()
    }

}
</script>

<style>

</style>