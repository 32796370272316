<template>
    <div class="flex justify-between mb-4" >
        <div class="flex space-x-2">
            <p class="text-sm flex items-center">Rows per page</p>
            <select v-model="rowPerpage"  class="border p-0.5 focus:outline-none " name="" id="">
                <option value="10">10</option>
                <option value="25" >25</option>
                <option value="50" >50</option>
            </select>
        </div>
        <div class="flex space-x-3 border border-gray-100 p-0.5 px-3">
            <input type="text"  @input="sortList" v-model="this.search"  placeholder="Search" class="focus:outline-none" >
            <button>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
            </button>
        </div>
    </div>
    <table class="w-full" >
        <thead class="bg-gray-100 border-gray-100 border">
            <tr class="">
                <th v-for="row in rows" :key="row" class="text-sm py-3 px-3 text-left" >{{ row }}</th>
            </tr>
        </thead>
          <tbody v-if="vendors.length < 1">
            <tr class="border border-t-0" >
                <td  class="text-sm p-3 text-center" :colspan="rows.length">{{ msg }}</td>  
            </tr>
        </tbody>
        <tbody>
            <tr v-for="col in vendors.slice(startPage, Endpage)" :key="col" class="border border-t-0">
                <td class="text-sm p-3" >{{ col.firstName }} {{ col.lastName }} </td>
                <td class="text-sm p-3">{{ col.email }}  </td>
                <td class="text-sm p-3">{{ col.phoneNumber }} </td>
                <td class="text-sm p-3">
                    <span v-if="col.active" class="px-2 py-1 bg-green-400 text-xs rounded-full" >active</span>
                    <span v-else class="px-2 py-1 bg-gray-300 text-xs rounded-full">inactive</span>
                </td>
                <td class="text-sm p-3">
                    {{ changeDate(col.createdAt) }}
                </td>
                <td class="text-center" >
                    <button>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                        </svg>
                    </button>
                    <button @click="singleUser(col.userId)" class="text-purple-500" >
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                      </svg>
                    </button>
                    <button v-if="this.$route.name == 'AdminVendor'" @click="setCommsionVendor(col.userId)" class="text-indigo-500" >
                       <svg class="h-6 w-6"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="4" y="3" width="16" height="18" rx="2" />  <rect x="8" y="7" width="8" height="3" rx="1" />  <line x1="8" y1="14" x2="8" y2="14.01" />  <line x1="12" y1="14" x2="12" y2="14.01" />  <line x1="16" y1="14" x2="16" y2="14.01" />  <line x1="8" y1="17" x2="8" y2="17.01" />  <line x1="12" y1="17" x2="12" y2="17.01" />  <line x1="16" y1="17" x2="16" y2="17.01" /></svg>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="flex justify-between bg-gray-100 h-12 px-4  items-center " >
        <div> {{ page + 1 }} of {{ numberofPages }} pages (Showing {{ getFirstItem }} - {{ getLastItem }} of {{ columns.totalElements }} Entries)</div>
        <div class="flex flex-grow  justify-end" >
            <div class="flex space-x-1">
                <button @click="prev(currentPage)" class="pagination-btn active text-sm">Prev</button>
                <div  class="border border-gray-300  px-3 py-0.5" > {{ page + 1 }} </div>
                <button @click="next(currentPage)" class="pagination-btn active text-sm">Next</button>
            </div>
        </div>
    </div>
    <Modal v-if="showModal" width="w-768" :show="true">
        <div class="flex justify-between">
            <h1 class="text-xl font-semibold">View User</h1>
            <div @click="toggleModal">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div>
        <div class="grid grid-cols-3 mt-4 gap-4" >
            <div>
                <p class="text-sm" >Full Name</p>
                <p class="font-semibold" >{{ single.firstName }} {{ single.lastName }}</p>
            </div>
            <div>
                <p class="text-sm" >Email</p>
                <p class="font-semibold" >{{ single.email }}</p>
            </div>
            <div>
                <p class="text-sm" >Phone Number</p>
                <p class="font-semibold" >{{ single.phoneNumber }}</p>
            </div>
             <div>
                <p class="text-sm" >Date Added </p>
                <p class="font-semibold" >{{ changeDate(single.createdAt) }}</p>
            </div>
        </div>
        <div class="mt-4 flex-grow flex space-x-2 justify-end">
            <button @click="toggleModal" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                Close
            </button>
        </div>
    </Modal>
    <Modal v-if="showSetComission"  width="w-500" :show="true" >
        <div class="flex justify-between">
            <h1 class="text-xl font-semibold">Set Vendor Commission</h1>
            <div @click="tooglesetComission">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div>
        <div v-if="error" class="text-sm text-red-500 mt-3">{{ error }}</div>
        <form @submit.prevent="SumbitVendorComission">
            <div>
                <label for="commission" class="text-sm" >Set Commision</label>
                <input type="number" min="1" max="50" v-model="comission" class="input" placeholder="Set Commision">
            </div>
            <div class="mt-4 flex-grow flex space-x-2 justify-end">
                <button @click="tooglesetComission" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                    Close
                </button>
                <button type="submit" :disabled="showComissionPreloader" class="bg-brand px-3 text-sm py-2 font-semibold rounded">
                    <Preloader v-if="showComissionPreloader"/>
                    <span v-else >Set Commission</span>      
                </button>
            </div>  
        </form>
    </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import Preloader from "@/components/Preloader.vue";

import moment from "moment";

export default {
    props:['rows','columns','page','msg'],
    components:{
        Modal,Preloader
    },
    emits:['changePage'],
    data(){
        return{
            search:'',
            vendors: this.columns,
            showModal:false,
            searchResult:[],
            rowPerpage:10,
            Endpage: 10,
            startPage : 0,
            numberofPages:0,
            currentPage:1,
            showSetComission:false,
            comission:0,
            showComissionPreloader:false,
            error:""
        }
    },
    computed:{
        getFirstItem(){
            return this.vendors.findIndex((item)=> item.userId == this.vendors.slice(this.startPage, this.Endpage)[0].userId ) + 1
        },
        getLastItem(){
            return this.vendors.findIndex((item)=> item.userId == this.vendors.slice(this.startPage, this.Endpage)[this.vendors.slice(this.startPage, this.Endpage).length - 1].userId ) + 1
        }
    },
    methods: {
        sortList(){
            if(this.search.toLowerCase() == 'active' ){
               this.searchResult = this.columns.content.filter((col) => col.active == true)
            }else{
                this.searchResult = []
                this.searchResult = this.columns.content.filter((col) => 
                    col.firstName.toLowerCase().includes(this.search.toLowerCase()) ||
                    col.lastName.toLowerCase().includes(this.search.toLowerCase()) ||
                    col.email.toLowerCase().includes(this.search.toLowerCase()) 
                );  
            }
            this.vendors = this.searchResult;
            if (this.searchResult.length < 1 ) {
                this.vendors = this.columns.content
            }
            
        },
        toggleModal(){
            this.showModal = !this.showModal
        },
        singleUser(id){
            let singleUser = this.columns.content.find( (item) => item.userId === id);
            this.single = singleUser;
            this.toggleModal();
        },
        prev(){
            if(this.page - 1 >= 0){
                this.vendors = []
                this.$emit('changePage',{
                    page: this.page - 1,
                    size: this.rowPerpage
                })
            }
        },
        next(){
            if(this.page + 1 < this.columns.totalPages){
                this.vendors = []
                this.$emit('changePage',{
                    page: this.page + 1,
                    size: this.rowPerpage
                })
            }
        },
        tooglesetComission(){
            this.showSetComission = !this.showSetComission
        },
        setCommsionVendor(id){
            this.CommissionVendor = id;
            this.comission = 0;
            this.tooglesetComission();
        },
        async SumbitVendorComission(){
            this.showComissionPreloader = true;
            let url = this.$store.state.base_url + 'admin/vendor/commission/'+ this.CommissionVendor
            let token = await this.$store.getters.getToken
            await this.axios.post(url,null,{
                headers: {
                    Authorization: 'Bearer ' + token
                },
                params:{
                    newCommission: this.comission
                }

            })
             this.showComissionPreloader = false;
            this.tooglesetComission();
        },
        changeDate(date){
           return moment(date).format('llll');
        }
    },
    watch:{
        columns: function () {
            this.vendors = this.columns.content
            this.numberofPages = this.columns.totalPages
        },
        rowPerpage : function () {
            this.vendors = []
            this.$emit('changePage',{
                page: 0,
                size: this.rowPerpage
            })
        }
    }
}
</script>

<style>

</style>