import { createRouter, createWebHistory } from 'vue-router'
import store from '../store';

function CheckToken(to) {
  if (!store.getters.getUser) {
    router.push({ name: 'Login' });
  }else{
    let user = store.getters.getUser;
    if(user.role !== "ADMIN"){
      router.push('/not-allowed');
    }
  }
}
function deleteToken(to) {
  store.dispatch('removeUserToken')
  router.push({ name: 'Login' });
 
}
function redirectAdmin() {
  if (store.getters.getUser) {
    router.push({ name: 'Dashboard' });
  }
}

const routes = [
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Login.vue'),
    beforeEnter: [redirectAdmin],
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/password/ChangePassword.vue'),
    beforeEnter: [redirectAdmin],
  },
  {
    path: '/forget-password',
    name: 'ForgetPassword',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/password/Forgetpassword.vue'),
    beforeEnter: [redirectAdmin],
  },
  {
    path: '/email-confirmation',
    name: 'EmailConfirmation',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/password/EmailConfirmation.vue'),
    beforeEnter: [redirectAdmin],
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/admin/Dashboard.vue'),
    beforeEnter: [CheckToken],
  },
   {
    path: '/restaurants',
    name: 'AdminRestaurant',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Restaurants.vue'),
    beforeEnter: [CheckToken],
  },
  {
    path: '/referral',
    name: 'Referral',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Referral.vue'),
    beforeEnter: [CheckToken],
  },
  {
    path: '/audit-log',
    name: 'AdminLogs',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/AuditLogs.vue'),
    beforeEnter: [CheckToken],
  },
  {
    path: '/user/waiting-users',
    name: 'WaitingUsers',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Waiting.vue'),
    beforeEnter: [CheckToken],
  },
  {
    path: '/user/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Admins.vue'),
    beforeEnter: [CheckToken],
  },
  {
    path: '/user/vendors',
    name: 'AdminVendor',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Vendors.vue'),
    beforeEnter: [CheckToken],
  },
  {
    path: '/user/customers',
    name: 'AdminCustomer',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Customers.vue'),
    beforeEnter: [CheckToken],
  },
  {
    path: '/meals',
    name: 'AdminMeals',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Meals.vue'),
    beforeEnter: [CheckToken],
  },
  {
    path: '/meals/category',
    name: 'MealsCategory',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/MealsCategory.vue'),
    beforeEnter: [CheckToken],
  },
  {
    path: '/meals/tags',
    name: 'MealsTag',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/MealsTag.vue'),
    beforeEnter: [CheckToken],
  },
  {
    path: '/users/wallet',
    name: 'AdminWallet',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Wallet.vue'),
    beforeEnter: [CheckToken],
  },
  {
    path: '/settings',
    name: 'AdminSettings',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Settings.vue'),
    beforeEnter: [CheckToken],
  },
  {
    path: '/restaurants/settlements',
    name: 'Settlements',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Settlements.vue'),
    beforeEnter: [CheckToken],
  },
  {
    path: '/service/settlements',
    name: 'DeliverySettlements',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/DeliverySettlements.vue'),
    beforeEnter: [CheckToken],
  },
  {
    path: '/timebelts',
    name: 'Timebelts',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Timebelts.vue'),
    beforeEnter: [CheckToken],
  },
  {
    path: '/orders',
    name: 'AdminOrders',
    component: () => import('../views/admin/Orders.vue'),
    beforeEnter: [CheckToken],
  },
  {
    path: '/discount-codes',
    name: 'AdminDiscount',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Discount.vue'),
    beforeEnter: [CheckToken],
  },
  {
    path: '/reasons',
    name: 'AdminReasons',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Reasons.vue'),
    beforeEnter: [CheckToken],
  },
  {
    path: '/reviews',
    name: 'AdminReviews',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Reviews.vue'),
    beforeEnter: [CheckToken],
  },  
  {
    path: '/service-charge',
    name: 'ServiceCharge',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/ServiceCharge.vue'),
    beforeEnter: [CheckToken],
  },
  {
    path: '/delivery/banks',
    name: 'DellymanSettlement',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/BankAccount.vue'),
    beforeEnter: [CheckToken],
  },
  {
    path: '/buy-for-me/restaurants',
    name: 'Buy4meRestaurant',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/BuyForMeRestaurants.vue'),
    beforeEnter: [CheckToken],
  },
  {
    path: '/buy-for-me/orders',
    name: 'Buy4meOrders',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/BuyForMeOrders.vue'),
    beforeEnter: [CheckToken],
  },
  {
    path: '/zones',
    name: 'Zones',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Zones.vue'),
    beforeEnter: [CheckToken],
  },
  {
    path: '/unpublished/restaurants',
    name: 'UnpublishedOrder',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/UnpublishedRestaurant.vue'),
    beforeEnter: [CheckToken],
  },
  {
    path: '/logout',
    name: 'AdminLogout',
    beforeEnter: [deleteToken],
  },
  {
    path: '/not-allowed',
    name: 'NotAllowed',
    component: () => import(/* webpackChunkName: "about" */ '../views/NotAllowed.vue')
  },
  { path: '/:catchAll(.*)', redirect: '/404' },
  {
    path: '/404',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "about" */ '../views/NotFound.vue')
  }

]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
      return { top: 0 }
  },
})

router.beforeEach((to, from) => {
  if (to.name === "Resturants" || to.name === "ResturantsPage" ) {
    const Address = store.getters.getAddress
    if (!Address) {
      store.dispatch('errAddress', 'Please enter your delivery address');
      router.push({ name: 'Home' });
    } else if (!Address.latitude || !Address.longitude){
      store.dispatch('errAddress', 'Invaild delivery address');
      router.push({ name: 'Home' });
    }
  }
})

export default router
