<template>
    <div class="absolute bg-white w-full z-30 shadow-lg rounded p-3 text-sm mt-2" >
        <div @click.self="closeDropdown" class="fixed top-0 left-0 h-full w-full -z-10" ></div>
        <ul>
            <li v-for="item in items" :key="item" class="py-0.5">
                <router-link :to="item.url" class="hover:text-brand transition ease-in-out duration-300 text-sm my-2">  {{ item.name }}</router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name:['Dropdown'],
    props:['items'],
    methods:{
        closeDropdown(){
            this.$emit('closeDropdwn')
        }
    }
}
</script>

<style>

</style>