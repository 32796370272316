<template>
  <div class="font-mont text-sm md:text-base">
    <router-view/>
  </div>
</template>
<script>
import moment from 'moment';
export default{
  name:"App",
  computed:{

  },
  mounted(){
    if(this.$store.getters.getUser){
      let user = this.$store.getters.getUser
      if(moment().format() > moment(user.refreshToken.expiryDate).format()){
        this.$store.dispatch('removeUserToken')
        return this.$router.push({ name: 'Login' });
      }
    }

  }
}

</script>

<style>
#nav a.router-link-exact-active {
  color: #42b983;
}
.bg-overlay{
    background: rgba(0, 0, 0, 0.267);
}
button{
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}
button,
a{
 -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
 -webkit-tap-highlight-color: transparent;
 -webkit-user-select: none;
 -khtml-user-select: none;
 -moz-user-select: none;
 -ms-user-select: none;
  user-select: none;
}
</style>
