<template>
  <div :class="{'block': modalLarge == true, 'flex': modalLarge == false}" class="fixed justify-center items-center bg-overlay z-999 p-4 lg:p-8 h-screen top-0 left-0 overflow-scroll  w-full">
    <div  :class="[{'w-full mx-auto': modalLarge == true,'lg:m-10': modalLarge == false },width]" class="bg-white rounded px-4 py-4">
        <slot></slot>
    </div>
</div>
</template>

<script>
export default {
        name:"Modal",
        props:['show','width','large'],
        data(){
            return{
                modalLarge: (this.large) ? true : false
            }
        },

}
</script>

<style>

</style>