<template>
    <Admin>
        <div class="flex justify-between">
            <div class="flex" > 
                <div class="h-2 w-2 bg-brand" ></div>  
                 <h1 class="font-semibold text-3xl" >Discount Codes</h1>
            </div>
            <div class="flex space-x-3 items-center" >
                <button @click="toggleModal" class="p-3 bg-brand rounded flex space-x-2" >
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    <p class="text-sm font-semibold" >Add Discount Code</p>
                </button>
            </div>
        </div>
        <div class="mt-8" >
            <Table :rows="rows" :columns="columns"/>
        </div>
    </Admin>
    <Modal v-if="showModal" width="w-500"  :show="true">
        <div class="flex justify-between">
            <h1 class="text-xl font-semibold">Add Discount Code</h1> 
            <div @click="toggleModal">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div>  
        <div v-if="error" class="text-sm text-red-500 mt-3">{{ error }}</div>
        <form  @submit.prevent="CreateDiscountCode" class="mt-3">
            <div class="grid grid-cols-2 gap-2 mb-3">
                <div class="" >
                    <label for="discountType" class="text-sm">Discount code</label>
                    <input type="text" v-model="discountCode" class="input" placeholder="Discount Code">
                </div>
                 <div>
                <label for="discounttype" class="text-sm" >Select Discount type</label>
                <select v-model="discountType" class="input" >
                    <option value="">Select Discount type</option>
                    <option value="flat">Flat Rate Discount</option>
                    <option value="percent">Percentage Discount</option>
                </select>
            </div>
            </div>
            <div class="mt-3" v-if="discountType == 'flat'" >
                <label for="amountDiscounted" class="text-sm" >Flat Amount Discounted</label>
                <input type="number" v-model="amountDiscounted" class="input" placeholder="Flat Amount Discounted">          
            </div>
            <div class="mt-3" v-else-if="discountType == 'percent'">
                <label for="percentageDiscounted" class="text-sm">Percentage Discounted</label>
                <input type="number" min="1" max="100" v-model="percentageDiscounted" class="input" placeholder="Percentage Discounted">
            </div>
            <div :class="{'grid-cols-2': discountType != 'flat','grid-cols-1': discountType == 'flat', }" class="grid  gap-2 my-3">
                <div v-if="discountType != 'flat'" >
                    <label for="amountDiscounted" class="text-sm" >Max Amount </label>
                    <input type="number" v-model="maxAmount" class="input" placeholder="Max Amount">          
                </div>
                <div class="" >
                    <label for="percentageDiscounted" class="text-sm">Number Of Usage</label>
                    <input type="number" v-model="noOfCodes" class="input" placeholder="Number Of Codes">
                </div>
            </div>
            <div class="grid grid-cols-2 gap-2">
                <div class="mb-3" >
                    <label for="Minimun Subtotal cost" class="text-sm">Minimun Subtotal cost</label>
                    <input type="number" v-model="subTotalCost" class="input" placeholder="Minimun Subtotal cost">
                </div>
                <div class="" >
                    <label for="validityPeriod" class="text-sm">Validity Period</label>
                    <input type="number" v-model="validityPeriod" class="input" placeholder="Validity Period">
                </div>
            </div>
             <div class="grid grid-cols-2">
                <div class="" >
                    <label for="usageLimitPerUser" class="text-sm">Usage limit per user</label>
                    <input type="number" v-model="usageLimitPerUser" class="input" placeholder="Usage limit per user">
                </div>
            </div>
            
            <div class="mt-4 flex-grow flex space-x-2 justify-end">
              <button @click="toggleModal" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                Close
              </button>
            <button type="submit" :disabled="showPreloader" ref="submit" class="bg-brand px-3 text-sm py-2 font-semibold rounded">
                <Preloader v-if="showPreloader"/>
                <span v-else >Add</span>      
            </button>
            </div>  
        </form>
    </Modal>
</template>
<script>
import Admin from "@/components/admin/AdminTemplate.vue";
import Modal from "@/components/Modal.vue";
import Preloader from "@/components/Preloader.vue";
import Table from "@/components/admin/Tables/DiscountTable.vue";

export default{
    name:"DiscountCode",
    components:{
        Admin,Modal,Preloader,Table
    },
    data(){
        return {
            showModal:false,
            codeType:"",
            usageLimitPerUser:"",
            discountCode:"",
            amountDiscounted:"",
            subTotalCost:"",
            discountType:"",
            percentageDiscounted:"",
            showPreloader:false,
            maxAmount:"",
            noOfCodes:"",
            validityPeriod:"",
            rows:['Discount Code','Discount Type','Minimum Subtotal','Value','Expiry Date','Status', 'Action'],
            columns:[],
            error:""
            
        }
    },
    methods:{
        toggleModal(){
            this.showModal = !this.showModal
        },
        async CreateDiscountCode(){
            if(!this.discountCode){
                this.error = 'Discount code is required';
            }else if(!this.discountType){
                this.error = 'Discount type is required';
            }else if(this.discountType == 'flat' && !this.amountDiscounted){
                this.error = 'Amount discounted field is required';
            }else if(this.discountType == 'percent' && !this.percentageDiscounted){
                this.error = 'Percentage discounted field is required';
            }else if(this.percentageDiscounted > 100){
                this.error = 'Percentage discounted field cannot be more than 100';
            }else if(!this. maxAmount && this.discountType != 'flat'){
                this.error = 'Max amount is required';
            }else if(!this. noOfCodes){
                this.error = 'Number of code field is required'
            }else if(!this.validityPeriod){
                this.error = 'Vaildity period field is required'
            }else if(!this.subTotalCost){
                this.error = 'Minimum subtotal field is required'
            }else{
                this.showPreloader = true
                let data = {  
                    "discountType": this.discountType.toUpperCase(),
                    "discountCode": this.discountCode,
                    "discountId": 0,
                    // "expired": false,
                    // "active": true,
                    "minimumOrderSubTotalCost": this.subTotalCost,
                    "noOfValidDaysLeft": 0,
                    "discountValue": (this.discountType == 'flat') ? parseInt(this.amountDiscounted) : parseInt(this.percentageDiscounted),
                    "discountLimit": parseInt(this.maxAmount),
                    "noOfCodes": parseInt(this.noOfCodes),
                    "validityPeriod": parseInt(this.validityPeriod),
                    "usageLimitPerUser":parseInt(this.usageLimitPerUser)
                }
                let url = this.$store.state.base_url + 'discount';
                try{
                    let token = await this.$store.getters.getToken
                    let res = await this.axios.post(url,data,{
                        headers: {
                            Authorization: 'Bearer ' + token
                        }
                    })
                    this.showPreloader = false;
                    this.getDiscountCode("create");
                }catch(error){
                    this.showPreloader = false;
                }
            }
        },
        async getDiscountCode(create){
            let url = this.$store.state.base_url + 'discount';
            let token = await this.$store.getters.getToken
            let res = await this.axios.get(url,{
                headers:{
                        Authorization: 'Bearer ' + token
                }
            })
            this.columns = res.data
            if(create){
                this.toggleModal();
            }
        }
    },
    beforeMount(){
        this.getDiscountCode();
    }
}
</script>