<template>
    <Admin>
      <div class="flex justify-between">
            <div class="flex" > 
                <div class="h-2 w-2 bg-brand" ></div>  
                <h1 class="font-semibold text-3xl" >Meals Tags</h1>
            </div>
            <div class="flex space-x-3 items-center" >
                <button @click="toggleModal" class="p-3 bg-brand rounded flex space-x-2" >
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    <p class="text-sm" >Add Tag</p>
                </button>
            </div>
      </div>
      <div class="mt-8" >
          <Table :rows="rows" @removeTag="removeTag"  @updateTag="updateTag" :page="page" :TagCategory="categories" :msg="msg" @changePage="changePage" :columns="columns"/>
      </div>
      <Modal v-if="showModal" width="w-500" :show="true">
          <div class="flex justify-between">
            <h1 class="text-xl font-semibold">Add tags</h1>
            <div @click="toggleModal">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div> 
        <div v-if="error" class="text-sm text-red-500 mt-3">{{ error }}</div>
        <form @submit.prevent="addMealTag" method="post">
            <div class="mb-3">
                <label for="name" class="text-sm" >Tag name</label>
                <input type="text" v-model="formData.name" class="input" placeholder="Tag name">
            </div>
            <div class="mb-3 relative">
                <label for="category" class="text-sm block mb-3">Tag Category</label>
                <select2-multiple-control :settings="setting"  @change="selectedCategory" :options="getSelectCategories"  />
            </div>
            <div class="mt-4">
                <div class="flex">
                    <label for="description" class="text-sm" >Description</label>
                    <span class="text-sm flex-grow flex justify-end" ></span>
                </div>
                <textarea type="text" maxlength="200" v-model="formData.description" cols="10" rows="2" class="input" placeholder="Enter Description"></textarea>
            </div>
            <div class="mt-4 flex-grow flex space-x-2 justify-end">
                <button @click="toggleModal" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                    Close
                </button>
                <button type="submit" :disabled="showPreloader" class="bg-brand px-3 text-sm py-2 font-semibold rounded">
                    <Preloader v-if="showPreloader"/>
                    <span v-else >Add tags</span>      
                </button>
            </div>
        </form>
      </Modal>
    </Admin>
  </template>
  
  <script>
  import Admin from "@/components/admin/AdminTemplate.vue"
  import Preloader from "@/components/Preloader.vue";
  import Modal from "@/components/Modal.vue";
  import Autocomplete from "@/components/Autocomplete.vue";
  import Table from "@/components/admin/Tables/MealsTags.vue";
  import Select2MultipleControl from 'v-select2-multiple-component'
  export default {
      name:"MealsCategory",
      components:{
          Admin,Preloader,Modal,Table,Autocomplete,Select2MultipleControl
      },
      data(){
          return {
            showModal:false,
            showPreloader:false,
            formData:{
                name:"",
                description: "",
                productTagCategories: []
            },
            categories:[],
            timebelts:[],
            error:"",
            rows:[
                'Name',
                'Description',
                'Category',
                'Action'
            ],
            columns:{
                content:[]
            },
            arrayunitOfMeasure:[],
            unitOfMeasure:"",
            msg:"Loading ...",
            page:0,
            size:10,
            setting:{
                placeholder: "Select meal categories",
                width:'100%'
            },
          }
      },
      computed:{
        getSelectCategories(){
            let categories  = this.categories.map(item =>{

                let computedItem = {
                    id: item.id, 
                    text: item.name
                }

                return computedItem
            })

            return categories.sort((a,b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0));
        }
      },
      methods:{
        toggleModal(){
            this.showModal = !this.showModal
            this.formData.productTagCategories = [];
            this.formData.name = this.formData.description = ""
        },
        async searchCategory(){
            let url = this.$store.state.base_url + 'product-tag-category';
            try{
                let token = await this.$store.getters.getToken
                let res = await this.axios.get(url,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    },
                    params:{
                        page: 0,
                        size: 500
                    }
                })
                this.categories = res.data.content 
            }
            catch(err){
                console.log(err);
            }
        },
        selectedCategory(data){
            this.formData.productTagCategories = data.map(item => parseInt(item));
        },
        async addMealTag(){
            if(!this.formData.productTagCategories.length){
                return this.error = "Select a category"
            }

            if(!this.formData.name){
                return this.error = "Meal category tag name is required";
            }

            if(!this.formData.description){
                return this.error = "Description field is required";
            }

            this.error = null
            this.showPreloader = true;
            try {
                let url = this.$store.state.base_url + 'product-tag';
                let token = await this.$store.getters.getToken
                let res = await this.axios.post(url,this.formData,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                await this.getProductTag()
                this.showPreloader = false;
                this.toggleModal()
                
            } catch (error) {
                this.showPreloader = false;
                if(error.response.status == 400){
                    this.showPreloader = false  
                    return this.error = error.response.data.message
                }
            }
        },
        async getProductTag(){
            try {
                let url = this.$store.state.base_url + 'product-tag';
                let token = await this.$store.getters.getToken
                let res = await this.axios.get(url,{
                    headers: {   
                        Authorization: 'Bearer ' + token
                    },
                    params:{
                        page:this.page,
                        size:this.size
                    }
                })
                this.columns = res.data
                this.msg = "No data found"
            } catch (error) {
                console.log(error)   
            }
        },
        async changePage(data){
            this.columns.content = [] 
            this.msg = "Loading ..."
            this.page = data.page
            this.size = data.size
            await this.getProductTag()           
        },
        updateTag(data){
            this.columns.content.map(item => {
                if(item.id == data.id){
                    item.name = data.name   
                    item.productTagCategoryDtos = data.productTagCategoryDtos
                    item.description = data.description
                }
            });

        },
        removeTag(data){
            this.getProductTag()
            this.columns.content = this.columns.content.filter((item) => item.id != data);
        }
      },
      mounted(){
        this.getProductTag()
        this.searchCategory()
      }
  
  }
  </script>
  
  <style>
  
  </style>