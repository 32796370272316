<template>
<div class="flex justify-between mb-4" >
        <div class="flex space-x-2">
            <p class="text-sm flex items-center">Rows per page</p>
            <select v-model="rowPerpage"  class="border p-0.5 focus:outline-none " name="" id="">
                <option value="10">10</option>
                <option value="25" >25</option>
                <option value="50" >50</option>
            </select>
        </div>
        <div class="flex space-x-3 border border-gray-100 p-0.5 px-3">
            <input type="text"  @input="sortList" v-model="search"  placeholder="Search" class="focus:outline-none" >
            <button>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
            </button>
        </div>
    </div>
    <table class="w-full" >
        <thead class="bg-gray-100 border-gray-100 border">
            <tr class="">
                <th v-for="row in rows" :key="row" class="text-sm py-3 px-3 text-left" >{{ row }}</th>
            </tr>
        </thead>
          <tbody v-if="serviceCharges.length < 1" >
            <tr class="border border-t-0" >
                <td  class="text-sm p-3 text-center" :colspan="rows.length">{{ msg }}</td>  
            </tr>
        </tbody>
        <tbody>
            <tr v-for="col,index in serviceCharges.slice(startPage, Endpage)" :key="col" class="border border-t-0">
                <td class="text-sm p-3">{{ col.name }}</td>
                <td class="text-sm p-3 capitalize">{{ col.rateType.toLowerCase() }}</td>
                <td class="text-sm p-3">&#8358;{{ formatNumber(col.minimumAmount) }}</td>
                <td class="text-sm p-3">&#8358;{{ formatNumber(col.maximumAmount) }}</td>
                <td class="text-sm p-3">{{ (col.rateType == 'PERCENT') ? col.value + '%' : '&#8358;'+ formatNumber(col.value)  }}</td>
                <td>
                    <button @click="editDiscountScheme(col.id)" >
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                        </svg>
                    </button>
                    <button v-if="col.name !== 'DEFAULT CHARGE'" @click="deleteCode(index)"  class="text-red-500" >
                       <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="flex justify-between bg-gray-100 h-12 px-4  items-center " >
       <div> {{ currentPage }} of {{ numberofPages }} pages (Showing {{ getFirstItem }} - {{ getLastItem }} of {{ serviceCharges.length }} Entries)</div>
        <div class="flex flex-grow  justify-end" >
            <div class="flex space-x-1">
                <button @click="prev(currentPage)" class="pagination-btn active text-sm">Prev</button>
                <div  class="border border-gray-300  px-3 py-0.5" > {{ currentPage }} </div>
                <button @click="next(currentPage)" class="pagination-btn active text-sm">Next</button>
            </div>
        </div>
    </div>
    <Modal width="w-500" v-if="showeditModal" :show="true">
        <div class="flex justify-between">
            <h1 class="text-xl font-semibold">Edit Service Charge</h1>
            <div @click="toggleeditModal">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div>
        <div v-if="error" class="text-sm text-red-500 mt-3">{{ error }}</div>
        <form  @submit.prevent="updateCharge" class="mt-3">
            <div v-if="updateServiceCharge.name !== 'DEFAULT CHARGE'" class="mb-3" >
                <label for="name" class="text-sm">Name</label>
                <input type="text" v-model="updateServiceCharge.name" id="name" class="input" placeholder="Name">
            </div>
            <div class="grid grid-cols-2 gap-2 mb-3">
                <div>
                    <label for="rateType" class="text-sm" >Select Rate type</label>
                    <select v-model="updateServiceCharge.rateType" id="rateType" class="input" >
                        <option value="">Select rate type</option>
                        <option value="FLAT">Flat Rate</option>
                        <option value="PERCENT">Percentage</option>
                    </select>
                </div>
                <div v-if="updateServiceCharge.rateType == 'PERCENT'" >
                    <label for="value" class="text-sm">Percentage Value</label>
                    <input type="number" max="100" step="0.01" v-model="updateServiceCharge.value" id="value" class="input" placeholder="Percentage">
                </div>
                <div v-else >
                    <label for="value" class="text-sm">Value</label>
                    <input type="number" step="0.01" v-model="updateServiceCharge.value" id="value" class="input" placeholder="Value">
                </div>
            </div>
            <div class="grid grid-cols-2 gap-2 my-3">
                <div>
                    <label for="minimumAmount" class="text-sm" >Minimum Amount</label>
                    <input type="number" v-model="updateServiceCharge.minimumAmount" id="minimumAmount" class="input" placeholder="Minimum Amount">          
                </div>
                <div>
                    <label for="maximumAmount" class="text-sm" >Maximum Amount </label>
                    <input type="number" v-model="updateServiceCharge.maximumAmount" id="maximumAmount" class="input" placeholder="Maximum Amount">          
                </div>
            </div>
            <div>
                <label for="description" class="text-sm" >Description</label>
                <textarea v-model="updateServiceCharge.description" class="input" id="description" cols="30" rows="4"></textarea>
            </div>
            <div class="mt-4 flex-grow flex space-x-2 justify-end">
              <button @click="toggleeditModal" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                Close
              </button>
            <button type="submit" :disable="showPreloader" ref="submit" class="bg-brand px-3 text-sm py-2 font-semibold rounded">
                <Preloader v-if="showPreloader"/>
                <span v-else >Edit</span>      
            </button>
            </div>  
        </form>
    </Modal>
    <Modal width="w-500" v-if="showAssign" :show="true">
        <div class="flex justify-between">
            <h1 class="text-xl font-semibold">Assign Service Charge</h1>
            <div @click="toogleAssign">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div>
         <div v-if="error" class="text-sm text-red-500 mt-3">{{ error }}</div>
        <form @submit.prevent="assignChargeToRestaurant" class="mt-3">
            <div v-if="false" class="mb-3">
                <label for="serviceCharge" class="text-sm" >Select Service Charge</label>
                <select v-model="assignData.schemeId" id="serviceCharge" class="input" >
                    <option value="">Select Service Charge</option>
                    <option v-for="charge in serviceCharges" :key="charge" :value="charge.id">{{ charge.name }}</option>
                </select>
            </div>
            <div class="mb-3">
                <label for="restaurant" class="text-sm" >Select Restaurant</label>
                <select v-model="assignData.restaurantId" id="restaurant" class="input" >
                    <option value="">Select Restaurant</option>
                    <option v-for="restaurant in restaurants" :key="restaurant" :value="restaurant.restaurantId">{{ restaurant.name }}</option>
                </select>
            </div>
            <div class="mt-4 flex-grow flex space-x-2 justify-end">
                <button @click="toggleeditModal" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                    Close
                </button>
                <button type="submit" :disable="showPreloader" ref="submit" class="bg-brand px-3 text-sm py-2 font-semibold rounded">
                    <Preloader v-if="showPreloader"/>
                    <span v-else >Assign</span>      
                </button>
            </div>  
        </form>
    </Modal>
    
    <Modal v-if="deleteModal" :show="true">
       <h1 class="text-2xl font-semibold">Are you sure</h1> 
       <p class="py-2" >Do you really want to delete this record? This action cannot be undone</p>
        <div class="mt-4 flex-grow flex space-x-2 justify-end">
            <button @click="toogledelete" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                No
            </button>
            <button @click="deleteDiscountCode"  type="submit" ref="submit" class="bg-brand px-3 text-sm py-2 font-semibold rounded">
                <Preloader v-if="showPreloader"/>
                <span v-else>Yes</span>      
            </button>
        </div>
    </Modal>
</template>
<script>
import axios from "axios";
import Preloader from "@/components/Preloader.vue";
import Modal from "@/components/Modal.vue";

export default{
    name:'DiscountTable',
    props:['rows','columns','msg'],
    components:{
        Modal,Preloader
    },
    data(){
        return{
            search :"",
            rowPerpage:10,
            serviceCharges:[],
            startPage:0,
            Endpage: 10,
            currentPage:1,
            numberofPages:0,
            showPreloader:false,
            showeditModal:false,
            deleteModal:false,
            error:"",
            updateServiceCharge:{},
            discountType:"",
            restaurants:[],
            showAssign:false,
            assignServiceCharge:{},
            assignData:{
                schemeId:"",
                restaurantId:""
            }
        }
    },
    watch: {
        columns: function () {
            this.serviceCharges = this.columns
            this.numberofPages = Math.ceil(this.columns.length/this.rowPerpage)
        },
        rowPerpage : function () {
            this.numberofPages = Math.ceil(this.columns.length/this.rowPerpage)
            this.Endpage = this.rowPerpage;
            this.startPage  = 0;
            this.currentPage = 1
        }
    },
    computed:{
        getFirstItem(){
            return this.serviceCharges.findIndex((item)=> item.discountCode == this.serviceCharges.slice(this.startPage, this.Endpage)[0].discountCode) + 1
        },
        getLastItem(){
            return this.serviceCharges.findIndex((item)=> item.discountCode == this.serviceCharges.slice(this.startPage, this.Endpage)[this.serviceCharges.slice(this.startPage, this.Endpage).length - 1].discountCode) + 1
        }
    },
    methods:{
        toggleeditModal(){
            this.showeditModal = !this.showeditModal
        },
        toogledelete(){
            this.deleteModal = !this.deleteModal
        },
        prev(currentPage){
            let prev = currentPage - 1;
            if (prev) {
              this.currentPage = prev;
              this.startPage  = ((prev - 1) * this.rowPerpage);
              this.Endpage = prev * this.rowPerpage            
            }
        }, 
        next(currentPage){
            let next = currentPage + 1;
            if (next <= this.numberofPages) {
                this.currentPage = next;
                this.startPage  = ((next - 1) * this.rowPerpage);
                this.Endpage = next * this.rowPerpage;
            }
        },
        formatNumber(num) {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        },
        editDiscountScheme(id){
            this.updateServiceCharge = this.serviceCharges.find((item) => item.id == id);
            this.toggleeditModal();
        },
        async updateCharge(){
            if(!this.updateServiceCharge.name){
                this.error = 'Name field is required';
            }else if(!this.updateServiceCharge.value){
                this.error = 'Amount field  is required';
            }else if(!this.updateServiceCharge.rateType){
                this.error = 'Rate type field id required';
            }else if(!this.updateServiceCharge.description){
                this.error = 'Description field is required';
            }else if(!this.updateServiceCharge.minimumAmount){
                this.error = 'Minimun Amount is required';
            }else if(!this.updateServiceCharge.maximumAmount){
                this.error = 'Maximum amount is required';
            }else{
                this.showPreloader = true
                let url = this.$store.state.base_url + `admin/service-charge-scheme/${this.updateServiceCharge.id}`;
                try {
                    let token = await this.$store.getters.getToken
                    let res = await this.axios.put(url,this.updateServiceCharge,{
                        headers:{
                            Authorization: 'Bearer ' + token
                        }
                    })
                    this.showPreloader = false;
                    this.toggleeditModal();
                    
                } catch (error) {
                    this.showPreloader = false;
                }
            }
        },
        deleteCode(id){
            this.deleteid = id
            this.toogledelete()
        },
        async deleteDiscountCode(){
            let url = this.$store.state.base_url + 'discount/delete/'+ this.deleteid ;
            let token = await this.$store.getters.getToken
            axios.delete(url,{
                 headers:{
                    Authorization: 'Bearer ' + token
                }
            })
            this.serviceCharges = this.serviceCharges.filter((item) => item.discountId !== this.deleteid );
            this.toogledelete();
        },
        assignCharge(id){
            this.assignServiceCharge = this.serviceCharges.find(item => item.id == id) 
            this.assignData.schemeId = this.assignServiceCharge.id
            this.toogleAssign()
        },
        toogleAssign(){
            this.showAssign = !this.showAssign
        },
        async assignChargeToRestaurant(){
            if(!this.assignData.restaurantId){
                this.error = "Select a restaurant";
            }else{
                 this.showPreloader = true;
                try{
                    let url = this.$store.state.base_url + 'admin/service-charge-scheme/assign';
                    let token = await this.$store.getters.getToken
                    await this.axios.post(url,null,{
                        headers: {
                            Authorization: 'Bearer ' + token
                        },
                        params:this.assignData
                    })
                     this.showPreloader = false;
                    this.toogleAssign()
                }catch(error){
                    this.showPreloader = false;
                }
            }

        }
        
    },
    async mounted(){
        this.restaurants = await this.global.getAllRestaurants()
    }
}

</script>
