<template>
    <div class="lg:flex lg:justify-between mb-4 mt-8" >
        <div class="flex justify-center space-x-2">
            <p class="text-sm flex items-center">Rows per page</p>
            <select v-model="rowPerpage"  class="border p-2 focus:outline-none " name="" id="">
                <option value="10">10</option>
                <option value="25" >25</option>
                <option value="50" >50</option>
            </select>
        </div>
        <div class="flex justify-between space-x-3 border border-gray-100 mt-4 lg:mt-0 p-2 px-3">
            <input type="text"  @input="sortList" v-model="search"  placeholder="Search" class="focus:outline-none" >
            <button>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
            </button>
        </div>
    </div>
    <div class="overflow-x-auto" >
        <table class="w-full mt-8">
            <thead class="bg-gray-100 border-gray-100 border">
                <tr class="">
                    <th v-for="row,index in rows" :key="row" :class="{'text-center':index == rows.length, 'text-left':index != rows.length}" class="text-sm py-3 px-3" >{{ row }}</th>
                </tr>
            </thead>
            <tbody v-if="timebelts.length < 1">
                <tr class="border border-t-0" >
                    <td class="text-sm p-3 text-center" :colspan="rows.length">{{ msg }}</td>  
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-for="col in timebelts.slice(startPage, Endpage)" :key="col" class="border border-t-0">
                    <td class="text-sm p-3" >{{ col.name }} </td>
                    <td class="text-sm p-3">{{ col.startTime }} </td>
                    <td class="text-sm p-3">{{col.endTime }} </td>
                    <td class="text-sm flex space-x-3 w-24 p-3">
                        <button @click="assignTimebelts(col.id)" class="text-sm px-4 py-2 bg-brand rounded-md" >
                            Assign
                        </button>
                        <button @click="updateTimebelt(col.id)" class="text-sm px-4 py-2 bg-brand rounded-md" >
                            Update
                        </button> 
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
     <div class="lg:flex lg:justify-between lg:space-y-0 text-sm space-y-3 bg-gray-100 h-12 p-4 mb-8 items-center " >
       <div> {{ currentPage }} of {{ numberofPages }} pages (Showing {{ getFirstItem }} - {{ getLastItem }} of {{ timebelts.length }} Entries)</div>
        <div class="flex flex-grow justify-center items-center lg:justify-end" >
            <div class="flex space-x-1">
                <button @click="prev(currentPage)" class="pagination-btn active text-sm">Prev</button>
                <div  class="border border-gray-300  px-3 " > {{ currentPage }} </div>
                <button @click="next(currentPage)" class="pagination-btn active text-sm">Next</button>
            </div>
        </div>
    </div>
    <Modal v-if="showAssign"  width="w-500" :show="true">
        <div class="flex justify-between">
            <h1 class="text-xl font-semibold">Assign Timebelt</h1>
            <div @click="toogleAssign">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div>
        <form @submit.prevent="postAssignTimebelts" >
            <div v-if="error" class="text-sm text-red-500">{{ error }}</div>
            <div class="mb-4">
                <label for="vendor" class="text-sm" >Select Vendor</label>
                <select v-model="vendorId" class="input" >
                    <option value="">Select Vendor</option>
                    <option v-for="vendor in vendors" :key="vendor" :value="vendor.userId">{{ vendor.firstName }} {{ vendor.lastName }} ({{ vendor.email }})</option>
                </select>
            </div>
             <div class="mt-4 flex-grow flex space-x-2 justify-end">
                <button @click="toogleAssign" type="reset" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                    Close
                </button>
                <button type="submit" :disabled="showassignPreloader" class="bg-brand px-3 text-sm py-2 font-semibold rounded">
                    <Preloader v-if="showassignPreloader"/>
                    <span v-else>Assign Timebelt</span>      
                </button>
            </div>
        </form> 
    </Modal>
    <Modal v-if="showeditModal" width="w-500" :show="true">
        <div class="flex justify-between">
            <h1 class="text-xl font-semibold">Edit Timebelt</h1>
            <div @click="toogleShowEdit">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div> 
        <div v-if="error" class="text-sm text-red-500">{{ error }}</div>
        <form @submit.prevent="updateTB" id="form" class="mt-3" method="post">
            <div>
                <select class="input" v-model="editTimebeltData.name">
                    <option value="">Select Timebelt Name</option>
                    <option v-for="name in timebeltNames" :key="name" :value="name">{{ name }}</option>
                </select>
            </div>
            <div class="grid grid-cols-2 gap-2 mt-3">
                <div>
                    <label for="status" class="text-sm" >Start time</label>
                    <input type="text" v-model="editTimebeltData.startTime" class="input" placeholder="Start time">
                </div>
                <div>
                    <label for="status" class="text-sm" >End time</label>
                    <input type="text" v-model="editTimebeltData.endTime" class="input" placeholder="End time">
                </div>
            </div>
             <div class="mt-4 flex-grow flex space-x-2 justify-end">
                <button @click="toogleShowEdit" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                    Close
                </button>
                <button type="submit" :disabled="showPreloader" class="bg-brand px-3 text-sm py-2 font-semibold rounded">
                    <Preloader v-if="showPreloader"/>
                    <span v-else>Update Timebelt</span>      
                </button>
            </div>
        </form>
    </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import Preloader from '@/components/Preloader.vue';
import axios from 'axios'
export default {
    name:"TimebeltsTable",
    props:['rows','columns','msg','timebeltNames'],
    components:{
        Modal,Preloader
    },
    data(){
        return{
            rowPerpage:10,
            search:"",
            startPage:0,
            Endpage:10,
            showDeleteModal:false,
            showDeletePreloader:false,
            currentPage:1,
            numberofPages:0,
            showAssign :false,
            vendors:[],
            vendorId:"",
            timebeltID:"",
            showassignPreloader:false,
            error:"",
            showeditModal:false,
            editTimebeltData:{
                name:"",
                startTime:"",
                endTime:"",
                id:""
            },
            showPreloader:false,
            allTimeBelts:[],
        }
    },
    computed:{
        timebelts(){
            this.numberofPages = Math.ceil(this.columns.length/this.rowPerpage)
            this.allTimeBelts = this.columns
            return this.allTimeBelts;
        },
        getFirstItem(){
            return this.timebelts.findIndex((item)=> item.id == this.timebelts.slice(this.startPage, this.Endpage)[0].id ) + 1
        },
        getLastItem(){
            return this.timebelts.findIndex((item)=> item.id == this.timebelts.slice(this.startPage, this.Endpage)[this.timebelts.slice(this.startPage, this.Endpage).length - 1].id ) + 1
        }
    },
    methods:{
        toogleAssign(){
            this.showAssign = !this.showAssign
        },
        assignTimebelts(id){
            this.toogleAssign();
            this.timebeltID = id
        },
        async postAssignTimebelts(){
            if(!this.vendorId){
                this.error = "Select a vendor"
            }else{
                try {
                    this.showassignPreloader = true
                    let url = this.$store.state.base_url + `time-belt/${this.timebeltID}/assign/vendor/${this.vendorId}`
                    let token = await this.$store.getters.getToken;
                    await axios.post(url,null,{
                        headers: {
                            Authorization: 'Bearer ' + token
                        }
                    })
                    this.showassignPreloader = false;
                    this.toogleAssign();
                } catch (error) {
                    
                }
            }
        },
        async getVendors(){
            let url = this.$store.state.base_url + 'admin/vendor';
            try {
                let token = await this.$store.getters.getToken
                let res = await axios.get(url,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    },
                    params:{
                        page:0,
                        size:100
                    }
                })
                this.vendors = res.data.content
            } catch (err) {
                console.error(err);
            }
        },
        updateTimebelt(id){
            let data = this.timebelts.find(item => item.id == id);
            this.editTimebeltData.name = data.name;
            this.editTimebeltData.startTime = data.startTime;
            this.editTimebeltData.endTime = data.endTime;
            this.editTimebeltData.id = data.id;
            this.toogleShowEdit();
        },
        toogleShowEdit(){
            this.showeditModal = !this.showeditModal;
        },
       async updateTB(){
            this.error = (!this.editTimebeltData.name || !this.editTimebeltData.startTime || !this.editTimebeltData.endTime ) ? "Invaild details in the form" : null;
            if (!this.error) {
                this.showPreloader = true;
                let data = {
                    "deliveryWindow": this.columns[0].deliveryWindow,
                    "endTime": this.editTimebeltData.endTime,
                    "id": this.editTimebeltData.id,
                    "name": this.editTimebeltData.name ,
                    "startTime": this.editTimebeltData.startTime
                }
                let url = this.$store.state.base_url + `time-belt/edit/${this.editTimebeltData.id}`;
                let token = this.$store.getters.getToken
                let res = await axios.put(url,data,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                this.allTimeBelts.map(item => { 
                    if(item.id == data.id){
                        item.name = data.name
                        item.startTime = data.startTime
                        item.endTime = data.endTime
                    }
                });
                this.showPreloader = false;
                this.toogleShowEdit();
    
            }
        },
    },
    watch:{
        rowPerpage : function () {
            this.numberofPages = Math.ceil(this.columns.length/this.rowPerpage)
            this.Endpage = this.rowPerpage;
            this.startPage  = 0;
            this.currentPage = 1
        }
    },
    mounted(){
        this.getVendors();
    }

}
</script>

<style>

</style>