<template>
  <Admin>
    <div class="flex justify-between">
      <div class="flex">
        <div class="w-2 h-2 bg-brand"></div>
        <h1 class="text-3xl font-semibold">Orders</h1>
      </div>
      <div class="flex space-x-3">
        <div class="flex items-center space-x-3">
          <button
            @click="toggleModal"
            class="flex p-3 space-x-2 rounded bg-brand"
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                />
              </svg>
            </div>
            <!-- <p v-if="filter.length == 0" class="text-sm">Filter Orders</p>
            <p v-else class="text-sm">Clear filter</p> -->
          </button>
        </div>
        <div class="flex items-center space-x-3">
          <button
            @click="downloadExcel"
            class="flex p-3 space-x-2 rounded bg-brand"
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                />
              </svg>
            </div>
            <p class="text-sm">Download</p>
          </button>
        </div>
      </div>
    </div>
    <div class="mt-8">
      <Table
        :rows="rows"
        :columns="columns"
        :page="page"
        :msg="msg"
        @changePage="changeOrderPage"
        @search="searchResult"
      />
    </div>
  </Admin>
  <Modal v-if="showModal" width="w-500" :show="true">
    <div class="flex justify-between">
      <h1 class="text-xl font-semibold">Filter Order</h1>
      <div @click="toggleModal">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-6 h-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>
    <div v-if="error" class="my-2 text-sm text-red-500">{{ error }}</div>
    <div>
      <label for="" class="text-sm">Select Restaurant</label>
      <select v-model="resturantId" class="input">
        <option value="">Select Resturant</option>
        <option
          v-for="resturant in resturants"
          :key="resturant"
          :value="resturant.name"
        >
          {{ resturant.name }}
        </option>
      </select>
    </div>
    <div>
      <label for="" class="text-sm">Select Timebelt</label>
      <select v-model="timebeltID" class="input">
        <option value="">Select Timebelts</option>
        <option value="all">All Timebelts</option>
        <option
          v-for="timebelt in timebelts"
          :key="timebelt"
          :value="timebelt.name"
        >
          {{ timebelt.name }}
        </option>
      </select>
    </div>
    <div>
      <label class="block">Set Date</label>
      <datepicker
        class="input"
        v-model="todayDate"
        :style="{
          '--vdp-bg-color': '#ffffff',
          '--vdp-text-color': '#000000',
          '--vdp-box-shadow':
            '0 4px 10px 0 rgba(128, 144, 160, 0.1), 0 0 1px 0 rgba(128, 144, 160, 0.81)',
          '--vdp-border-radius': '3px',
          '--vdp-heading-size': '2.5em',
          '--vdp-heading-weight': 'bold',
          '--vdp-heading-hover-color': '#eeeeee',
          '--vdp-arrow-color': 'currentColor',
          '--vdp-elem-color': 'currentColor',
          '--vdp-disabled-color': '#d5d9e0',
          '--vdp-hover-color': '#ffffff',
          '--vdp-hover-bg-color': '#c0d800',
          '--vdp-selected-color': '#ffffff',
          '--vdp-selected-bg-color': '#c0d800',
          '--vdp-elem-font-size': '0.8em',
          '--vdp-elem-border-radius': '3px',
          '--vdp-divider-color': '#d5d9e0',
        }"
        required
      />
    </div>
    <div>
      <label for="" class="text-sm">Order Status</label>
      <select v-model="status" class="input">
        <option value="">Select Status</option>
        <option
          v-for="status in delievryStatus"
          :key="status"
          :value="status.status"
        >
          {{ status.name }}
        </option>
      </select>
    </div>
    <div class="flex justify-end flex-grow mt-4 space-x-2">
      <button
        @click="toggleModal"
        class="px-3 py-2 text-sm font-semibold bg-gray-300 rounded"
      >
        Close
      </button>
      <button
        @click="filterOrders"
        type="submit"
        ref="submit"
        class="px-3 py-2 text-sm font-semibold rounded bg-brand"
      >
        Filter
      </button>
    </div>
  </Modal>  
</template>

<script>
import Admin from "@/components/admin/AdminTemplate.vue";
import Table from "@/components/admin/Tables/OrdersTable.vue";
import Modal from "@/components/Modal.vue";
import moment from "moment";
import Datepicker from "vue3-datepicker";
import { ref } from "vue";
const picked = ref(new Date());
const xlxs = require("xlsx");
export default {
  name: ["Menus"],
  components: {
    Admin,
    Table,
    Modal,
    Datepicker,
  },
  data() {
    return {
      rows: [
        "Reference ID",
        "Customer",
        "Restaurant",
        "Price",
        "Timebelt",
        "Delivery date",
        "Status",
        "Action",
      ],
      columns: {},
      showModal: false,
      resturantId: "",
      timebeltID: "",
      resturants: "",
      timebelts: [],
      orders: [],
      filter: [],
      todayDate: "",
      status: "",
      delievryStatus: [
        { name: "Not paid", status: "ORDER_PLACED" },
        { name: "Confirmed", status: "ORDER_CONFIRMED" },
        { name: "Processing", status: "ORDER_PREPARATION_IN_PROGRESS" },
        { name: "Processed", status: "ORDER_PREPARATION_COMPLETED" },
        { name: "Assigned to driver", status: "ORDER_ASSIGNED_TO_DRIVER" },
        { name: "Rejected", status: "ORDER_REJECTED" },
        { name: "Ready for Pickup", status: "ORDER_READY_FOR_PICKUP" },
        { name: "Order Shipped", status: "ORDER_SHIPPED" },
        { name: "Order Delivered", status: "ORDER_DELIVERED" },
        { name: "Order Cancelled", status: "ORDER_CANCELLED" },
        { name: "Order Refunded", status: "ORDER_REFUNDED" },
      ],
      error: null,
      msg: "Loading ...",
      page: 0,
      size: 10,
      search: "",
    };
  },
  methods: {
    async getAllOrders() {
      try {
        let url = this.$store.state.base_url + "admin/all-orders";
        let token = await this.$store.getters.getToken;
        let res = await this.axios.get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: {
            page: this.page,
            size: this.size,
            searchTerm: this.search,
          },
        });
        console.log(res.data)
        this.columns  = res.data;
        this.orders = res.data;
        this.msg = "No data found";
      } catch (error) {
        this.msg = "No data found";
        console.log(error);
      }
    },
    toggleModal() {
      this.showModal = !this.showModal;
      if (this.showModal && this.filter.length > 0) {
        this.filter = [];
        this.columns = this.orders;
      }
    },
    filterOrders() {
      if (!this.filter.length > 0) {
        this.error =
          !this.resturantId ||
          !this.timebeltID ||
          !this.status ||
          !this.todayDate
            ? "Fill all criterial"
            : null;
        if (!this.error) {
          let date = this.todayDate.toLocaleDateString("en-US");
          let filtredOrders = this.orders.filter(
            (item) =>
              item.restaurant.name === this.resturantId &&
              (this.timebeltID == "all" ||
                item.cartItems[0].timeBelt.name === this.timebeltID) &&
              item.status == this.status &&
              moment(item.cartItems[0].deliveryDate).format("L") ===
                moment(date).format("L")
          );
          if (filtredOrders.length > 0) {
            this.columns = this.filter = filtredOrders;
            this.toggleModal();
          } else {
            this.error = "No data found";
          }
        }
      } else {
        this.filter = [];
        (((this.resturantId == this.timebeltID) == this.status) ==
          this.todayDate) ==
          "";
        this.columns = this.orders;
      }
    },
    formatNumber(num) {
      if (num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      } else {
        return num;
      }
    },
    downloadExcel() {
      let excel = [];
      this.columns.map((item) => {
        let foodId = "";
        item.cartItems.map((one, index) => {
          if (index + 1 == item.cartItems.length) {
            foodId = `${foodId} ${one.food.name} x ${one.quantity}`;
          } else {
            foodId = `${foodId} ${one.food.name}  x ${one.quantity} \n`;
          }
        });
        let obj = {
          "Reference ID": item.orderReferenceNumber,
          "Restaurant Name": item.restaurant.name,
          Timebelt: item.cart.timeBelt.name,
          "Customer's Name": `${item.user.firstName} ${item.user.lastName}`,
          "Customer's Phone Number": item.user.phoneNumber,
          "Ordered Date": moment(item.createdAt).format("MMM Do YY"),
          "Delivery date": moment(item.cart.deliveryDate).format("MMM Do YY"),
          "Delivery Address": item.cart.deliveryAddress,
          Meals: foodId,
          Total: this.formatNumber(item.totalCost),
        };
        excel.push(obj);
      });
      let newWB = xlxs.utils.book_new();
      let newWs = xlxs.utils.json_to_sheet(excel);
      newWs["!cols"] = [
        { width: 15 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 20 },
        { width: 40 },
        { width: 40 },
        { width: 10 },
      ];

      if (this.resturantId && this.timebeltID && this.status) {
        xlxs.utils.book_append_sheet(newWB, newWs, `${this.resturantId}`);
        xlxs.writeFile(newWB, `${this.resturantId}.xlsx`);
      } else {
        xlxs.utils.book_append_sheet(newWB, newWs, `Noshtribe Orders`);
        xlxs.writeFile(newWB, `Noshtribe _All _Orders.xlsx`);
      }
    },
    async getAllTimebelt() {
      let url = this.$store.state.base_url + "time-belt";
      try {
        let token = await this.$store.getters.getToken;
        let res = await this.axios.get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        this.timebelts = res.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getAllResturants() {
      let url = this.$store.state.base_url + "admin/all-restaurant/";
      let token = await this.$store.getters.getToken;
      try {
        let res = await this.axios.get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        this.resturants = res.data;
      } catch (error) {
        console.log(error);
      }
    },
    async changeOrderPage(data) {
      this.columns.content = [];
      this.msg = "Loading ...";
      this.page = data.page;
      this.size = data.size;
      await this.getAllOrders();
    },
    searchResult(data) {
      this.search = data;
      this.getAllOrders();
    },
  },
  mounted() {
    this.todayDate = picked;
  },
  beforeMount() {
    this.getAllOrders();
    this.getAllTimebelt();
    this.getAllResturants();
  },
};
</script>

<style>
</style>