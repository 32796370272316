<template>
    <div class="flex justify-between mb-4" >
        <div class="flex space-x-2">
            <p class="text-sm flex items-center">Rows per page</p>
            <select v-model="rowPerpage" class="border p-0.5 focus:outline-none " name="" id="">
                <option value="10">10</option>
                <option value="25" >25</option>
                <option value="50" >50</option>
            </select>
        </div>
        <div class="flex space-x-3 border border-gray-100 p-0.5 px-3">
            <input type="text" @input="sortList" v-model="this.search" placeholder="Search" class="focus:outline-none" >
            <button>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
            </button>
        </div>
    </div>
    <table class="w-full" >
        <thead class="bg-gray-100 border-gray-100 border">
            <tr class="">
                <th v-for="row in rows" :key="row" class="text-sm py-3 px-3 text-left" >{{ row }}</th>
            </tr>
        </thead>
        <tbody v-if="Orders.length < 1">
            <tr class="border border-t-0" >
                <td  class="text-sm p-3 text-center" :colspan="rows.length">{{ msg }}</td>  
            </tr>
        </tbody>
        <tbody v-else >
            <tr v-for="col in Orders.slice(startPage, Endpage)" :key="col" class="border border-t-0">
                <td class="text-sm p-3" >{{ col.orderReferenceNumber }}</td>
                <td v-if="col.user" class="text-sm p-3">
                    <p>{{ col.user.firstName }} {{ col.user.lastName }}</p>
                    <p>{{ col.user.phoneNumber }}</p>
                </td>
                <td v-else class="text-sm p-3">
                  Nill
                </td>
                <td class="text-sm p-3">{{ col.restaurant.name }}</td>
                <td class="text-sm p-3 w-1/12">&#8358;{{ formatNumber(col.totalCost) }} </td>          
                <td  class="text-sm text-gray-100 p-3 ">
                    <div v-if="col.cartDto.timeBelt.name ==='Instant'" >
                        <span  class="bg-purple-400 px-2 py-1 text-xs rounded-full" >{{ col.cartDto.timeBelt.name }}</span>
                    </div>
                    <div v-else-if="col.cartDto.timeBelt.name === 'Breakfast'" >
                        <span class="bg-pink-400 px-2 py-1 text-xs rounded-full" >{{ col.cartDto.timeBelt.name }}</span>
                    </div>
                    <div v-else-if="col.cartDto.timeBelt.name === 'Lunch' " >
                        <span class="bg-yellow-400 px-2 py-1 text-xs rounded-full" >{{ col.cartDto.timeBelt.name }}</span>
                    </div>
                    <div v-else-if="col.cartDto.timeBelt.name === 'Brunch' " >
                        <span class="bg-green-400 px-2 py-1 text-xs rounded-full" >{{ col.cartDto.timeBelt.name}}</span>
                    </div>
                    <div v-else-if="col.cartDto.timeBelt.name === 'Dinner' " >
                        <span class="bg-indigo-400 px-2 py-1 text-xs rounded-full" >{{ col.cartDto.timeBelt.name}}</span>
                    </div>
                    <div v-else>
                        <span class="bg-indigo-600 px-2 py-1 text-xs rounded-full" >{{ col.cartDto.timeBelt.name }}</span>
                    </div>

                </td>      
                <td class="text-sm p-3">{{ changeDate(col.expectedDeliveryDate) }}</td>  
                <td class="text-xs p-3 ">
                    <div v-if="col.status == 'ORDER_PLACED'"  >
                        <span class="mt-4 bg-red-600 text-gray-100 rounded-full px-3 py-1 top-0 right-0">Not paid</span>
                    </div>
                    <div v-else-if="col.status == 'ORDER_CONFIRMED'">
                        <span class="mt-4 bg-green-600 text-gray-100 rounded-full px-3 py-1 top-0 right-0">Confirmed</span>
                    </div>
                    <div v-else-if="col.status == 'ORDER_PAID'">
                        <span class="mt-4 bg-indigo-800 text-gray-100 rounded-full px-3 py-1 top-0 right-0">Paid</span>
                    </div>
                    <div v-else-if="col.status == 'ORDER_READY_FOR_PICKUP'">
                        <span class="mt-4 bg-yellow-600 text-gray-100 rounded-full px-3 py-1 top-0 right-0">Delivery Booked</span>
                    </div>
                    <div v-else-if="col.status == 'ORDER_SHIPPED'">
                        <span class="mt-4 bg-blue-700 text-gray-100 rounded-full px-3 py-1 top-0 right-0">Shipped</span>
                    </div>
                    <div  v-else-if="col.status == 'ORDER_DELIVERED'" >
                        <span class="mt-4 bg-green-800 text-gray-100  rounded-full px-3 py-1 top-0 right-0">Delivered</span>
                    </div>
                    <div v-else-if="col.status == 'ORDER_CANCELLED'">
                        <span class="mt-4 bg-gray-800 text-gray-100  rounded-full px-3 py-1 top-0 right-0">Cancelled</span>
                    </div>
                    <div v-else-if="col.status == 'ORDER_REFUNDED'">
                        <span class="mt-4 bg-purple-800 text-gray-100 rounded-full px-3 py-1 top-0 right-0">Refunded</span>
                    </div>
                    <div v-else-if="col.status == 'ORDER_PREPARATION_IN_PROGRESS'">
                        <span class="mt-4 bg-blue-500 text-gray-100 rounded-full px-3 py-1 top-0 right-0">Processing</span>
                    </div>
                    <div v-else-if="col.status == 'ORDER_PREPARATION_COMPLETED'">
                        <span class="mt-4 bg-purple-500 text-gray-100 rounded-full px-3 py-1 top-0 right-0">Order Processed</span>
                    </div> 
                    <div v-else-if="col.status == 'ORDER_REJECTED'">
                        <span class="mt-4 bg-black text-gray-100 rounded-full px-3 py-1 top-0 right-0">Rejected</span>
                    </div> 
                     <div v-else-if="col.status == 'ORDER_ASSIGNED_TO_DRIVER'">
                        <span class="mt-4 bg-indigo-400 text-gray-100 rounded-full px-3 py-1 top-0 right-0">Assigned to driver</span>
                    </div> 
                </td>            
                <td class="w-1/12">
                    <button @click="getSingleOrder(col.orderReferenceNumber)" class="text-purple-500" >
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                      </svg>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="flex justify-between bg-gray-100 h-12 px-4  items-center " >
        <div> {{ page + 1 }} of {{ numberofPages }} pages (Showing {{ getFirstItem }} - {{ getLastItem }} of {{ columns.totalElements }} Entries)</div>
        <div class="flex flex-grow  justify-end" >
            <div class="flex space-x-1">
                <button @click="prev()" class="pagination-btn active text-sm">Prev</button>
                <div  class="border border-gray-300  px-3 py-0.5" > {{ page + 1 }} </div>
                <button @click="next()" class="pagination-btn active text-sm">Next</button>
            </div>
        </div>
    </div>
    <Modal v-if="showModal" :large="true" width="w-2/5" :show="true">
        <div class="flex justify-between mb-4">
            <h1 class="text-xl font-semibold">View order information</h1>
            <div @click="toggleModal">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div>
        <div class="inline bg-brand rounded-tr-2xl  p-3 text-sm">
            Reference ID: <span class="font-semibold">{{ modalInfo.orderReferenceNumber}}</span> 
        </div>
          <div class="grid lg:grid-cols-3 grid-cols-2 mt-4 gap-4" >
            <div>
                <p class="text-sm" >Order date</p>
                <p class="font-semibold">{{ this.moment(modalInfo.createdAt).format('LLLL') }}</p>
            </div>
            <div>
                <p class="text-sm" >Order from</p>
                <p class="font-semibold" >{{ modalInfo.restaurant.name }}</p>
            </div>
            <div>
                <p class="text-sm">Timebelt</p>
                <p class="font-semibold" >{{ modalInfo.cartDto.timeBelt.name  }} ({{ modalInfo.cartDto.timeBelt.startTime  }} - {{ modalInfo.cartDto.timeBelt.endTime }} )</p>
            </div>
            <div>
                <p class="text-sm" >Delivery Date</p>
                <p class="font-semibold" >{{ this.moment(modalInfo.expectedDeliveryDate).format('MMMM Do YYYY')  }}</p>
            </div>
            <div>
                <p class="text-sm" >Delivery Address</p>
                <p class="font-semibold">{{ modalInfo.cartDto.deliveryAddress }}</p>
            </div>
            <div class="text-sm" >
                <p class="text-sm mb-2" >Status</p>
                <div v-if="modalInfo.status == 'ORDER_PLACED'"  >
                    <span class="mt-4 bg-red-600 text-gray-100 rounded-full text-xs px-3 py-1 top-0 right-0">Not paid</span>
                </div>
                <div v-else-if="modalInfo.status == 'ORDER_CONFIRMED'">
                    <span class="mt-4 bg-green-600 text-gray-100 rounded-full text-xs px-3 py-1 top-0 right-0">Confirmed</span>
                </div>
                <div v-else-if="modalInfo.status == 'ORDER_PAID'">
                    <span class="mt-4 bg-indigo-800 text-gray-100 rounded-full text-xs px-3 py-1 top-0 right-0">Paid</span>
                </div>
                <div v-else-if="modalInfo.status == 'ORDER_READY_FOR_PICKUP'">
                    <span class="mt-4 bg-yellow-600 text-gray-100 rounded-full text-xs px-3 py-1 top-0 right-0">Delivery booked</span>
                </div>
                <div v-else-if="modalInfo.status == 'ORDER_SHIPPED'">
                    <span class="mt-4 bg-blue-700 rounded-full px-3 py-1 top-0 text-xs right-0">Shipped</span>
                </div>
                <div  v-else-if="modalInfo.status == 'ORDER_DELIVERED'" >
                    <span class="mt-4 bg-green-800 text-gray-100 rounded-full text-xs px-3 py-1 top-0 right-0">Delivered</span>
                </div>
                <div v-else-if="modalInfo.status == 'ORDER_CANCELLED'">
                    <span class="mt-4 bg-gray-800 text-gray-100 rounded-full text-xs px-3 py-1 top-0 right-0">Cancelled</span>
                </div>
                <div v-else-if="modalInfo.status == 'ORDER_REFUNDED'">
                    <span class="mt-4 bg-purple-800 text-gray-100  rounded-full text-xs px-3 py-1 top-0 right-0">Refunded</span>
                </div> 
                <div v-else-if="modalInfo.status == 'ORDER_REJECTED'">
                    <span class="mt-4 bg-black text-gray-100 text-xs rounded-full px-3 py-1 top-0 right-0">Rejected</span>
                </div> 
                <div v-else-if="modalInfo.status == 'ORDER_PREPARATION_IN_PROGRESS'">
                    <span class="mt-4 bg-blue-400 text-gray-100 text-xsrounded-full px-3 py-1 top-0 right-0">Processing</span>
                </div> 
                <div v-else-if="modalInfo.status == 'ORDER_PREPARATION_COMPLETED'">
                    <span class="mt-4 bg-purple-500 text-gray-100 text-xs rounded-full px-3 py-1 top-0 right-0">Processed</span>
                </div> 
                <div v-else-if="modalInfo.status == 'ORDER_ASSIGNED_TO_DRIVER'">
                    <span class="mt-4 bg-indigo-400 text-gray-100 text-xsrounded-full px-3 py-1 top-0 right-0">Rider Assigned</span>
                </div> 
            </div>
            <div>
                <p class="text-sm" >Additional</p>
                <p class="font-semibold" >{{ (modalInfo.additionalInfo) ? modalInfo.additionalInfo : "No data entered"  }}</p>
            </div>
            <!-- <div v-if="modalInfo.deliveryOrderNumber">
                <p class="text-sm" >Delivery order id</p>
                <p class="font-semibold" >{{ modalInfo.deliveryOrderNumber }}</p> 
            </div> -->
        </div>
        <h1 class="font-semibold text-xl mt-4">Pack Summary</h1>
        <div class="grid lg:grid-cols-5 mt-4 gap-6" >
            <div class="lg:col-span-3 flex flex-col gap-3">
                <div v-if="modalInfo.cartDto.foodContainerCartItems">
                    <div class="flex flex-col text-sm gap-3" >
                        <div v-for="container in modalInfo.cartDto.foodContainerCartItems" :key="container" class="shadow-sm border p-3 rounded">
                            <div class="flex justify-between mb-3">
                                <p class="font-semibold text-sm">{{ container.foodContainer.name }}</p>
                            </div>
                            <div v-for="cart in container.foodCartItems.filter(item => item.quantity != 0)" :key="cart" class="flex flex-col gap-3">
                                <div class="flex gap-3">
                                    <div>
                                        <img :src="cart.food.images[0].image" class="h-14 w-14 object-cover rounded-md" alt="">
                                    </div>
                                    <div class="flex-grow flex justify-between">
                                        <div>
                                            <h5 class="my-2 text-sm"> <span>{{ cart.food.name }}</span></h5>
                                            <div class="text-sm" >
                                                Qty:{{ cart.quantity }}
                                            </div>
                                        </div>
                                        <div class="text-sm my-2 text-right" >
                                            &#8358;{{ this.global.formatNumber(cart.totalPrice) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex gap-3 mt-3">
                                <div>
                                    <img :src="container.foodContainer.images[container.foodContainer.images.length - 1].image" class="h-14 w-14 object-cover rounded-md" alt="">
                                </div>
                                <div class="flex-grow flex justify-between">
                                    <div>
                                        <h5 class="my-2 text-sm"> <span>{{ container.foodContainer.name }}</span></h5>
                                        <div class="text-sm" >
                                            Qty:{{ container.quantity }}
                                        </div>
                                    </div>
                                    <div class="text-sm my-2 text-right" >
                                        &#8358;{{ this.global.formatNumber(container.foodContainer.price) }}
                                    </div>
                                </div>
                            </div>
                            <div class="flex border-t mt-3 text-sm pt-2  justify-between">
                                <div class="text-sm text-gray-500">
                                    x{{ container.quantity }} items
                                </div>
                                <div class="text-right" >
                                    Total: &#8358;{{ this.global.formatNumber(container.totalPrice) }} 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="modalInfo.cartDto.foodCartItems" class="shadow-sm border p-3 rounded">
                    <p class="font-semibold text-sm mb-4">Combo Meals</p>
                    <div class="flex flex-col gap-3">
                        <div v-for="cart in modalInfo.cartDto.foodCartItems.filter(item => item.quantity != 0)" :key="cart">
                            <div class="flex gap-3">
                                <div>
                                    <img :src="cart.foodImage" class="h-14 w-14 object-cover rounded-md" alt="">
                                </div>
                                <div class="flex-grow flex justify-between">
                                    <div>
                                        <h5 class="my-2 text-sm"> <span>{{ cart.foodName }}</span></h5>
                                        <div class="text-sm" >
                                            Qty:{{ cart.quantity }}
                                        </div>
                                    </div>
                                    <div class="text-sm my-2 text-right" >
                                        &#8358;{{ this.global.formatNumber(cart.price * cart.quantity) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="border-t mt-3 text-sm pt-2 text-right" >
                        Total: &#8358;{{ this.global.formatNumber(getComboTotal()) }} 
                    </div>
                </div>
            </div>
            <div class="lg:col-span-2">
                <div>
                    <div class="bg-brand rounded-t-xl shadow-sm p-3">
                        <p class="font-semibold">Order Summary</p>
                    </div>
                    <div class="p-3 border flex flex-col gap-2">
                        <div class="grid grid-cols-2 text-sm" >
                            <p>Subtotal</p>
                            <p v-if="modalInfo.subTotalCost" class="text-right" >&#8358;{{ this.global.formatNumber(modalInfo.subTotalCost) }}</p>
                        </div>
                        <div class="grid grid-cols-2 text-sm" >
                            <p>Shipping Cost</p>
                            <p class="text-right">&#8358;{{ this.global.formatNumber(modalInfo.shippingCost) }}</p>
                        </div>
                        <div v-if="modalInfo.discountAmount" class="grid grid-cols-2 text-sm" >
                            <p>Discount</p>
                            <p class="text-right">&#8358;{{ this.global.formatNumber(modalInfo.discountAmount) }}</p>
                        </div>
                        <div v-if="modalInfo.serviceCharge" class="grid grid-cols-2 text-sm" >
                            <p>Service charge</p>
                            <p class="text-right">&#8358;{{ this.global.formatNumber(modalInfo.serviceCharge) }}</p>
                        </div>
                        <div class="grid grid-cols-2 text-sm" >
                            <p>Total</p>
                            <p class="text-right">&#8358;{{ this.global.formatNumber(modalInfo.totalCost) }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-red-500 text-sm">
            {{ error  }}
        </div>
        <div class="mt-4 flex-grow flex space-x-2 justify-end">
            <button @click="processBuyForMeOrder" v-if="modalInfo.status == 'ORDER_CONFIRMED'" class="bg-brand px-3 text-black text-sm py-2 font-semibold rounded">
                <Preloader v-if="showProcessOrder"/>
                <span v-else >Process order</span> 
                
            </button>
            <button @click="toggleCancelModal" v-if="modalInfo.status == 'ORDER_PLACED' || modalInfo.status == 'ORDER_CONFIRMED' || modalInfo.status == 'ORDER_PAID'" class="bg-red-500 px-3 text-gray-100 text-sm py-2 font-semibold rounded">
                Cancel order
            </button>
            <button @click="toggleModal" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                Close
            </button>
        </div>
    </Modal>
    <Modal v-if="showEdit" width="w-768" :show="true">
        <div class="flex justify-between">
            <h1 class="text-xl font-semibold">Change Order Status</h1>
            <div @click="toggleEditModal">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div>
         <div v-if="error" class="text-sm text-red-500 mt-3">{{ error }}</div>
        <form @submit.prevent="updateOrder" method="post">
            <div  v-if="status == 'ORDER_PLACED'" >
                <label for="confirm" class="text-sm">Amount Paid</label>
                <input type="number" v-model="amount" class="input" placeholder="Amount Paid">
            </div>
            <div v-else>
                <label for="status" class="text-sm" >Change status</label>
                <select v-model="status" class="input" >
                    <option value="">Select Status</option>
                    <option v-for="editstat in editStatus" :key="editstat" :value="editstat.status">{{ editstat.name }}</option>
                </select>
            </div>
            <div class="my-4" v-show="status == 'ORDER_READY_FOR_PICKUP'">
                <label for="status" class="text-sm" >Select Carrier</label>
                <select v-model="Carrier" class="input" >
                    <option value="">Select Carrier</option>
                    <option v-for="Carrier in Carriers" :key="Carrier" :value="Carrier.Name">{{ Carrier.Name }}</option>
                </select>
            </div>
              <div class="mt-4 flex-grow flex space-x-2 justify-end">
              <button @click="toggleEditModal" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                Close
              </button>
                <button v-if="status == 'ORDER_PLACED'" type = 'button' ref="submit"  @click="confirmPayment"  class="bg-brand px-3 text-sm py-2 font-semibold rounded">
                    <Preloader v-if="showPreloader"/>
                    <span v-else >Confirm payment</span>      
                </button>
                <button v-else type="submit" ref="submit" class="bg-brand px-3 text-sm py-2 font-semibold rounded">
                    <Preloader v-if="showPreloader"/>
                    <span v-else >Update Order</span>      
                </button>
            </div>  
        </form>
    </Modal>
    <Modal v-if="showCancelModal" width="w-768" :show="true">
        <div class="flex justify-between">
            <h1 class="text-xl font-semibold">Cancel order {{ modalInfo.orderReferenceNumber }}</h1>
            <div @click="toggleCancelModal">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div>
        <div v-if="error" class="text-sm text-red-500 mt-3">{{ error }} </div>
         <form @submit.prevent="postCancel">
            <textarea class="input" name="" id="" placeholder="Reason" v-model="reason" cols="30" rows="10"></textarea>
            <div class="mt-4 flex-grow flex space-x-2 justify-end">
                <button @click="toggleModal" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                    Close
                </button>
                <button type="submit" ref="submit" class="bg-red-500 text-gray-100  px-3 text-sm py-2 font-semibold rounded">
                    <Preloader v-if="showCancelPreloader"/>
                    <span v-else >Cancel</span>      
                </button>
            </div>  
        </form>
    </Modal>
</template>

<script>
import moment from "moment";
import Modal from "@/components/Modal.vue";
import Preloader from "@/components/Preloader.vue";

export default {
    name:['Table'],
    props:['rows','columns','msg','page'],   
    components:{
        Modal,Preloader
    }, 
    emits:['changePage'],
    data(){
        return{
            toggleValue: false,
            search:"",
            showModal:false,
            searchResult: [],
            Orders:[],
            reason:"",
            showCancelModal:false,
            rowPerpage:10,
            modalInfo:0,
            Endpage: 10,
            startPage : 0,
            numberofPages:0,
            currentPage:1,
            customerName:"",
            phone:"",
            email:"",
            error:"",
            showPreloader:false,
            changeStatus:false,
            showEdit:false,
            status:"",
            editStatus:[],
            amount:0,
            Carriers:[],
            Carrier:"",
            referenceNumber:"",
            showCancelPreloader:false,
            showProcessOrder:false
        }
    },
    computed:{
        getFirstItem(){
            return this.page * this.rowPerpage || 1
        },
        getLastItem(){
            return  this.page * this.rowPerpage + this.Orders.length
        },
    },
    methods:{
        toggleModal(){
            this.showModal = !this.showModal
            this.error =""
        },
        toggleCancelModal(){
             this.toggleModal();
            this.showCancelModal = !this.showCancelModal;
        },
        toggleEditModal(){
            this.showEdit = !this.showEdit;
            this.amount = 0;
            this.error = "";
        },
        async postCancel(){
            if(!this.reason){
                this.error = "Reason field is required";
            }else{
                let url = this.$store.state.base_url + `admin/order/cancel/${this.modalInfo.id}`;
                this.showCancelPreloader = true;
                try {
                    let data = {
                        'request': this.reason
                    };
                    let token = await this.$store.getters.getToken
                    let res = await this.axios.post(url,data,{
                        headers: {
                            Authorization: 'Bearer ' + token
                        }
                    })
                    let order =  this.columns.content.find((item) => item.id == this.modalInfo.id)
                    console.log(order)
                    this.columns.content.map( (item) =>{ 
                        if(item.id === this.modalInfo.id && order.status != "ORDER_PLACED"){
                            item.status = 'ORDER_REFUNDED';
                        }
                        else if(item.id === this.modalInfo.id && order.status == "ORDER_PLACED"){
                            item.status = 'ORDER_CANCELLED';
                        }
                    }); 
                    this.showCancelPreloader = false;
                    this.toggleCancelModal();
                } catch (error) {
                     this.showCancelPreloader = false;
                }
            }
        },
        editsingleModal(id){
            let singleOrder = this.columns.content.find( (item) => item.orderReferenceNumber === id);
            this.OrderId = singleOrder.id
            this.customerName = `${singleOrder.user.firstName} ${singleOrder.user.lastName}`
            this.phone = singleOrder.user.phoneNumber;
            this.status =  (singleOrder.status == 'ORDER_PLACED' ) ? singleOrder.status : '';
            this.editStatus = this.filterStatus(singleOrder.status)
            this.referenceNumber = singleOrder.dellymanDeliveryOrderNumber
            this.dellymanOrderId = singleOrder.dellymanDeliveryOrderId
            this.toggleEditModal()
        },
        updateOrder(){
            this.error = (!this.status) ? "Status field is required" : null;
            this.error = (this.status == 'ORDER_READY_FOR_PICKUP' && !this.Carrier) ? "Carrier field is required" : null;

            if (!this.error) {
                this.showPreloader = true;
                this.$refs.submit.disabled = true;
                if(this.status === 'ORDER_READY_FOR_PICKUP'){ 
                    this.bookDellymanOrder(this.OrderId,this.status)
                }else{
                  this.updateStatus(this.OrderId,this.status,this.dellymanOrderId,this.referenceNumber);  
                }
            }
        },
        async updateStatus(orderID,status,dellymanOrderId,referenceNumber){
            let url = this.$store.state.base_url + 'admin/order/change-status';
            let data= {
                "deliveryOrderCode": referenceNumber,
                "newOrderStatus": status,
                "deliveryOrderId": dellymanOrderId,
                "orderId": orderID
            }
            try{
                let token = await this.$store.getters.getToken
                let res =  await this.axios.post(url,data,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                this.columns.content.map( (item) =>{ 
                    if(item.id === this.OrderId){
                        item.status = status;
                        item.deliveryOrderNumber = dellymanOrderId;
                    }
                });  
                //Booking an order
                this.showPreloader = false;
                this.$refs.submit.disabled = false;
                this.toggleEditModal();     
            }catch(error){
                this.showPreloader = false;
                this.error = err.response.data.message
                this.$refs.submit.disabled = false;
            }   
        },
        async confirmPayment(){
            this.error = (!this.amount) ? "Invaild details in the form" : null;
            if (!this.error) {
                this.showPreloader = true;
                let url = this.$store.state.base_url + 'admin/order/'+ this.OrderId + '/process-payment';
                let data = {
                    amountPaid: parseInt(this.amount)
                }
                try{
                    let token = await this.$store.getters.getToken
                    let res = await this.axios.post(url,null,{
                        headers: {
                            Authorization: 'Bearer ' + token
                        },
                        params:{
                            amountPaid: parseInt(this.amount)
                        }
                    })
                    this.columns.content.map( (item) =>{ 
                        if(item.id === this.OrderId){
                            item.status = "ORDER_PAID";
                        }
                    });
                    this.showPreloader = false;
                    this.$refs.submit.disabled = false;
                    this.toggleEditModal();

                }catch(error){
                    this.error = error.response.data.message;
                    this.showPreloader = false;
                    this.$refs.submit.disabled = false;
                }
            }
        },
        showChange(){
            this.changeStatus = !this.changeStatus
        },
        sendStatus(){
            this.changeStatus = false;
        },
        getSingleOrder(id){
            let singleOrder = this.columns.content.find( (item) => item.orderReferenceNumber === id);
            this.modalInfo = singleOrder;
            this.toggleModal()
        },
        formatNumber(num) {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        },
        prev(){
            if(this.page - 1 >= 0){
                this.Orders = []
                this.$emit('changePage',{
                    page: this.page - 1,
                    size: this.rowPerpage
                })
            }
        },
        next(){
            if(this.page + 1 < this.columns.totalPages){
                this.Orders = []
                this.$emit('changePage',{
                    page: this.page + 1,
                    size: this.rowPerpage
                })
            }
        },
        sortList(){
            this.searchResult = this.columns.content.filter(col => col.orderReferenceNumber.toLowerCase().includes(this.search.toLowerCase()) || 
                col.user.firstName.toLowerCase().includes(this.search.toLowerCase()) || 
                col.user.lastName.toLowerCase().includes(this.search.toLowerCase()) || col.cartItems[0].timeBelt.name.toLowerCase().includes(this.search.toLowerCase())
            );
            this.Orders = this.searchResult
            if (this.searchResult < 1 ) {
                this.Orders = this.columns.content
            }
        },
        datewithTime(date){
            return moment(date).format('LLLL');
        },
        changeDate(date){
           return moment(date).format('MMMM Do YYYY');
        },
        filterStatus(status){
            let allStatus = this.$store.state.delievryStatus
            if(status == "ORDER_CONFIRMED" || status == "ORDER_PAID" || status == "ORDER_PAID" || status == "ORDER_PREPARATION_IN_PROGRESS" || status == 'ORDER_PREPARATION_COMPLETED'){
                return allStatus;
            }else if(status == "ORDER_READY_FOR_PICKUP"){
                return allStatus.filter((item)=> item.status != status )
            }else if(status == "ORDER_SHIPPED"){
                return allStatus.filter((item)=> item.status !== status && item.status !== "ORDER_READY_FOR_PICKUP" )
            }else if(status == "ORDER_DELIVERED"){
                return [];
            }else if(status == "ORDER_CANCELLED"){
                return allStatus.filter((item)=> item.status == "ORDER_REFUNDED" )
            }else{
                return []
            }
        },
        findStatus(name){
             let allStatus = this.$store.state.delievryStatus
             let Newstatus = allStatus.find((item) => item.status == name);
            return Newstatus.name;
        },
        loginDellyman(){

        },
        async bookDellymanOrder(orderID,status){
            let singleOrder = this.columns.content.find( (item) => item.id === orderID);
            let DeliveryContactName = singleOrder.contactPersonName;
            let phone = singleOrder.contactPersonPhoneNumber;
            let amount =singleOrder.totalCost;
            let deliveryAddress = singleOrder.deliveryAddress;
            let PickUpContactName = singleOrder.restaurant.name;
            let PickUpContactNumber = singleOrder.restaurant.phoneNumber;
            let PickUpGooglePlaceAddress = singleOrder.restaurant.address;
            let PickUpState = singleOrder.restaurant.state;
            let PickUpCity = singleOrder.restaurant.city;
            let DeliveryState = singleOrder.restaurant.state
            let Carrier = this.Carrier;
            let dellymanUrl = this.$store.state.base_url + 'dellyman/order';
            let foodId = '';   
            singleOrder.cart.foodCartItems.map((one,index) =>{
                if (index + 1 == singleOrder.cart.foodCartItems.length) {
                    foodId = `${foodId} [Combo ${index + 1} - ${one.food.name} (${one.quantity}) ];`;
                }else{
                    foodId = `${foodId} [Combo ${index + 1} - ${one.food.name} (${one.quantity}) ];`;
                }       
            })
            let containers = "";
            singleOrder.cart.foodContainerCartItems.map((one,index) =>{
                let foodId = "";
                one.foodCartItems.map((meal,index) =>{
                    if (index + 1 == one.foodCartItems.length) {
                        foodId = `${foodId} ${meal.food.name} (${meal.quantity})`;
                    }else{
                        foodId = `${foodId} ${meal.food.name} (${meal.quantity}),`;
                    }       
                })

                if (index + 1 == singleOrder.cart.foodContainerCartItems.length) {
                    containers = `${containers}  [Container ${index + 1} - ${foodId}];`;
                }else{
                    containers = `${containers}  [Container ${index + 1} - ${foodId}];`;
                }       
            })

            let productNames = `[Order ${singleOrder.orderReferenceNumber.toUpperCase()}],[Total item(s) ${ singleOrder.cart.foodCartItems.length + singleOrder.cart.foodContainerCartItems.length }] ${(singleOrder.cart.foodContainerCartItems.length) ? `${containers}` : ""} ${ (singleOrder.cart.foodCartItems.length) ? `${foodId}` : "" }`
            let bookingDetails =  {
                "PaymentMode": 'online',
                "FixedDeliveryCharge": 10,
                "OrderRef" : singleOrder.orderReferenceNumber.toUpperCase(),
                "Vehicle": Carrier ,
                "IsProductOrder": 0,
                "BankCode":"",
                "AccountNumber":"",
                "IsProductInsurance": 0,
                "InsuranceAmount": 0,
                "IsInstantDelivery" : 1,
                "PickUpContactName": PickUpContactName,
                "PickUpContactNumber":PickUpContactNumber,
                "PickUpGooglePlaceAddress":PickUpGooglePlaceAddress,
                "PickUpLandmark":"",
                "PickUpRequestedTime": "06 AM to 09 PM",
                "PickUpRequestedDate": moment().format('l'),
                "DeliveryRequestedTime": "06 AM to 09 PM",

                "Packages": [
                    {
                        "DeliveryContactName": DeliveryContactName ,
                        "DeliveryContactNumber": phone,
                        "DeliveryGooglePlaceAddress": deliveryAddress ,
                        "DeliveryLandmark": "String",
                        "PackageDescription": productNames,
                        "PickUpCity": PickUpCity,
                        "DeliveryCity":PickUpCity,
                        "ProductAmount": amount,
                        "PickUpState": PickUpState,
                        "DeliveryState": DeliveryState
                    }
                ],
            }
            try {
                let token = await this.$store.getters.getToken
                let res = await this.axios.post(dellymanUrl,bookingDetails,{
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: 'Bearer ' + token
                    }
                })
                 if(res.data.responseCode == "101"){
                        this.error = res.data.responseMessage;
                        this.showPreloader = false;
                        this.$refs.submit.disabled = false; 
                }else{
                    let dellyManResponse = res.data;
                    this.updateStatus(orderID,status,dellyManResponse.orderID,dellyManResponse.reference);  
                }  
            } catch (error) {
                this.error = error.response.data.ResponseMessage;
                this.showPreloader = false;
                this.$refs.submit.disabled = false; 
            }
        },
        GetCarriers(){
            let dellymanUrl = this.$store.state.dellyman_base_url + 'Vehicles';
            delete this.axios.defaults.headers.common['Authorization'];
            this.axios.get(dellymanUrl)
            .then((res)=>{
                this.Carriers = res.data
            }) 
        },
        getComboTotal(){
            let cost = 0;
            this.modalInfo.cartDto.foodCartItems.forEach(element => {
               cost = cost + (element.price * element.quantity)
            });

            return cost
        },
        async processBuyForMeOrder(){

            let url = this.$store.state.base_url + 'admin/orders/process-buy-for-me-order/' + this.modalInfo.id;

            this.showProcessOrder = true
            try{
                let token = await this.$store.getters.getToken
                let res = await this.axios.put(url,null,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    },
                })
                
                this.showProcessOrder = false
                this.columns.content.map( (item) =>{ 
                    if(item.id === this.modalInfo.id){
                        item.status = 'ORDER_READY_FOR_PICKUP';
                    }
                });  

            }catch(error){
                this.showProcessOrder = false
                console.log(error)
                this.error = error.response.data.message
            }
        }
    },
    watch: {
        columns: function () {
            this.Orders = this.columns.content
            this.numberofPages = this.columns.totalPages
        },
        rowPerpage : function () {
            this.Orders = []
            this.$emit('changePage',{
                page: 0,
                size: this.rowPerpage
            })
        }
    },
    mounted(){
       this.GetCarriers();
    }
}
</script>
<style>
:root{
	--loader-width: 20px;
	--loader-height: 20px;
	--loader-color-primary: #000000;
	--loader-color-secondary: #eee;
	--line-width: 2px;
	--animation-duration: 2s;
	--loader-initial-scale: 0.1;
}
</style>