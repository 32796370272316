<template>
    <div class="flex justify-between mb-4" >
        <div class="flex space-x-2">
            <p class="text-sm flex items-center">Rows per page</p>
            <select v-model="rowPerpage"  class="border p-0.5 focus:outline-none " name="" id="">
                <option value="10">10</option>
                <option value="25" >25</option>
                <option value="50" >50</option>
            </select>
        </div>
        <div class="flex space-x-3 border border-gray-100 p-0.5 px-3">
            <input type="text"  @input="sortList" v-model="this.search"  placeholder="Search" class="focus:outline-none" >
            <button>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
            </button>
        </div>
    </div>
    <table class="w-full" >
        <thead class="bg-gray-100 border-gray-100 border">
            <tr class="">
                <th v-for="row in rows" :key="row" class="text-sm py-3 px-3 text-left" >{{ row }}</th>
            </tr>
        </thead>
          <tbody v-if="vendors.length < 1">
            <tr class="border border-t-0" >
                <td  class="text-sm p-3 text-center" :colspan="rows.length">No data found</td>  
            </tr>
        </tbody>
        <tbody>
            <tr v-for="col in vendors.slice(startPage, Endpage)" :key="col" class="border border-t-0">
                <td class="text-sm p-3">{{ col.email }}  </td>
                <td class="text-sm p-3">{{ (col.phone != "string") ? col.phone : "-" }}</td>
                <td class="text-sm p-3">{{ changeDate(col.createdAt) }}  </td>
            </tr>
        </tbody>
    </table>
    <div class="flex justify-between bg-gray-100 h-12 px-4  items-center " >
        <div> {{ currentPage }} of {{ numberofPages }} pages (Showing {{ getFirstItem }} - {{ getLastItem }} of {{ vendors.length }} Entries)</div>
        <div class="flex flex-grow  justify-end" >
            <div class="flex space-x-1">
                <button @click="prev(currentPage)" class="pagination-btn active text-sm">Prev</button>
                <div  class="border border-gray-300  px-3 py-0.5" > {{ currentPage }} </div>
                <button @click="next(currentPage)" class="pagination-btn active text-sm">Next</button>
            </div>
        </div>
    </div>
    <Modal v-if="showModal" width="w-768" :show="true">
        <div class="flex justify-between">
            <h1 class="text-xl font-semibold">View User</h1>
            <div @click="toggleModal">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div>
        <div class="grid grid-cols-3 mt-4 gap-4" >
            <div>
                <p class="text-sm" >Full Name</p>
                <p class="font-semibold" >{{ single.firstName }} {{ single.lastName }}</p>
            </div>
            <div>
                <p class="text-sm" >Email</p>
                <p class="font-semibold" >{{ single.email }}</p>
            </div>
            <div>
                <p class="text-sm" >Phone Number</p>
                <p class="font-semibold" >{{ single.phoneNumber }}</p>
            </div>
        </div>
        <div class="mt-4 flex-grow flex space-x-2 justify-end">
            <button @click="toggleModal" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                Close
            </button>
        </div>
    </Modal>
    <Modal v-if="showSetComission"  width="w-500" :show="true" >
        <div class="flex justify-between">
            <h1 class="text-xl font-semibold">Set Vendor Commission</h1>
            <div @click="tooglesetComission">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div>
        <div v-if="error" class="text-sm text-red-500 mt-3">{{ error }}</div>
        <form @submit.prevent="SumbitVendorComission">
            <div>
                <label for="commission" class="text-sm" >Set Commision</label>
                <input type="number" min="1" max="50" v-model="comission" class="input" placeholder="Set Commision">
            </div>
            <div class="mt-4 flex-grow flex space-x-2 justify-end">
                <button @click="tooglesetComission" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                    Close
                </button>
                <button type="submit" :disabled="showComissionPreloader" class="bg-brand px-3 text-sm py-2 font-semibold rounded">
                    <Preloader v-if="showComissionPreloader"/>
                    <span v-else >Set Commission</span>      
                </button>
            </div>  
        </form>
    </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import Preloader from "@/components/Preloader.vue";
import axios from "axios";
import moment from 'moment';

export default {
    props:['rows','columns'],
    components:{
        Modal,Preloader
    },
    data(){
        return{
            search:'',
            vendors: this.columns,
            showModal:false,
            searchResult:[],
            rowPerpage:10,
            Endpage: 10,
            startPage : 0,
            numberofPages:0,
            currentPage:1,
            showSetComission:false,
            comission:0,
            showComissionPreloader:false,
            error:""
        }
    },
    computed:{
        getFirstItem(){
            return this.vendors.findIndex((item)=> item.userId == this.vendors.slice(this.startPage, this.Endpage)[0].userId ) + 1
        },
        getLastItem(){
            return this.vendors.findIndex((item)=> item.userId == this.vendors.slice(this.startPage, this.Endpage)[this.vendors.slice(this.startPage, this.Endpage).length - 1].userId ) + 1
        }
    },
    methods: {
        sortList(){
            this.searchResult = this.columns.filter((col) => 
                col.firstName.toLowerCase().includes(this.search.toLowerCase()) ||
                col.lastName.toLowerCase().includes(this.search.toLowerCase()) ||
                col.email.toLowerCase().includes(this.search.toLowerCase()) 
            );  
            this.vendors = this.searchResult;
            if (this.searchResult < 1 ) {
                this.vendors = this.columns
            }
        },
        toggleModal(){
            this.showModal = !this.showModal
        },
        singleUser(id){
            let singleUser = this.columns.find( (item) => item.userId === id);
            this.single = singleUser;
            this.toggleModal();
        },
        prev(currentPage){
            let prev = currentPage - 1;
            if (prev) {
              this.currentPage = prev;
              this.startPage  = ((prev - 1) * this.rowPerpage);
              this.Endpage = prev * this.rowPerpage            
            }
        },
        next(currentPage){
            let next = currentPage + 1;
            if (next <= this.numberofPages) {
                this.currentPage = next;
                this.startPage  = ((next - 1) * this.rowPerpage);
                this.Endpage = next * this.rowPerpage;
            }
        },    
        changeDate(date){
           return moment(date).format('llll');
        }

    },
    watch:{
        columns: function () {
            this.vendors = this.columns
            this.numberofPages = Math.ceil(this.columns.length/this.rowPerpage)
        },
        rowPerpage : function () {
            this.numberofPages = Math.ceil(this.columns.length/this.rowPerpage)
            this.Endpage = this.rowPerpage;
            this.startPage  = 0
            this.currentPage = 1

        }
    }
}
</script>

<style>

</style>