<template>
    <Admin>
        <div class="flex justify-between">
            <div class="flex" > 
                <div class="h-2 w-2 bg-brand" ></div>  
                 <h1 class="font-semibold text-3xl">Service Charge</h1>
            </div>
            <div class="flex space-x-3 items-center" >
                <button @click="toggleModal" class="p-3 bg-brand rounded flex space-x-2" >
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    <p class="text-sm font-semibold" >Add Service Scheme</p>
                </button>
            </div>
        </div>
        <div class="mt-8" >
            <Table :rows="rows" :columns="columns" :msg="msg"/>
        </div>
    </Admin>
    <Modal v-if="showModal" width="w-500"  :show="true">
        <div class="flex justify-between">
            <h1 class="text-xl font-semibold">Add Service Scheme</h1>
            <div @click="toggleModal">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div>  
        <div v-if="error" class="text-sm text-red-500 mt-3">{{ error }}</div>
        <form @submit.prevent="createServiceScheme" class="mt-3">
            <div class="mb-3" >
                <label for="name" class="text-sm">Name</label>
                <input type="text" v-model="formData.name" id="name" class="input" placeholder="Name">
            </div>
            <div class="grid grid-cols-2 gap-2 mb-3">
                <div>
                    <label for="rateType" class="text-sm" >Select Rate type</label>
                    <select @change="clearValue()" v-model="formData.rateType" id="rateType" class="input" >
                        <option value="">Select rate type</option>
                        <option value="FLAT">Flat Rate</option>
                        <option value="PERCENT">Percentage</option>
                    </select>
                </div>
                <div v-if="formData.rateType == 'PERCENT'" >
                    <label for="value" class="text-sm">Percentage Value</label>
                    <input type="number" max="100" step="0.01" v-model="formData.value" id="value" class="input" placeholder="Percentage">
                </div>
                <div v-else >
                    <label for="value" class="text-sm">Value</label>
                    <input type="number" step="0.01" v-model="formData.value" id="value" class="input" placeholder="Value">
                </div>
            </div>
            <div class="grid grid-cols-2 gap-2 my-3">
                <div>
                    <label for="minimumAmount" class="text-sm" >Minimum Amount</label>
                    <input type="number" v-model="formData.minimumAmount" id="minimumAmount" class="input" placeholder="Minimum Amount">          
                </div>
                <div>
                    <label for="maximumAmount" class="text-sm" >Maximum Amount </label>
                    <input type="number" v-model="formData.maximumAmount" id="maximumAmount" class="input" placeholder="Maximum Amount">          
                </div>
            </div>
            <div>
            <label for="description" class="text-sm" >Description</label>
                <textarea v-model="formData.description" class="input" placeholder="Description" id="description" cols="10" rows="4">Description</textarea>
            </div>
            <div class="mt-4 flex-grow flex space-x-2 justify-end">
              <button @click="toggleModal" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                Close
              </button>
            <button type="submit" :disabled="showPreloader" ref="submit" class="bg-brand px-3 text-sm py-2 font-semibold rounded">
                <Preloader v-if="showPreloader"/>
                <span v-else >Add</span>      
            </button>
            </div>  
        </form>
    </Modal>
</template>
<script>
import Admin from "@/components/admin/AdminTemplate.vue";
import Modal from "@/components/Modal.vue";
import Preloader from "@/components/Preloader.vue";
import Table from "@/components/admin/Tables/ServiceChargeTable.vue";

export default{
    name:"DiscountCode",
    components:{
        Admin,Modal,Preloader,Table
    },
    data(){
        return {
            showModal:false,
            formData:{
                value: "",
                rateType:"",
                description:"",
                minimumAmount:"",
                maximumAmount:"",
                name:""
            },
            msg:"Loading ...",
            showPreloader:false,
            rows:['Name','Rate type','Minimum Amount','Maximum Amount','Value','Action'],
            columns:[],
            error:""
            
        }
    },
    methods:{
        toggleModal(){
            this.showModal = !this.showModal
        },
        async createServiceScheme(){
            if(!this.formData.name){
                this.error = 'Name field is required';
            }else if(!this.formData.value){
                this.error = 'Amount field  is required';
            }else if(!this.formData.rateType){
                this.error = 'Rate type field required';
            }else if(this.formData.rateType == "PERCENT" && this.formData.value > 100 ){
                this.error = 'Percent cannot be more than 100';
            }else if(!this.formData.description){
                this.error = 'Description field is required';
            }else if(!this.formData.minimumAmount){
                this.error = 'Minimun Amount is required';
            }else if(!this.formData.maximumAmount){
                this.error = 'Maximum amount is required';
            }else{
                this.showPreloader = true
                let url = this.$store.state.base_url + 'admin/service-charge-scheme';
                try {
                    let token = await this.$store.getters.getToken;
                    let res = await this.axios.post(url,this.formData,{
                        headers:{
                            Authorization: 'Bearer ' + token
                        }
                    })
                    this.showPreloader = false;
                    await this.getServiceScheme();
                    this.toggleModal();
                    
                } catch (error) {
                    this.showPreloader = false;

                    if(error.response.status == 400){
                        this.error = error.response.data.payload[0].message;
                    }
                }
            }
        },
        async getServiceScheme(){
            let url = this.$store.state.base_url + 'admin/service-charge-scheme';
            let token = await this.$store.getters.getToken
            let res = await this.axios.get(url,{
                headers:{
                    Authorization: 'Bearer ' + token
                }
            });
            this.columns = res.data;
            this.msg = "No data found"
        },
        clearValue(){
            this.formData.value = "";
        }
    },
    mounted(){
        this.getServiceScheme();
    }
}
</script>