<template>
  <Admin>
    <div class="flex justify-between">
        <div class="flex" > 
            <div class="h-2 w-2 bg-brand" ></div>  
              <h1 class="font-semibold text-3xl" >Vendors</h1>
        </div>
        <div class="flex space-x-3 items-center" >
          <button @click="toggleModal" class="p-3 bg-brand rounded flex space-x-2" >
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <p class="text-sm" >Add Vendors</p>
          </button>
      </div>
    </div>
    <div class="my-8" >
        <Table :rows="rows" :columns="columns" :page="page" :msg="msg" @changePage="changePage"/>
    </div>
      <Modal v-if="showModal" width="w-500" :show="true">
            <div class="flex justify-between">
                <h1 class="text-xl font-semibold">Add Vendor</h1>
                <div @click="toggleModal">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
            </div>         
            <div v-if="error" class="text-sm text-red-500 mt-3">{{ error }}</div>
            <form @submit.prevent="addVendor" ref="addVendor" class="mt-3" method="post">
                <div class="grid grid-cols-2 gap-2 mb-3">
                    <div>
                        <label for="fullname" class="text-sm" >First Name</label>
                        <input type="text" v-model="firstName" name="firstName" id="firstName"  class="input" placeholder="Enter First Name">
                    </div>
                   <div>
                        <label for="fullname" class="text-sm" >Last name</label>
                        <input type="text" v-model="lastName" name="lastName"  id="lastName" class="input" placeholder="Enter Last Name">
                    </div>
                </div>
                <div class="mb-3" >
                  <label for="fullname" class="text-sm" >E-mail</label>
                    <input type="email" v-model="email" name="email" id="email" class="input" placeholder="ng@example.com">
                </div>
                <div>
                  <label for="phone" class="text-sm" >Phone Number</label>
                  <input type="text" v-model="phone" name="phone" id="phone" class="input" placeholder="08012345678">
                </div>
                <div class="mt-4 flex-grow flex space-x-2 justify-end">
                  <button @click="toggleModal" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                    Close
                  </button>
                    <button ref="submit" class="bg-brand px-3 text-sm py-2 font-semibold rounded">
                        <Preloader v-if="showPreloader"/>
                        <span v-else >Add Vendor</span>      
                    </button>
                </div>
            </form>
        </Modal>
  </Admin>
</template>

<script>
import Admin from "@/components/admin/AdminTemplate.vue";
import Table from "@/components/admin/Tables/VendorTable.vue";
import Preloader from "@/components/Preloader.vue";
import Modal from "@/components/Modal.vue";

export default {
    name:['Admin'],
    components:{
        Admin,Table,Preloader,Modal
    },
    data(){
      return{
        firstName:"",
        lastName:"",
        email:"",
        phone:"",
        showModal:false,
        error: null,
        showPreloader:false,
        rows:['Fullname','Email Address','Phone Number','Status','Created' ,'Actions'],
        columns:[],
        page:0,
        size:10,
        msg:"Loading ..."
      }
    },
    methods:{
      toggleModal(){
        this.showModal = !this.showModal
        this.error =null;        
        this.firstName = this.lastName = this.phone = this.email = this.password = ""
      },
      async addVendor(){
        let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        let regexPhone = /^[0]\d{10}$/gm;
        this.error = (!this.firstName ||  !this.lastName || !this.email.match(regexEmail) || !regexPhone.test(this.phone)) ? "Invaild details in the form" : null
        if(!this.error) {
           this.showPreloader = true;
           this.$refs.submit.disabled = true;
           let data = {
              "email": this.email,
              "firstName": this.firstName,
              "phoneNumber": this.phone,
              "lastName": this.lastName,
              "active": true,
              "address": "string",
              "confirmPassword": "foobar",
              "password": "foobar",
              "url": window.location.origin
            }
           let url = this.$store.state.base_url + 'admin/register-vendor';
           let token = await this.$store.getters.getToken
           this.axios.post(url,data,{
              headers: {
                  Authorization: 'Bearer ' + token
              }
           })
           .then(res => {
              this.showPreloader = false;
              this.$refs.submit.disabled = false;
              this.toggleModal()
              this.getVendors()
           })
           .catch(err=> {
             this.$refs.submit.disabled = false;
             this.showPreloader = false;
              this.error = err.response.data.message
           })
        }       
      },
      async getVendors(){
          let url = this.$store.state.base_url + 'admin/vendor';
          try{
              let token = await this.$store.getters.getToken;
              let res = await this.axios.get(url,{
                  headers: {
                      Authorization: 'Bearer ' + token
                  },
                  params:{
                    page:this.page,
                    size:this.size
                  }
              })
              this.columns = res.data
              if(!this.columns.content.length){
                this.msg = "No data found"
              }
          }catch(error){
            console.log(error)
          }

      },
      async changePage(data){
          this.columns.content = [] 
          this.msg = "Loading ..."
          this.page = data.page
          this.size = data.size
          await this.getVendors()           
      }
    },
    beforeMount(){
        this.getVendors();
    }

}
</script>

<style>
:root{
	--loader-width: 20px;
	--loader-height: 20px;
	--loader-color-primary: #000000;
	--loader-color-secondary: #eee;
	--line-width: 2px;
	--animation-duration: 2s;
	--loader-initial-scale: 0.1;
}

</style>