<template>
  <div class="flex space-x-4 py-5 px-6  bg-gray-100" >
      <div class="rounded-full px-6 py-2 w-72 bg-white flex space-x-4" >
          <button class="flex items-center" >
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          </button>
          <input type="text" class="focus:outline-none" placeholder="Search for anything" name="" id="">
      </div>
      <div class="flex-grow" >
          <div class="flex justify-end space-x-4">
             <div class="flex space-x-3" >
                <div class="h-10 w-10 bg-white text-black rounded-full" >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                         <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </div>
                <div class="relative z-30 items-center">
                    <div class="flex space-x-3" @click="toggleDropdwn">
                        <div class="flex items-center">
                            <div>
                                <p class="">{{ adminData.firstName  }}</p>
                                <p class="text-xs">{{ adminData.role  }}</p>
                            </div>
                        </div>
                        <div class="flex items-center" >
                            <button>
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <Dropdown @closeDropdwn="toggleDropdwn" :items="dropdownList" v-show="showDropdwn"/>
                </div>
          
             </div>
          </div>
      </div>     
  </div>
</template>

<script>
import Dropdown from "@/components/Dropdown.vue";
export default {
    name:['AdminHeader'],
    props:['adminData'],
    components:{
        Dropdown
    },
    data(){
        return{
            showDropdwn:false,
            dropdownList:[
                {
                    name:"Settings",
                    url: "/settings"
                },
                {
                    name:"Log out",
                    url: "/logout"
                }
            ]        
        }
    },
    methods:{
        toggleDropdwn(){
            this.showDropdwn = !this.showDropdwn
        }
    }
}
</script>
