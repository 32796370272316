<template>
    <Admin>
        <div class="flex justify-between">
            <div class="flex" > 
                <div class="h-2 w-2 bg-brand" ></div>  
                 <h1 class="font-semibold text-3xl" >Dashboard</h1>
            </div>
        </div>
        <div v-if="analytics" class="mt-8 grid grid-cols-4 gap-3">
            <div class=" flex space-x-4 border border-gray-300 rounded-md p-5" >
                <div class="h-16 w-16 bg-yellow-400 flex justify-center items-center rounded-full">
                    <img src="@/assets/svg/earning.svg" class="h-6 w-6" alt="">
                </div>
                <div class="flex items-center" >
                    <div>
                        <p class="font-semibold text-xl" >{{ analytics.allUsersCount }}</p>
                        <p class="text-xs">Total Users</p>
                    </div>     
                </div>
            </div>
            <div class=" flex space-x-4 border border-gray-300 rounded-md p-5" >
                <div class="h-16 w-16 bg-blue-300 flex justify-center items-center rounded-full">
                    <img src="@/assets/svg/menu.svg" class="h-6 w-6 text-gray-50" alt="">
                </div>
                <div class="flex items-center" >
                    <div>
                        <p class="font-semibold text-xl" >
                            {{ analytics.allRestaurantCount }}
                        </p>
                        <p class="text-xs">Total Restaurants</p>
                    </div>     
                </div>
            </div>
            <div class=" flex space-x-4 border border-gray-300 rounded-md p-5" >
                <div class="h-16 w-16 bg-purple-400 rounded-full flex justify-center items-center">
                     <img src="@/assets/svg/order.svg" class="h-6 w-6" alt="">
                </div>
                <div class="flex items-center" >
                    <div>
                        <p class="font-semibold text-xl" >{{ analytics.allFoodsCount }}</p>
                        <p class="text-xs">Total Menus</p>
                    </div>     
                </div>
            </div>
            <div class=" flex space-x-4 border border-gray-300 rounded-md p-5" >
                <div class="h-16 w-16 bg-green-400 rounded-full flex justify-center items-center">
                    <img src="@/assets/svg/delivery-truck.svg" class="h-6 w-6" alt="">
                </div>
                <div class="flex items-center" >
                    <div>
                        <p class="font-semibold text-xl" >
                            {{ analytics.allOrdersCount }}
                        </p>
                        <p class="text-xs">Total Orders</p>
                    </div>     
                </div>
            </div>
        </div>
        <div class="mt-8 grid grid-cols-4 gap-3">
            <div class=" flex space-x-4 border border-gray-300 rounded-md p-5" >
                <div class="h-16 w-16 bg-pink-400 flex justify-center items-center rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </div>
                <div class="flex items-center" >
                    <div>
                        <p class="font-semibold text-xl" >&#8358;{{ (analytics.totalSales) ? this.global.largeNumberConverter(analytics.totalSales) : "0.00" }}</p>
                        <p class="text-xs">Total Sales</p>
                    </div>     
                </div>
            </div>
            <div class=" flex space-x-4 border border-gray-300 rounded-md p-5" >
                <div class="h-16 w-16 bg-red-400 flex justify-center items-center rounded-full">
                   <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                     <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </div>
                <div class="flex items-center" >
                    <div>
                        <p class="font-semibold text-xl" >
                            &#8358;{{ (analytics.totalCommission) ? this.global.largeNumberConverter(analytics.totalCommission) : "0.00"  }}
                        </p>
                        <p class="text-xs">Net Commission</p>
                    </div>     
                </div>
            </div>
            <div class=" flex space-x-4 border border-gray-300 rounded-md p-5" >
                <div class="h-16 w-16 bg-blue-400 rounded-full flex justify-center items-center">
                     <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </div>
                <div class="flex items-center" >
                    <div>
                        <p class="font-semibold text-xl" >&#8358; 0</p>
                        <p class="text-xs">Amount Settled</p>
                    </div>     
                </div>
            </div>
            <div class=" flex space-x-4 border border-gray-300 rounded-md p-5" >
                <div class="h-16 w-16 bg-gray-300 rounded-full flex justify-center items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </div>
                <div class="flex items-center" >
                    <div>
                        <p class="font-semibold text-xl" >
                            &#8358; 0
                        </p>
                        <p class="text-xs">Amount Pending</p>
                    </div>     
                </div>
            </div>
        </div>
        <div class="my-4 flex space-x-6" >
            <div class="w-3/5 h-52 py-4" >
                <p class="font-semibold text-xl" >Sale Analytics</p>
                <div class=" bg-gray-100 h-56 my-2 flex justify-center items-center" >Sale analytics coming soon</div>
            </div>
            <div class="py-4 flex-grow" >
                <div class="flex justify-between">
                    <div class="flex" > 
                        <div class="h-2 w-2 bg-brand" ></div>  
                            <h1 class="font-semibold text-xl" >Timebelt</h1>
                    </div>
                    <div class="hidden space-x-3 items-center" >
                        <button @click="toogleCreate" class="p-1 bg-brand rounded flex space-x-1" >
                        <div class="flex items-center" >
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </div>
                        </button>
                    </div>
                </div>
                <div class="my-2">
                    <table class="w-full">
                        <thead class="bg-gray-100 border-gray-100 border">
                            <tr>
                                <th class="py-3 px-3 text-left text-sm">Timebelt</th>
                                <th class="py-3 px-3 text-left text-sm">Start time</th>
                                <th class="py-3 px-3 text-left text-sm">End time</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="timebelt in getAnyfourTimebelt" :key="timebelt" class="border border-t-0">
                                <td class="text-sm p-3">{{ timebelt.name }}</td>
                                <td class="text-sm p-3">{{ timebelt.startTime }}</td>
                                <td class="text-sm p-3">{{ timebelt.endTime }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </Admin>
</template>

<script>
import Admin from "@/components/admin/AdminTemplate.vue";

import Preloader from "@/components/Preloader.vue";
import Modal from "@/components/Modal.vue";
export default {
    name:['Dashboard'],
    components:{
        Admin,Modal,Preloader
    },
    data(){
        return {
           analytics:{},
           timebelts:[],
           showPreloader:false,
           showcreateModal:false,
           editModal:false,
        }
    },
    computed:{
        getAnyfourTimebelt(){
          let timebelts = this.timebelts.filter(item => item.name != 'Instant' );
          return timebelts.slice(0,4);
        }
    },
    methods:{
        async getDashboard(){
            let url = 'admin/dashboard';
            try {
                let token = await this.$store.getters.getToken
                let res = await this.axios.get(url,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                }) 
                this.analytics = res.data
            } catch (error) {
                console.log(error);
            }
        },
        async getAllTimebelt(){
            let url = this.$store.state.base_url + 'time-belt';
            let token = await this.$store.getters.getToken
            let res = await this.axios.get(url,{
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
            this.timebelts = res.data;       
        },
        formatNumber(num) {
            if(num){
                return num.toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            }else{
                return num
            }
            
        },
        toogleCreate(){
            this.showcreateModal = !this.showcreateModal
        },
        toogleeditModal(){
            this.editModal = !this.editModal;
        },
        singleTimebelt(id){
            let editTimebelt = this.timebelts.find((item) => item.id == id);
            return editTimebelt
        },
        deleteTimebelt(id){
            let url = `time-belt/delete/${id}`;
            this.axios.delete(url)
            .then((res)=>{
                this.getAllTimebelt(); 
            })
        },
        updateTimebelt(id){
            this.editTimebelt = this.singleTimebelt(id)
            this.toogleeditModal()
        }

    },
    beforeMount(){
        this.getDashboard();
        this.getAllTimebelt()
    }
}
</script>

<style>
:root{
	--loader-width: 20px;
	--loader-height: 20px;
	--loader-color-primary: #000000;
	--loader-color-secondary: #eee;
	--line-width: 2px;
	--animation-duration: 2s;
	--loader-initial-scale: 0.1;
}
</style>