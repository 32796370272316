<template>
    <Template>
        <div class="flex justify-between">
            <div class="flex" > 
                <div class="h-2 w-2 bg-brand" ></div>  
                 <h1 class="font-semibold text-3xl" >External Services</h1>
            </div>
            <div  class="flex space-x-3">
                <button @click="toggleModal" class="bg-brand py-3 rounded-md text-sm px-3 lg:px-6 flex space-x-2 font-semibold" >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <p class="text-sm hidden lg:block" >Add Bank</p>
                </button>
                     <button @click="showSettlementModal" class="bg-brand py-3 rounded-md text-sm px-3 lg:px-6 flex space-x-2 font-semibold">
                    <p class="text-sm hidden lg:block">Settle Service</p>
                </button>
            </div>
        </div>
        <Table :columns="bankDetails" :rows="rows" :msg="msg"></Table>
    </Template>
    <Modal width="w-500" v-if="showModal" :show="true">
        <div class="flex justify-between">
            <h1 class="text-xl font-semibold">Add Bank</h1>
            <div @click="toggleModal">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div>
        <div class="mt-4" >
            <div v-if="error" class="text-sm text-red-500 mt-3">{{ error }}</div>
            <div v-if="success" class="text-sm text-green-500 mt-3">{{ success }}</div>
            <form @submit.prevent="addBankAccount" method="post">
                <div>
                    <label for="bankName" class="text-sm">Bank Name</label>
                    <select @change="makeAccountNumberActive" v-model="bankName" class="input">
                        <option value="">Enter Bank Name</option>
                        <option v-for="bank in banks" :key="bank" :value="bank.code">{{ bank.name }}</option>
                    </select>
                </div>
                <div class="my-4">
                    <label for="bankName" class="text-sm">External service</label>
                    <select v-model="serviceIDBank" class="input">
                        <option value="">Select service</option>
                        <option v-for="name in services" :key="name" :value="name.id">{{ name.name }}</option>
                    </select>
                </div>
                <div class="my-4">
                    <label for="accountNumber" class="text-sm">Account Number</label> 
                    <input type="tel" disabled ref="accountNumber"  @input="resolveAccount"  maxlength="10" v-model="accountNumber" class="input" placeholder="Enter Account Number" autocomplete="off">
                </div>  
                <div class="mt-4">
                    <div class="flex space-x-3">
                        <label for="accountName" class="text-sm">Account Name</label>
                        <Preloader v-if="showResolvePreloader"/>
                    </div>
                    
                    <input type="text" disabled v-model="accountName" class="input" placeholder="Enter Account Name" autocomplete="off">
                </div>
                <div class="mt-4 flex-grow flex space-x-2 justify-end">
                    <button @click="toggleModal" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                        Close
                    </button>
                    <button type="submit" :disabled="showPreloader" class="bg-brand px-3 text-sm py-2 font-semibold rounded">
                        <Preloader v-if="showPreloader"/>
                        <span v-else >Add Bank Details</span>      
                    </button>
                </div>
            </form>
        </div>
    </Modal>
      <Modal width="w-500" v-if="settleServiceModal" :show="true">
        <div class="flex justify-between">
            <h1 class="text-xl font-semibold">Settle Service</h1>
            <div @click="showSettlementModal">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div>
        <div class="mt-4" >
            <div v-if="error" class="text-sm text-red-500 mt-3">{{ error }}</div>
            <div v-if="success" class="text-sm text-green-500 mt-3">{{ success }}</div>
            <form @submit.prevent="settleService" method="post">
                <div class="my-4">
                    <label for="bankName" class="text-sm">External service</label>
                    <select v-model="serviceIDSettle" class="input">
                        <option value="">Select service</option>
                        <option v-for="name in services" :key="name" :value="name.id">{{ name.name }}</option>
                    </select>
                </div>
                <div class="my-4">
                    <label for="bankName" class="text-sm">Bank Account</label>
                    <select v-model="bankDetailsID" class="input">
                        <option value="">Select Bank account</option>
                        <option v-for="name in bankDetails" :key="name" :value="name.id">{{ name.accountName }}</option>
                    </select>
                </div>
                <div class="mt-4 flex-grow flex space-x-2 justify-end">
                    <button @click="showSettlementModal" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                        Close
                    </button>
                    <button type="submit" :disabled="showPreloader" class="bg-brand px-3 text-sm py-2 font-semibold rounded">
                        <Preloader v-if="showSettlementPreloader"/>
                        <span v-else >Settle</span>      
                    </button>
                </div>
            </form>
        </div>
    </Modal>


</template>

<script>
import Modal from "@/components/Modal.vue";
import Template from "@/components/admin/AdminTemplate.vue";
import Preloader from '@/components/Preloader.vue';
import Table from "@/components/admin/Tables/BankAccountTable.vue";

export default {
    name:"Discount",
    components:{
        Template,Preloader,Modal,Table
    },
    data(){
        return{
            bankName:"",
            accountName:"",
            accountNumber:"",
            error:null,
            serviceIDSettle:"",
            rows:[
                'Account Name', 'Account Number','Bank Name','Actions'
            ],
            msg:"Loading ...",
            showPreloader:false,
            banks:[],
            success:"",
            bankDetails:[],
            user: this.$store.getters.getUser,
            showModal:false,
            showResolvePreloader:false,
            showConfirm:false,
            showConfirmPreloader:false,
            serviceIDBank:"",
            serviceID:"",
            bankDetailsID:"",
            settleServiceModal:false,
            showSettlementPreloader:false
        }
    },
    methods:{
        toggleModal(){
            this.showModal =  !this.showModal
            this.bankName = this.accountNumber = this.accountName = ""
        },
        showSettlementModal(){
            this.settleServiceModal =  !this.settleServiceModal
            this.success = this.error = null
        },
        async addBankAccount(){
            if (!this.bankName) {
                this.error = "Bank Name is Required"
            }else if(!this.accountName){
                this.error = "Account Name is required";
            }else if(!this.accountNumber){
                this.error = "Account Name is required";
            }else{
                let choosenBank = this.banks.find((item) => item.code == this.bankName);
                this.showPreloader = true
                try {
                    let url = this.$store.state.base_url + 'admin/external-services/bank-account/' + this.serviceIDBank;
                    let data = {
                        "accountName": this.accountName,
                        "accountNumber": this.accountNumber,
                        "bankCode": choosenBank.code,
                        "bankName": choosenBank.name,
                        "default": false,
                        "description": "string"
                    }
                    let token = await this.$store.getters.getToken
                    await this.axios.post(url,data,{
                        headers:{
                            Authorization: 'Bearer ' + token
                        }
                    });
                    await this.apigetBank();
                    this.showPreloader = false;
                    this.success = "Successfully added Bank details";
                    this.toggleModal();
                } catch (error) {
                    console.log(error)
                    this.showPreloader = false;
                   this.error = error.response.data.message;
                }
            }
        },
        async getBanks(){
            try {
                let url = "https://api.paystack.co/bank";
                let res = await this.axios.get(url);
                this.banks = res.data.data
            } catch (error) {
                console.log(error);
            }
        },
        async apigetBank(){
            try {
                let url = this.$store.state.base_url + `admin/external-services/bank-account/${this.serviceID}`
                let token = await  this.$store.getters.getToken
                let res = await this.axios.get(url,{
                    headers:{
                        Authorization: 'Bearer ' + token
                    }
                });
                this.bankDetails.push(res.data);
                this.msg = "No data found"
            } catch (error) {
                this.msg = "No data found"
                console.log(error);
            }
        },
        makeAccountNumberActive(){
            if(this.bankName){
                this.$refs.accountNumber.disabled = false;
            }else{
                this.$refs.accountNumber.disabled = true;
            }
        },
        async resolveAccount(){
            let choosenBank = this.banks.find((item) => item.code == this.bankName);
            if(this.accountNumber.length == 10){
                this.showResolvePreloader = true
                let url = this.$store.state.base_url + 'admin/dellyman/bank-account/resolve';
                let data = {
                    "accountName": "string",
                    "accountNumber": this.accountNumber,
                    "bankCode":choosenBank.code,
                    "bankName": choosenBank.name,
                    "default": true,
                    "description": "string"
                }
                let token = await this.$store.getters.getToken
                let res = await this.axios.post(url,data,{
                    headers:{
                        Authorization: 'Bearer ' + token
                    }
                });
                this.showResolvePreloader = false;
                this.accountName = res.data.accountName;
            }
        },
        async getExternalService(){
            let url = this.$store.state.base_url + 'admin/external-services/all'
            let token = await this.$store.getters.getToken
            let res = await this.axios.get(url,{
                headers:{
                    Authorization: 'Bearer ' + token
                }
            })
            this.services = res.data.sort(function (a, b) {
                let dateA = a.id
                let dateB = b.id
                return dateA - dateB
            });
            this.bankDetails = this.services[0].bankAccounts
        },
        async settleService(){
            if(!this.serviceIDSettle){
                this.error = "Select an external service"
            }else if(!this.bankDetailsID){
                this.error = "Select a bank account"
            }else{
                try {
                    this.showSettlementPreloader = true;
                    let url = this.$store.state.base_url + 'admin/external-services/settle'
                    let token = await this.$store.getters.getToken;
                    await this.axios.post(url,{
                        "serviceBankAccountId": this.bankDetailsID,
                        "serviceId": this.serviceIDSettle
                    },{
                        headers:{
                            Authorization: 'Bearer ' + token
                        }
                    })
                    this.showSettlementPreloader = false;
                    this.success = "Successfully settled service"
                } catch (error) {
                    console.log(error)
                    this.showSettlementPreloader = false;
                    this.error = (error.response) ? error.response.data.message : null
                }
            }
        }
    },
    async mounted(){
        this.getBanks();
        await this.getExternalService();

    }
}
</script>
