<script>
export default {
    name:"ZoneTable",
    props:['columns','msg','page'],
    data(){
        return {
            search:"",
            rowPerpage:10,
            startPage : 0,
            rows:[
                'Zone',
                'Address',
                'Radius (KM)'
            ],
            numberofPages:0

        }
    },
    computed:{
        getZones(){
            this.numberofPages = this.columns.totalPages
            return this.columns.content
        },
        getFirstItem(){
            return this.page * this.rowPerpage || 1
        },
        getLastItem(){
            return  this.page * this.rowPerpage + this.getZones.length
        }
    },
    emits:['changePage'],
    methods:{
       
        changeRow(){
            this.$emit('changePage',{
                page: 0,
                size: this.rowPerpage
            })
        },
        sortList(){
            this.$emit('changePage',{
                page: 0,
                size: this.rowPerpage,
                name: this.search
            });
        },
        prev(){
            if(this.page - 1 >= 0){
                this.$emit('changePage',{
                    page: this.page - 1,
                    size: this.rowPerpage
                })
            }
        },
        next(){
            if(this.page + 1 < this.columns.totalPages){
                this.$emit('changePage',{
                    page: this.page + 1,
                    size: this.rowPerpage
                })
            }
        },
    }
}
</script>

<template>
    <div class="my-8" >
        <div class="flex justify-between mb-4" >
            <div class="flex space-x-2">
                <p class="text-sm flex items-center">Rows per page</p>
                <select @change="changeRow" v-model="rowPerpage" class="border p-0.5 focus:outline-none " name="" id="">
                    <option value="10">10</option>
                    <option value="25" >25</option>
                    <option value="50" >50</option>
                </select>
            </div>
            <div class="flex space-x-3 border border-gray-100 p-0.5 px-3">
                <input type="text" @input="sortList" v-model="search" placeholder="Search" class="focus:outline-none" >
                <button>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                </button>
            </div>
        </div>
        <table class="w-full" >
            <thead class="bg-gray-100 border-gray-100 border">
                <tr class="">
                    <th v-for="row in rows" :key="row" class="text-sm py-3 px-3 text-left" >
                       {{ row }}
                    </th>
                </tr>
            </thead>
            <tbody v-if="getZones.length < 1">
                <tr class="border border-t-0" >
                    <td  class="text-sm p-3 text-center" :colspan="rows.length">{{ msg }}</td>  
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-for="col in getZones.slice(startPage, rowPerpage)" :key="col" class="border border-t-0">
                    <td class="text-sm p-3">{{ col.name }}</td>
                    <td class="text-sm p-3">{{ col.centralLocationAddress }}</td>
                    <td class="text-sm p-3">{{ col.radiusInKm }}KM</td>
                </tr>
            </tbody>
        </table>
        <div class="flex justify-between bg-gray-100 h-12 px-4  items-center " >
            <div> {{ page + 1 }} of {{ numberofPages }} pages (Showing {{ getFirstItem }} - {{ getLastItem }} of {{ getZones.length }} Entries)</div>
             <div class="flex flex-grow  justify-end" >
                 <div class="flex space-x-1">
                     <button @click="prev()" class="pagination-btn active text-sm">Prev</button>
                     <div  class="border border-gray-300  px-3 py-0.5" > {{ page + 1 }} </div>
                     <button @click="next()" class="pagination-btn active text-sm">Next</button>
                 </div>
             </div>
         </div>
    </div>
</template>



<style>

</style>