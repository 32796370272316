<template>
  <div class="flex justify-between mb-4" >
        <div class="flex space-x-2">
            <p class="text-sm flex items-center">Rows per page</p>
            <select v-model="rowPerpage" class="border p-0.5 focus:outline-none " name="" id="">
                <option value="10">10</option>
                <option value="25" >25</option>
                <option value="50" >50</option>
            </select>
        </div>
        <div class="flex space-x-3 border border-gray-100 p-0.5 px-3">
            <input type="text" @input="sortList" v-model="search" placeholder="Search" class="focus:outline-none" >
            <button>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
            </button>
        </div>
    </div>

    <table class="w-full" >
        <thead class="bg-gray-100 border-gray-100 border">
            <tr class="">
                <th v-for="row in rows" :key="row" class="text-sm py-3 px-3 text-left" >
                   {{ row }}
                </th>
            </tr>
        </thead>
        <tbody v-if="reasons.length < 1">
            <tr class="border border-t-0" >
                <td  class="text-sm p-3 text-center" :colspan="rows.length">No data found</td>  
            </tr>
        </tbody>
        <tbody v-else>
            <tr v-for="col in reasons.slice(startPage, Endpage)" :key="col" class="border border-t-0">
                <td class="text-sm p-3" >{{ col.reason }}</td>
                <td class="text-sm p-3">{{ changeDate(col.createdAt) }}</td>
                 <td class="w-1/12" >
                    <button @click="editReason(col.id)" >
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                        </svg>
                    </button>
                    <!-- <button @click="getSingleMeal(col.id)" class="text-purple-500" >
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                      </svg>
                    </button> -->
                    <button @click="toogledelete(col.id)" class="text-red-500" >
                       <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="flex justify-between bg-gray-100 h-12 px-4  items-center " >
       <div> {{ currentPage }} of {{ numberofPages }} pages (Showing {{ getFirstItem }} - {{ getLastItem }} of {{ reasons.length }} Entries)</div>
        <div class="flex flex-grow  justify-end" >
            <div class="flex space-x-1">
                <button @click="prev(currentPage)" class="pagination-btn active text-sm">Prev</button>
                <div  class="border border-gray-300  px-3 py-0.5" > {{ currentPage }} </div>
                <button @click="next(currentPage)" class="pagination-btn active text-sm">Next</button>
            </div>
        </div>
    </div>
    <Modal v-if="showEditModal" width="w-500"  :show="true">
        <div class="flex justify-between">
            <h1 class="text-xl font-semibold">Edit Reason</h1>
            <div @click="toggleShowEdit">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div>        
        <div v-if="error" class="text-sm text-red-500 mt-3">{{ error }}</div>
        <form @submit.prevent="putReason">
            <textarea class="input" name="" id="" placeholder="Reason Comment" v-model="editreason" cols="30" rows="10"></textarea>
            <div class="mt-4 flex-grow flex space-x-2 justify-end">
                <button @click="toggleShowEdit" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                    Close
                </button>
                <button type="submit" :disable="showeditPreloader" class="bg-brand text-black px-3 text-sm py-2 font-semibold rounded">
                    <Preloader v-if="showeditPreloader"/>
                    <span v-else >Edit Reason</span>      
                </button>
            </div>  
        </form>
    </Modal>
    <Modal v-if="showdeleteModal" :show="true">
       <h1 class="text-2xl font-semibold">Are you sure</h1> 
       <p class="py-2 text-sm" >Do you really want to delete this record? This action cannot be undone</p>
        <div class="mt-4 flex-grow flex space-x-2 justify-end">
            <button @click="toogledelete" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                No
            </button>
            <button @click="deleteReason"  type="submit" ref="submit" class="bg-brand px-3 text-sm py-2 font-semibold rounded">
                <Preloader v-if="showDeletePreloader"/>
                <span v-else>Yes</span>      
            </button>
        </div>
    </Modal>
</template>
<script>
import Preloader from "@/components/Preloader.vue";
import Modal from "@/components/Modal.vue";
import axios from 'axios';
import moment from "moment";
export default {
    name:['ReasonTable'],
    props:['rows','columns','msg'],
    components:{
        Modal,Preloader
    },
    data(){
        return{
            search:"",
            rowPerpage:10,
            startPage : 0,
            showEditModal:false,
            showeditPreloader:false,
            currentPage:1,
            numberofPages:0,
            Endpage:10,
            reasons:[],
            editReasonData:{},
            editreason:"",
            error:null,
            showdeleteModal:false,
            showDeletePreloader:false,
            deleteId:null
        }
    },
    computed:{
        getFirstItem(){
            return this.reasons.findIndex((item)=> item.id == this.reasons.slice(this.startPage, this.Endpage)[0].id ) + 1
        },
        getLastItem(){
            return this.reasons.findIndex((item)=> item.id == this.reasons.slice(this.startPage, this.Endpage)[this.reasons.slice(this.startPage, this.Endpage).length - 1].id ) + 1
        }
    },
    methods:{
        changeDate(date){
           return moment(date).format('llll');
        },
        toggleShowEdit(){
            this.showEditModal = !this.showEditModal;
        },
        editReason(id){
            this.editReasonData = this.reasons.find( (item) => item.id === id);
            this.editreason = this.editReasonData.reason
            this.toggleShowEdit();
        },
        async putReason(){
            if(!this.editreason){
                this.error = "Reason comment is required"
            }else{
                this.showeditPreloader = true;
                let url = this.$store.state.base_url + `admin/order-rejection-reason/` + this.editReasonData.id;
                let data = {
                    id: this.editReasonData.id,
                    reason: this.editreason
                }
                try {
                    let token = await this.$store.getters.getToken
                    axios.put(url,data,{
                        headers: {
                            Authorization: 'Bearer ' + token
                        }
                    })
                    this.showeditPreloader = false;
                    this.reasons.map( (item) => {
                        if(item.id === this.editReasonData.id){
                            item.reason = this.editreason
                        }
                    });

                    this.toggleShowEdit();
                } catch (error) {
                    
                }
            }
        },
        toogledelete(id){
            this.showdeleteModal = !this.showdeleteModal
            if(id){
                this.deleteId = id
            }
        },
        async deleteReason(){
            if(this.deleteId){
                let url = this.$store.state.base_url + `admin/order-rejection-reason/` + this.deleteId;
                let token = await this.$store.getters.getToken
                let res = await axios.delete(url,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                this.reasons = this.reasons.filter((item) => item.id !== this.deleteId )
                this.toogledelete();
            }
        },
        next(currentPage){
            let next = currentPage + 1;
            if (next <= this.numberofPages) {
                this.currentPage = next;
                this.startPage  = ((next - 1) * this.rowPerpage);
                this.Endpage = next * this.rowPerpage;
            }
        },
        prev(currentPage){
            let prev = currentPage - 1;
            if (prev) {
              this.currentPage = prev;
              this.startPage  = ((prev - 1) * this.rowPerpage);
              this.Endpage = prev * this.rowPerpage            
            }
        },
        sortList(){
            if(this.search){
                this.searchResult = this.columns.filter(col => col.reason.toLowerCase().includes(this.search.toLowerCase()) );  
                this.reasons = this.searchResult;
                this.currentPage = 1
                this.startPage  = 0;
                this.numberofPages = Math.ceil(this.reasons.length/this.rowPerpage)
                if (this.searchResult < 1 ) {
                    this.reasons = []
                }
            }else{
                this.reasons = this.columns
                this.numberofPages = Math.ceil(this.columns.length/this.rowPerpage)
                this.Endpage = this.rowPerpage;
                this.startPage  = 0;
                this.currentPage = 1
            }
           
        }
    },
    watch: {
        columns: function () {
            this.reasons = this.columns
            this.numberofPages = Math.ceil(this.columns.length/this.rowPerpage)
        },
        rowPerpage : function () {
            this.numberofPages = Math.ceil(this.columns.length/this.rowPerpage)
            this.Endpage = this.rowPerpage;
            this.startPage  = 0;
            this.currentPage = 1
        }
    },

}
</script>

<style>

</style>