<template>
  <Admin>
    <div class="flex justify-between">
      <div class="flex">
        <div class="h-2 w-2 bg-brand"></div>
        <h1 class="font-semibold text-3xl">Referral</h1>
      </div>
      <div class="flex space-x-3 items-center">
        <button
          @click="toggleModal"
          class="p-3 bg-brand rounded flex space-x-2"
        >
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <p class="text-sm">Add Referral Scheme</p>
        </button>
      </div>
    </div>
    <div class="mt-8 pb-8">
      <referrals-table
        :rows="rows"
        :columns="columns"
        :msg="msg"
        @changePage="changePage"
        :pageNumber="pageNumber"
        :states="states"
        @search="searchResult"
      />
    </div>
    <Modal v-if="showModal" width="w-768" :show="true">
      <div class="flex justify-between">
        <h1 class="text-xl font-semibold">Add Referral Scheme</h1>
        <div @click="toggleModal">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
      </div>
      <div v-if="error" class="text-sm text-red-500 mt-3">{{ error }}</div>
      <div class="text-green-500" v-if="success">
        Successfully added new record
      </div>
      <form @submit.prevent="addReferral" class="mt-3">
        <div class="grid grid-cols-2 gap-2 mb-3">
          <div>
            <label for="name" class="text-sm">Referral Name</label>
            <input
              type="text"
              v-model="name"
              class="input"
              placeholder="Restaurant Name"
            />
          </div>
          <div>
            <label for="requiredNumberOfOrders" class="text-sm"
              >Required Number Of Orders</label
            >
            <input
              type="number"
              v-model="requiredNumberOfOrders"
              class="input"
              min="0"
              placeholder="Required Number of Orders"
            />
          </div>
        </div>
        <div class="grid grid-cols-2 gap-2 mb-3">
          <div>
            <label for="claimWindowDuration" class="text-sm"
              >Claim Window Duration</label
            >
            <input
              type="number"
              v-model="claimWindowDuration"
              name="claimWindowDuration"
              id="claimWindowDuration"
              class="input"
              min="0"
              placeholder="Claim Window Duration"
            />
          </div>

          <div>
            <label for="claimWindowDurationUnit" class="text-sm"
              >Claim Duration Unit</label
            >
            <select
              name=""
              id=""
              v-model="claimWindowDurationUnit"
              class="input"
            >
              <option value="MINUTES">MINUTES</option>
              <option value="HOURS">HOURS</option>
              <option value="HALF_DAYS">HALF DAYS</option>
              <option value="DAYS">DAYS</option>
              <option value="WEEKS">WEEKS</option>
              <option value="MONTHS">MONTHS</option>
              <option value="YEARS">YEARS</option>
              <option value="CENTURIES">CENTURIES</option>
              <option value="DECADES">DECADES</option>
              <option value="ERAS">ERAS</option>
              <option value="FOREVER">FOREVER</option>
              <option value="MICROS">MICROS</option>
              <option value="MILLENNIA">MILLENNIA</option>
              <option value="MILLIS">MILLIS</option>
              <option value="NANOS">NANOS</option>
              <option value="SECONDS">SECONDS</option>
            </select>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-2 mb-3">
          <div>
            <label for="refereeIncentiveType" class="text-sm"
              >Referee Incentive Type</label
            >
            <select name="" id="" v-model="refereeIncentiveType" class="input">
              <option value="FLAT">FLAT</option>
              <option value="PERCENT">PERCENT</option>
            </select>
          </div>
          <div>
            <label for="refereeIncentiveValue" class="text-sm"
              >Referee Incentive Value</label
            >
            <input
              type="number"
              v-model="refereeIncentiveValue"
              name="refereeIncentiveValue"
              id="refereeIncentiveValue"
              class="input"
              min="0"
              placeholder="Incentive Value"
            />
          </div>
        </div>
        <div class="grid grid-cols-2 gap-2 mb-3">
          <div>
            <label for="referrerIncentiveType" class="text-sm"
              >Referrer Incentive Type</label
            >
            <select name="" id="" v-model="referrerIncentiveType" class="input">
              <option value="FLAT">FLAT</option>
              <option value="PERCENT">PERCENT</option>
            </select>
          </div>
          <div>
            <label for="referrerIncentiveValue" class="text-sm"
              >Referrer Incentive Value</label
            >
            <input
              type="number"
              v-model="referrerIncentiveValue"
              name="referrerIncentiveValue"
              id="referrerIncentiveValue"
              class="input"
              placeholder="Referrer Incentive Value"
              min="0"
            />
          </div>
        </div>
        <div class="grid grid-cols-2 gap-2 mb-3">
          <div>
            <label for="minRequiredOrderValue" class="text-sm"
              >Min Required Order</label
            >
            <input
              type="number"
              min="0"
              v-model="minRequiredOrderValue"
              name="minRequiredOrderValue"
              id="minRequiredOrderValue"
              class="input"
              placeholder="Min Required Order"
            />
          </div>
          <div>
            <label for="activationMode" class="text-sm">Activation Mode</label>
            <select name="" id="" v-model="activationMode" class="input">
              <option value="DATE_PERIOD">DATE PERIOD</option>
              <option value="FORCE_ACTIVE">FORCE ACTIVE</option>
              <option value="FORCE_INACTIVE">FORCE INACTIVE</option>
            </select>
          </div>
        </div>
        <div
          class="grid grid-cols-2 gap-2 mb-3"
          v-if="activationMode !== 'FORCE_ACTIVE'"
        >
          <div>
            <label for="claimWindowDuration" class="text-sm">Start Date</label>
            <input
              type="date"
              v-model="startDate"
              name="startDate"
              id="startDate"
              class="input"
              placeholder="Start Date"
            />
          </div>
          <div>
            <label for="claimWindowDuration" class="text-sm">End Date</label>
            <input
              type="date"
              v-model="endDate"
              name="endDate"
              id="endDate"
              class="input"
              placeholder="End Date"
            />
          </div>
        </div>

        <div class="mt-4 flex-grow flex space-x-2 justify-end">
          <button
            @click="toggleModal"
            class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded"
          >
            Close
          </button>
          <button
            type="submit"
            ref="submit"
            @click="addReferral"
            class="bg-brand px-3 text-sm py-2 font-semibold rounded"
          >
            <Preloader v-if="showPreloader" />
            <span v-else>Add Referral Scheme</span>
          </button>
        </div>
      </form>
    </Modal>
  </Admin>
</template>
<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import Admin from "@/components/admin/AdminTemplate.vue";
import Preloader from "@/components/Preloader.vue";
import moment from "moment";

import Modal from "@/components/Modal.vue";
import ReferralsTable from "../../components/admin/Tables/ReferralsTable.vue";
export default {
  name: ["Referral"],
  components: {
    Admin,
    Modal,
    Preloader,
    VueGoogleAutocomplete,
    ReferralsTable,
  },
  data() {
    return {
      showModal: false,
      name: "",
      phone: "",
      requiredNumberOfOrders: "",
      minRequiredOrderValue: "",
      startDate: "",
      endDate: "",
      refereeIncentiveType: "",
      refereeIncentiveValue: "",
      referrerIncentiveValue: "",
      referrerIncentiveType: "",
      activationMode: "",
      claimWindowDuration: "",
      claimWindowDurationUnit: "",
      error: null,
      success: "",
      resturantAddress: "",
      contactPerson: "",
      vendors: null,
      miniTime: 0,
      maxTime: 0,
      filename: "",
      logoname: "",
      vendorId: "",
      states: [],
      stateId: "",
      cityId: "",
      cities: [],
      referralList: [],
      showPreloader: false,
      rows: [
        "Program Name",
        "Incentive Type",
        "Incentive Value",
        "Required no. of order",
        "Minimum order value",
        "Incentive claim duration",
        "Activation mode",
        "Start Date",
        "End Date",
        "Actions",
      ],
      columns: [],
      pageNumber: 0,
      pageSize: 10,
      msg: "Loading ...",
      search: "",
    };
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
      this.error = null;
      this.success = null;
    },
    datewithTime(date) {
      return moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    },
    async addReferral() {
      let url;
      setTimeout(() => {
        this.error = "";
      }, 2000);
      if (!this.name) {
        return (this.error = "Name is required");
      }

      // Check for requiredNumberOfOrders
      if (!this.requiredNumberOfOrders || isNaN(this.requiredNumberOfOrders)) {
        return (this.error =
          "Required Number of Orders must be a valid number");
      }
      // Check for activationMode
      if (!this.activationMode) {
        return (this.error = "Activation Mode is required");
      }
      if (
        !this.claimWindowDurationUnit ||
        isNaN(this.claimWindowDuration) ||
        this.claimWindowDuration <= 0
      ) {
        return (this.error = "Invalid Claim Window Duration");
      }
      if (
        !this.refereeIncentiveType ||
        isNaN(this.refereeIncentiveValue) ||
        this.refereeIncentiveValue < 0
      ) {
        return (this.error = "Invalid Referee Incentive Type or Value");
      }
      if (
        !this.minRequiredOrderValue ||
        isNaN(this.minRequiredOrderValue) ||
        this.minRequiredOrderValue < 0
      ) {
        return (this.error = "Invalid Minimum Required Order Value");
      }
      if (
        !this.referrerIncentiveType ||
        isNaN(this.referrerIncentiveValue) ||
        this.referrerIncentiveValue < 0
      ) {
        return (this.error = "Invalid Referrer Incentive Type or Value");
      } else if (!this.requiredNumberOfOrders) {
        return (this.error = "required number of orders must be submitted ");
      }
      try {
        let token = await this.$store.getters.getToken;
        url =
          this.$store.state.base_url + "user-registration-referral-programs";
        let data = {
          contactPersonName: this.contactPerson,
          requiredNumberOfOrders: this.requiredNumberOfOrders,
          name: this.name,
          activationMode: this.activationMode,
          claimWindowDuration: {
            unit: this.claimWindowDurationUnit,
            value: this.claimWindowDuration,
          },
          refereeIncentiveType: this.refereeIncentiveType,
          refereeIncentiveValue: this.refereeIncentiveValue,
          minRequiredNumberOfOrders: this.minRequiredOrderValue,
          minRequiredOrderValue: this.minRequiredOrderValue,
          referrerIncentiveValue: this.referrerIncentiveValue,
          referrerIncentiveType: this.referrerIncentiveType,
        };
        if (this.startDate) {
          data.startDate = this.datewithTime(this.startDate);
        }
        if (this.endDate) {
          data.endDate = this.datewithTime(this.endDate);
        }

        await this.axios.post(url, data, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        await this.getAllReferrals();
        this.showPreloader = false;
        this.success = "Successfully added referral bonus";
        // this.$refs.submit.disabled = false;
        setTimeout(() => {
          this.showModal = false;
        }, 4000);
      } catch (error) {
        this.showPreloader = false;
        this.error = error?.response?.data?.message ?? "Failed to save";
        console.log(error);
      }
    },

    async getAllReferrals() {
      let url =
        this.$store.state.base_url + "user-registration-referral-programs";
      try {
        let token = await this.$store.getters.getToken;
        let res = await this.axios.get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: {
            searchTerm: this.search,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
          },
        });
        this.columns = res.data;
        this.referralList = res.data.content;
      } catch (error) {
        console.log(error);
      }
    },

    async changePage(data) {
      this.columns.content = [];
      this.msg = "Loading ...";
      this.pageNumber = data.pageNumber;
      this.pageSize = data.pageSize;
      await this.getAllReferrals();
    },
    searchResult(data) {
      this.search = data;
      this.getAllReferrals();
    },
  },
  beforeMount() {
    this.getAllReferrals();
  },
};
</script>

<style>
:root {
  --loader-width: 20px;
  --loader-height: 20px;
  --loader-color-primary: #000000;
  --loader-color-secondary: #eee;
  --line-width: 2px;
  --animation-duration: 2s;
  --loader-initial-scale: 0.1;
}
</style>