<template>
  <div class="flex justify-between mb-4">
    <div class="flex space-x-2">
      <p class="text-sm flex items-center">Rows per page</p>
      <select
        v-model="rowPerpage"
        class="border p-0.5 focus:outline-none"
        name=""
        id=""
      >
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
      </select>
    </div>
    <div class="flex space-x-3 border border-gray-100 p-0.5 px-3">
      <input
        type="text"
        @input="sortList"
        v-model="search"
        placeholder="Search"
        class="focus:outline-none"
      />
      <button>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </button>
    </div>
  </div>
  <table class="w-full">
    <thead class="bg-gray-100 border-gray-100 border">
      <tr class="">
        <th v-for="row in rows" :key="row" class="text-sm py-3 px-3 text-left">
          {{ row }}
        </th>
      </tr>
    </thead>
    <tbody v-if="discountCodes.length < 1">
      <tr class="border border-t-0">
        <td class="text-sm p-3 text-center" :colspan="rows.length">
          No data found
        </td>
      </tr>
    </tbody>
    <tbody>
      <tr
        v-for="col in discountCodes.slice(startPage, Endpage)"
        :key="col"
        class="border border-t-0"
      >
        <td class="text-sm p-3">{{ col.discountCode }}</td>
        <td class="text-sm p-3 capitalize">
          {{ col.rateType ? col.rateType.toLowerCase() : "-" }}
        </td>
        <td class="text-sm p-3">
          &#8358;{{ formatNumber(col.minimumOrderSubTotalCost) }}
        </td>
        <td class="text-sm p-3">
          {{
            col.rateType == "PERCENT"
              ? col.discountValue + "%"
              : "&#8358;" + formatNumber(col.discountValue)
          }}
        </td>
        <td class="text-sm p-3">{{ datewithTime(col.expiryDate) }}</td>
        <td>
          <!-- <button
            @click="togglePublish(col.isActive, col.discountId)"
            :data-name="'discount' + col.discountId"
            :class="col.isActive ? 'bg-green-400' : null"
            class="border border-gray-200 bg-gray-200 h-5 w-10 flex items-center rounded-full"
          >
            <div
              :class="col.isActive ? 'translate-x-5' : null"
              class="h-4 w-4 flex bg-white rounded-full transition duration-500 transform"
            ></div>
          </button> -->
          <span v-if="col.status == 'ACTIVE'" class="text-xs">Active</span>
          <span v-else class="text-xs">{{ col.status }}</span>
        </td>
        <td>
          <button @click="editDiscountCode(col.discountId)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
              />
            </svg>
          </button>
          <button @click="deleteCode(col.discountId)" class="text-red-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="flex justify-between bg-gray-100 h-12 px-4 items-center">
    <div>
      {{ currentPage }} of {{ numberofPages }} pages (Showing
      {{ getFirstItem }} - {{ getLastItem }} of
      {{ discountCodes.length }} Entries)
    </div>
    <div class="flex flex-grow justify-end">
      <div class="flex space-x-1">
        <button
          @click="prev(currentPage)"
          class="pagination-btn active text-sm"
        >
          Prev
        </button>
        <div class="border border-gray-300 px-3 py-0.5">{{ currentPage }}</div>
        <button
          @click="next(currentPage)"
          class="pagination-btn active text-sm"
        >
          Next
        </button>
      </div>
    </div>
  </div>
  <Modal width="w-500" v-if="showeditModal" :show="true">
    <div class="flex justify-between">
      <h1 class="text-xl font-semibold">Edit Discount Code</h1>
      <div @click="toggleeditModal">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>
    <div v-if="error" class="text-sm text-red-500 mt-3">{{ error }}</div>
    <form @submit.prevent="updateDiscountCode" class="mt-3">
      <div class="grid grid-cols-2 gap-2 mb-3">
        <div class="">
          <label for="discountType" class="text-sm">Discount code</label>
          <input
            type="text"
            v-model="editCode.discountCode"
            class="input"
            placeholder="Discount Code"
          />
        </div>
        <div>
          <label for="discounttype" class="text-sm">Select Discount type</label>
          <select v-model="discountType" class="input">
            <option value="">Select Discount type</option>
            <option value="FLAT">Flat Rate Discount</option>
            <option value="PERCENT">Percentage Discount</option>
          </select>
        </div>
      </div>
      <div class="my-3" v-if="discountType == 'FLAT'">
        <label for="amountDiscounted" class="text-sm"
          >Flat Amount Discounted</label
        >
        <input
          type="number"
          v-model="editCode.discountValue"
          class="input"
          placeholder="Flat Amount Discounted"
        />
      </div>
      <div class="my-3" v-else-if="discountType == 'PERCENT'">
        <label for="percentageDiscounted" class="text-sm"
          >Percentage Discounted</label
        >
        <input
          type="number"
          min="1"
          max="100"
          v-model="editCode.discountValue"
          class="input"
          placeholder="Percentage Discounted"
        />
      </div>
      <div class="grid grid-cols-2 gap-2 mb-3">
        <div>
          <label for="amountDiscounted" class="text-sm">Max Amount </label>
          <input
            type="number"
            v-model="editCode.discountLimit"
            class="input"
            placeholder="Max Amount"
          />
        </div>
        <div class="">
          <label for="percentageDiscounted" class="text-sm"
            >Number Of Codes</label
          >
          <input
            type="number"
            v-model="editCode.noOfCodes"
            class="input"
            placeholder="Number Of Codes"
          />
        </div>
      </div>
      <div class="grid grid-cols-2 gap-2 mb-3">
        <div class="">
          <label for="subtotal" class="text-sm">Minimun Subtotal cost</label>
          <input
            type="number"
            v-model="editCode.minimumOrderSubTotalCost"
            class="input"
            placeholder="Minimun Subtotal cost"
          />
        </div>
        <div class="">
          <label for="validityPeriod" class="text-sm">Expiry Date</label>
          <input
            type="text"
            :value="datewithTime(editCode.expiryDate)"
            class="input"
            placeholder=""
            disabled
          />
        </div>
      </div>
      <div class="mt-4 flex-grow flex space-x-2 justify-end">
        <button
          @click="toggleeditModal"
          class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded"
        >
          Close
        </button>
        <button
          type="submit"
          :disable="showPreloader"
          ref="submit"
          class="bg-brand px-3 text-sm py-2 font-semibold rounded"
        >
          <Preloader v-if="showPreloader" />
          <span v-else>Edit</span>
        </button>
      </div>
    </form>
  </Modal>
  <Modal v-if="deleteModal" :show="true">
    <h1 class="text-2xl font-semibold">Are you sure</h1>
    <p class="py-2">
      Do you really want to delete this record? This action cannot be undone
    </p>
    <div class="mt-4 flex-grow flex space-x-2 justify-end">
      <button
        @click="toogledelete"
        class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded"
      >
        No
      </button>
      <button
        @click="deleteDiscountCode"
        type="submit"
        ref="submit"
        class="bg-brand px-3 text-sm py-2 font-semibold rounded"
      >
        <Preloader v-if="showPreloader" />
        <span v-else>Yes</span>
      </button>
    </div>
  </Modal>
</template>

<script>
import axios from "axios";
import Preloader from "@/components/Preloader.vue";
import Modal from "@/components/Modal.vue";
import moment from "moment";

export default {
  name: "DiscountTable",
  props: ["rows", "columns"],
  components: {
    Modal,
    Preloader,
  },
  data() {
    return {
      search: "",
      rowPerpage: 10,
      discountCodes: [],
      startPage: 0,
      Endpage: 10,
      currentPage: 1,
      numberofPages: 0,
      showPreloader: false,
      showeditModal: false,
      deleteModal: false,
      error: "",
      editCode: {},
      discountType: "",
    };
  },
  watch: {
    columns: function () {
      this.discountCodes = this.columns;
      this.numberofPages = Math.ceil(this.columns.length / this.rowPerpage);
    },
    rowPerpage: function () {
      this.numberofPages = Math.ceil(this.columns.length / this.rowPerpage);
      this.Endpage = this.rowPerpage;
      this.startPage = 0;
      this.currentPage = 1;
    },
  },
  computed: {
    getFirstItem() {
      return (
        this.discountCodes.findIndex(
          (item) =>
            item.discountCode ==
            this.discountCodes.slice(this.startPage, this.Endpage)[0]
              .discountCode
        ) + 1
      );
    },
    getLastItem() {
      return (
        this.discountCodes.findIndex(
          (item) =>
            item.discountCode ==
            this.discountCodes.slice(this.startPage, this.Endpage)[
              this.discountCodes.slice(this.startPage, this.Endpage).length - 1
            ].discountCode
        ) + 1
      );
    },
  },
  methods: {
    toggleeditModal() {
      this.showeditModal = !this.showeditModal;
    },
    toogledelete() {
      this.deleteModal = !this.deleteModal;
    },
    prev(currentPage) {
      let prev = currentPage - 1;
      if (prev) {
        this.currentPage = prev;
        this.startPage = (prev - 1) * this.rowPerpage;
        this.Endpage = prev * this.rowPerpage;
      }
    },
    next(currentPage) {
      let next = currentPage + 1;
      if (next <= this.numberofPages) {
        this.currentPage = next;
        this.startPage = (next - 1) * this.rowPerpage;
        this.Endpage = next * this.rowPerpage;
      }
    },
    formatNumber(num) {
      if (num) {
        return num?.toString()?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      }
    },
    datewithTime(date) {
      return moment(date).format("lll");
    },
    editDiscountCode(code) {
      this.editCode = this.discountCodes.find(
        (item) => item.discountId === code
      );
      this.discountType =
        this.editCode.discountType == "FLAT" ? "FLAT" : "PERCENT";
      this.toggleeditModal();
    },
    async updateDiscountCode() {
      if (!this.editCode.discountCode) {
        this.error = "Discount code is required";
      } else if (!this.discountType) {
        this.error = "Discount type is required";
      } else if (
        this.discountType == "flat" &&
        !this.editCode.flatAmountDiscounted
      ) {
        this.error = "Amount discounted field is required";
      } else if (
        this.discountType == "percent" &&
        !this.editCode.percentageDiscounted
      ) {
        this.error = "Percentage discounted field is required";
      } else if (!this.editCode.discountLimit) {
        this.error = "Max amount is required";
      } else if (!this.editCode.noOfCodes) {
        this.error = "Number of code field is required";
      }
      //  else if (!this.editCode.validityPeriod) {
      //   this.error = "Vaildity period field is required";
      // }
       else if (!this.editCode.minimumOrderSubTotalCost) {
        this.error = "Minimum subtotal field is required";
      } else {
        this.showPreloader = true;
        let data = {
          discountType: this.discountType,
          discountDescription: "string",
          discountCode: this.editCode.discountCode,
          minimumOrderSubTotalCost: this.editCode.minimumOrderSubTotalCost,
          discountValue: this.editCode.discountValue,
          discountLimit: parseInt(this.editCode.discountLimit),
          noOfCodes: parseInt(this.editCode.noOfCodes),
          validityPeriod: parseInt(this.editCode.validityPeriod),
        };
        let url =
          this.$store.state.base_url +
          "discount/update/" +
          this.editCode.discountId;
        try {
          let token = await this.$store.getters.getToken;
          let res = await axios.put(url, data, {
            headers: {
              Authorization: "Bearer " + token,
            },
          });
          this.showPreloader = false;
          this.discountCodes.map((item) => {
            if (item.discountCode === this.editCode.discountCode) {
              item = this.editCode;
            }
          });
          this.toggleeditModal();
        } catch (error) {
          this.showPreloader = false;
        }
      }
    },
    deleteCode(id) {
      this.deleteid = id;
      this.toogledelete();
    },
    async deleteDiscountCode() {
      let url = this.$store.state.base_url + "discount/delete/" + this.deleteid;
      let token = await this.$store.getters.getToken;
      let res = await axios.delete(url, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      this.discountCodes = this.discountCodes.filter(
        (item) => item.discountId !== this.deleteid
      );
      this.toogledelete();
    },
    async togglePublish(published, id) {
      let toogle = document.querySelector(`[data-name='discount${id}']`);
      let span = document.querySelector(
        `[data-name='discount${id}']`
      ).nextSibling;
      if (published) {
        //Unpublish
        toogle.classList.remove("bg-green-400");
        toogle.classList.add("bg-gray-300");
        toogle.lastElementChild.classList.remove("translate-x-5");
        //span.innerText = "Inactive"
        this.discountCodes.map((item) => {
          if (item.discountId == id) {
            item.isActive = false;
          }
        });
      } else {
        //publish
        toogle.classList.remove("bg-gray-300");
        toogle.classList.add("bg-green-400");
        toogle.lastElementChild.classList.add("translate-x-5");
        span.innerText = "Active";
        this.discountCodes.map((item) => {
          if (item.discountId == id) {
            item.isActive = true;
          }
        });
      }
      let url =
        this.$store.state.base_url + "discount/" + id + "/change-status";
      let token = await this.$store.getters.getToken;
      axios.post(url, null, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
  },
};
</script>
