<template>
    <div class="lg:flex lg:justify-between mb-4 mt-8" >
        <div class="flex justify-center space-x-2">
            <p class="text-sm flex items-center">Rows per page</p>
            <select v-model="rowPerpage"  class="border p-2 focus:outline-none " name="" id="">
                <option value="10">10</option>
                <option value="25" >25</option>
                <option value="50" >50</option>
            </select>
        </div>
        <div class="flex justify-between space-x-3 border border-gray-100 mt-4 lg:mt-0 p-2 px-3">
            <input type="text"  @input="sortList" v-model="search"  placeholder="Search" class="focus:outline-none" >
            <button>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
            </button>
        </div>
    </div>
    <div class="overflow-x-auto" >
        <table class="w-full mt-8">
            <thead class="bg-gray-100 border-gray-100 border">
                <tr class="">
                    <th v-for="row in rows" :key="row" class="text-sm py-3 px-3 text-left" >{{ row }}</th>
                </tr>
            </thead>
            <tbody v-if="banksDetails.length < 1">
                <tr class="border border-t-0" >
                    <td class="text-sm p-3 text-center" :colspan="rows.length">{{ msg }}</td>  
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-for="col in banksDetails.slice(startPage, Endpage)" :key="col" class="border border-t-0">
                    <td class="text-sm p-3" >{{ col.accountName }} </td>
                    <td class="text-sm p-3">{{ col.accountNumber }} </td>
                    <td class="text-sm p-3">{{ col.bankName }} </td>
                    <td class="text-center">
                        <button @click="deleteBank(col.id)" class="text-red-500" >
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                        </button> 
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
     <div class="lg:flex lg:justify-between lg:space-y-0 text-sm space-y-3 bg-gray-100 h-12 p-4 mb-8 items-center " >
       <div> {{ currentPage }} of {{ numberofPages }} pages (Showing {{ getFirstItem }} - {{ getLastItem }} of {{ banksDetails.length }} Entries)</div>
        <div class="flex flex-grow justify-center items-center lg:justify-end" >
            <div class="flex space-x-1">
                <button @click="prev(currentPage)" class="pagination-btn active text-sm">Prev</button>
                <div  class="border border-gray-300  px-3 " > {{ currentPage }} </div>
                <button @click="next(currentPage)" class="pagination-btn active text-sm">Next</button>
            </div>
        </div>
    </div>
    <Modal v-if="showDeleteModal" width="w-500" :show="true">
       <h1 class="text-2xl font-semibold">Are you sure</h1> 
       <p class="py-2" >Do you really want to delete this record? This action cannot be undone</p>
        <div class="mt-4 flex-grow flex space-x-2 justify-end">
            <button @click="toogleDelete" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                No
            </button>
            <button @click="deleteMealId"  type="submit" ref="submit" class="bg-brand px-3 text-sm py-2 font-semibold rounded">
                <Preloader v-if="showDeletePreloader"/>
                <span v-else>Yes</span>      
            </button>
        </div>
    </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import Preloader from '@/components/Preloader.vue';
export default {
    name:"BankAccountTable",
    props:['rows','columns','msg'],
    components:{
        Modal,Preloader
    },
    data(){
        return{
            rowPerpage:10,
            search:"",
            startPage:0,
            Endpage:10,
            showDeleteModal:false,
            showDeletePreloader:false,
            currentPage:1,
            numberofPages:0,
        }
    },
    computed:{
        banksDetails(){
            return this.columns;
        },
        getFirstItem(){
            return this.banksDetails.findIndex((item)=> item.id == this.banksDetails.slice(this.startPage, this.Endpage)[0].id ) + 1
        },
        getLastItem(){
            return this.banksDetails.findIndex((item)=> item.id == this.banksDetails.slice(this.startPage, this.Endpage)[this.banksDetails.slice(this.startPage, this.Endpage).length - 1].id ) + 1
        }
    },
    methods:{
        async togglePublish(published,id){
            let toogle = document.querySelector(`[data-name='bank${id}']`);
            let span = document.querySelector(`[data-name='bank${id}']`).nextSibling;
            if (published) {
                
            }else{
                //publish
                toogle.classList.remove('bg-gray-300');
                toogle.classList.add('bg-green-400');
                toogle.lastElementChild.classList.add('translate-x-5')
                span.innerText = "Default" 
                this.banksDetails.map(item =>{
                   if (item.id == id){
                       item.isDefault = true;
                   }else{
                        item.isDefault = false;
                   }
                })
                let url = this.$store.state.base_url + 'vendor/bank-account-details/default/' + parseInt(id);
                let token = await this.$store.getters.getToken
                this.this.axios.post(url,null,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
            }
           
        },
        toogleDelete(){
            this.showDeleteModal = !this.showDeleteModal
        },
        deleteBank(id){
            this.toogleDelete();
            this.deleteId = id
        },
        async deleteMealId(){
            this.showDeletePreloader = true;
            let url = this.$store.state.base_url + 'admin/dellyman/bank-account/'+ this.deleteId;
            try {
                let token = await  this.$store.getters.getToken
                await this.this.axios.delete(url,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                });
                this.showDeletePreloader = false;
                this.toogleDelete();
                this.banksDetails.filter( item => item.id != this.deleteId);
            } catch (error) {
                this.showDeletePreloader = false;
            }
            
        }
    }

}
</script>

<style>

</style>