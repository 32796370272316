<template>
  <div class="my-8">
    <div class="flex justify-between mb-4">
      <div class="flex space-x-2">
        <p class="flex items-center text-sm">Rows per page</p>
        <select
          v-model="rowPerpage"
          class="border p-0.5 focus:outline-none"
          name=""
          id=""
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
        </select>
      </div>
      <div class="flex space-x-3 border border-gray-100 p-0.5 px-3">
        <input
          type="text"
          @input="sortList"
          v-model="search"
          placeholder="Search"
          class="focus:outline-none"
        />
        <button>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-4 h-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
  <table class="w-full">
    <thead class="bg-gray-100 border border-gray-100">
      <tr class="">
        <th v-for="row in rows" :key="row" class="px-3 py-3 text-sm text-left">
          {{ row }}
        </th>
      </tr>
    </thead>
    <tbody v-if="getSettlements.length < 1">
      <tr class="border border-t-0">
        <td class="p-3 text-sm text-center" :colspan="rows.length">
          {{ msg }}
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr
        v-for="col in getSettlements.slice(startPage, Endpage)"
        :key="col"
        class="border border-t-0"
      >
        <td class="p-3 text-sm">{{ col.transactionReference }}</td>
        <td class="p-3 text-sm">NGN{{ formatNumber(col.amount) }}</td>
        <td class="p-3 text-sm">{{ col.narration }}</td>
        <td class="p-3 text-sm">
          <span v-if="col.transactionStatus == 'SUCCESS'">
            <span
              class="px-2 py-1 text-xs text-gray-100 bg-green-500 rounded-full"
            >
              Settled
            </span>
          </span>
          <span v-else>
            <span
              class="px-2 py-1 text-xs text-gray-100 bg-red-500 rounded-full"
            >
              Not Settled
            </span>
          </span>
        </td>
        <td class="p-3 text-sm">{{ changeDate(col.createdAt) }}</td>
        <td class="text-center">
          <button @click="getSettlementsbyID(col.id)" class="text-purple-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              />
            </svg>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="flex items-center justify-between h-12 px-4 bg-gray-100">
    <div>
      {{ currentPage }} of {{ numberofPages }} pages (Showing
      {{ getFirstItem }} - {{ getLastItem }} of
      {{ getSettlements.length }} Entries)
    </div>
    <div class="flex justify-end flex-grow">
      <div class="flex space-x-1">
        <button
          @click="prev(currentPage)"
          class="text-sm pagination-btn active"
        >
          Prev
        </button>
        <div class="border border-gray-300 px-3 py-0.5">{{ currentPage }}</div>
        <button
          @click="next(currentPage)"
          class="text-sm pagination-btn active"
        >
          Next
        </button>
      </div>
    </div>
  </div>

  <Modal v-if="showSingleSettlemntPopup" width="w-768" :show="true">
    <div class="flex justify-between">
      <h1 class="text-xl font-semibold">View Settlement Details</h1>
      <div @click="tooglesingleSettlement">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-6 h-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>
    <div class="relative my-4">
      <div class="grid grid-rows-1 gap-1 text-sm">
        <div class="flex justify-between">
          <div class="">
            <p>
              <span class="font-semibold">Transaction ID: </span>
              {{ singleSettlement.transactionReference }}
            </p>
            <p>
              <span class="font-semibold">Transaction Date: </span>
              {{ changeDate(singleSettlement.createdAt) }}
            </p>
            <p>
              <span class="font-semibold">Amount Settled: </span>
              NGN{{ formatNumber(singleSettlement.amount) }}
            </p>
            <p>
              <span class="font-semibold">Narration: </span>
              {{ formatNumber(singleSettlement.narration) }}
            </p>
          </div>
          <div class="mr8">
            <p>
              <span class="font-semibold">Account Name: </span>
              {{ singleSettlement.accountName }}
            </p>
            <p>
              <span class="font-semibold">Account Number: </span>
              {{ singleSettlement.accountNumber }}
            </p>
            <p>
              <span class="font-semibold">Bank Name: </span>
              {{ singleSettlement.bankName }}
            </p>
          </div>
        </div>
      </div>
      <div class="absolute top-0 -right-4">
        <span v-if="singleSettlement.transactionStatus == 'SUCCESS'">
          <span
            class="px-2 py-1 text-xs text-gray-100 bg-green-500 rounded-l-full"
          >
            Settled
          </span>
        </span>
        <span v-else>
          <span
            class="px-2 py-1 text-xs text-gray-100 bg-red-500 rounded-l-full"
          >
            Not Settled
          </span>
        </span>
      </div>
    </div>
  </Modal>
</template>

<script>
import moment from "moment";
import Modal from "@/components/Modal.vue";
export default {
  name: "SettlementTable",
  props: ["rows", "columns", "msg"],
  components: {
    Modal,
  },
  data() {
    return {
      rowPerpage: 10,
      search: "",
      startPage: 0,
      Endpage: 10,
      currentPage: 1,
      Settlements: [],
      singleSettlement: null,
      showSingleSettlemntPopup: false,
    };
  },
  computed: {
    getSettlements() {
      this.numberofPages = Math.ceil(this.columns.length / this.rowPerpage);
      this.Settlements = this.columns;
      return this.Settlements;
    },
    getFirstItem() {
      return (
        this.getSettlements.findIndex(
          (item) =>
            item.id ==
            this.getSettlements.slice(this.startPage, this.Endpage)[0].id
        ) + 1
      );
    },
    getLastItem() {
      return (
        this.getSettlements.findIndex(
          (item) =>
            item.id ==
            this.getSettlements.slice(this.startPage, this.Endpage)[
              this.getSettlements.slice(this.startPage, this.Endpage).length - 1
            ].id
        ) + 1
      );
    },
  },
  methods: {
    changeDate(date) {
      return moment(date).format("LLL");
    },
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    prev(currentPage) {
      let prev = currentPage - 1;
      if (prev) {
        this.currentPage = prev;
        this.startPage = (prev - 1) * this.rowPerpage;
        this.Endpage = prev * this.rowPerpage;
      }
    },
    next(currentPage) {
      let next = currentPage + 1;
      if (next <= this.numberofPages) {
        this.currentPage = next;
        this.startPage = (next - 1) * this.rowPerpage;
        this.Endpage = next * this.rowPerpage;
      }
    },
    tooglesingleSettlement() {
      this.showSingleSettlemntPopup = !this.showSingleSettlemntPopup;
    },
    getSettlementsbyID(id) {
      this.singleSettlement = this.getSettlements.find((item) => item.id == id);
      this.tooglesingleSettlement();
    },
  },
};
</script>

<style>
</style>