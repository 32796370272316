<template>
  <div :class="height" class="bg-gray-100 w-full flex justify-end">
      <div class="bg-img hidden md:block flex-grow" :style="{'background-image': 'url(' + require('@/assets/img/bg-auth.jpg') + ')'}" >
          <div class="h-full w-full bg-white-overlay flex items-center justify-center" >
            <img src="@/assets/img/logo.png" class="h-40 object-cover" alt="">
          </div>
      </div>
      <div :class="[width == 'md:w-2/5' ? 'md:w-2/5':'md:w-3/5']" class="w-full bg-img h-full shadow" >
            <div class="bg-white-overlay bg-white-nush w-full h-full flex items-center ">
              <slot></slot>
            </div>  
      </div>
  </div>
</template>

<script>
export default {
  name: "Auth",
  props:['background','height','width']
}
</script>

<style>
.bg-img{
  background-position: center;
  background-size: cover;
}
.bg-white-overlay{
  background: rgba(243, 241, 241, 0.712);
}
</style>