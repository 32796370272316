<template>
  <Admin>
    <div class="flex justify-between">
        <div class="flex" > 
            <div class="h-2 w-2 bg-brand" ></div>  
             <h1 class="font-semibold text-3xl" >Tag Category</h1>
        </div>
        <div class="flex space-x-3 items-center" >
            <button @click="toggleModal" class="p-3 bg-brand rounded flex space-x-2" >
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </div>
                <p class="text-sm" >Add Tag Category</p>
            </button>
        </div>
    </div>
    <div class="mt-8" >
        <Table :rows="rows" @removeTag="removeTag" :page="page" :msg="msg" @changePage="changePage" :columns="columns"/>
    </div>
    <Modal v-if="showModal" width="w-500" :show="true">
        <div class="flex justify-between">
            <h1 class="text-xl font-semibold">Add Tag Category</h1>
            <div @click="toggleModal">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div>
         <div v-if="error" class="text-sm text-red-500 mt-3">{{ error }}</div>
        <form @submit.prevent="addCategory" method="post">
            <div class="mt-2">
                <label for="categoryName" class="text-sm" >Tag category name</label>
                <input type="text" id="categoryName" v-model="categoryName" class="input" placeholder="Category name">
            </div>
            <div class="mt-4">
                <div class="flex">
                    <label for="description" class="text-sm" >Description</label>
                </div>
                <textarea type="text" maxlength="200" v-model="categoryDescription" cols="10" rows="2" class="input" placeholder="Enter Description"></textarea>
            </div>
            <div class="mt-4 flex-grow flex space-x-2 justify-end">
                <button @click="toggleModal" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                    Close
                </button>
                <button type="submit" class="bg-brand px-3 text-sm py-2 font-semibold rounded">
                    <Preloader v-if="showPreloader"/>
                    <span v-else >Add category</span>      
                </button>
            </div>
        </form>

    </Modal>
  </Admin>
</template>

<script>
import Admin from "@/components/admin/AdminTemplate.vue"
import Preloader from "@/components/Preloader.vue";
import Modal from "@/components/Modal.vue";
import Table from "@/components/admin/Tables/MealsCategory.vue";
export default {
    name:"MealsCategory",
    components:{
        Admin,Preloader,Modal,Table
    },
    data(){
        return {
            showModal:false,
            showPreloader:false,
            error:null,
            categoryName:"",
            categoryDescription:"",
            rows:['Category', 'Description','Action'],            
            page:0,
            size:10,
            msg:"Loading ...",
            columns:{
                content:[]
            }
        }
    },
    methods:{
        toggleModal(){
            this.showModal = !this.showModal
            this.categoryName = this.categoryDescription = ""
        },
        async addCategory(){
            if(!this.categoryName){
                return this.error = "Category name field is required"
            }

            if(!this.categoryDescription){
                return this.error = "Category description field is required"
            }

            this.error = null
            
            try{
                this.showPreloader = true
                let url = this.$store.state.base_url + 'product-tag-category';
                let data = {
                    "description":  this.categoryDescription,
                    "name": this.categoryName
                }
                let token = await this.$store.getters.getToken
                let res = await this.axios.post(url,data,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                this.page = 0
                this.size = 10
                await this.getCategory()
                this.showPreloader = false  
                this.toggleModal();
            }catch(error){
                if(error.response.status == 400){
                    this.showPreloader = false  
                    return this.error = error.response.data.message
                }
                console.log(error)
            }

        },
        async getCategory(){
            try{
                let url = this.$store.state.base_url + 'product-tag-category';
                let token = await this.$store.getters.getToken;
                let res = await this.axios.get(url,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    },
                    params:{
                        page:this.page,
                        size:this.size
                    }
                })
                this.columns = res.data
            }catch(error){
                console.log(error)
            }
        },
        async changePage(data){
            this.columns.content = [] 
            this.msg = "Loading ..."
            this.page = data.page
            this.size = data.size
            await this.getCategory()           
        },
        removeTag(data){
            this.getCategory();
        }
    },
    mounted(){
        this.getCategory();
    }

}
</script>

<style>

</style>