<template>
   <Admin>
        <div class="flex justify-between">
            <div class="flex" > 
                <div class="h-2 w-2 bg-brand" ></div>  
                 <h1 class="font-semibold text-3xl" >Meals</h1>
            </div>
            <div class="flex space-x-3 items-center" >
                <button @click="toggleModal" class="p-3 bg-brand rounded flex space-x-2" >
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    <p class="text-sm" >Add Meal</p>
                </button>
            </div>
        </div>
        <div class="mt-8" >
            <Table :rows="rows" :page="page" :msg="msg" @search="searchResult" @changePage="changePage" :timebelts="timebelts" :columns="columns"/>
        </div>
        <Modal v-if="showModal" width="w-768" :show="true">
            <div class="flex justify-between">
                <h1 class="text-xl font-semibold">Add Meals</h1>
                <div @click="toggleModal">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
            </div>
             <div v-if="error" class="text-sm text-red-500 mt-3">{{ error }}</div>
            <form @submit.prevent="addMeal" method="post">
                <div class="grid grid-cols-2 gap-2 mb-3">
                    <div>
                        <label for="mealname" class="text-sm" >Meal name</label>
                        <input type="text" v-model="mealName" class="input" placeholder="Meal name">
                    </div>
                    <div>
                        <label for="price" class="text-sm" >Price</label>
                        <input type="number" v-model="price" class="input" placeholder="Price">
                    </div>
                </div>
                <div class="col-span-2" >
                    <label for="restaurant" class="text-sm" >Select Restaurant</label>
                    <select v-model="RestaurantId" class="input" >
                        <option value="">Select Restaurant</option>
                        <option v-for="restaurant in Restaurants" :key="restaurant" :value="restaurant.restaurantId" >{{ restaurant.name }}</option>
                    </select>
                </div>
                <div class="mt-4">
                    <div class="flex">
                        <label for="description" class="text-sm" >Description</label>
                        <span class="text-sm flex-grow flex justify-end" >{{ description.length }}/200</span>
                    </div>
                    <textarea type="text" maxlength="200" v-model="description" cols="10" rows="2" class="input" placeholder="Enter Description"></textarea>
                </div>
                <div>
                    <label for="" class="text-sm" >Select timebelt</label>
                    <div class="flex space-x-4" >
                       <div v-for="timebelt in timebelts" :key="timebelt">
                            <input type="checkbox"  v-model="sendingTimebelt" :value="timebelt.id">
                             {{ timebelt.name }}
                        </div>
                    </div>
                </div>
                <div v-if="sendingTimebelt.includes(5)" >
                    <label for="preparationTime" class="text-sm" >Time(Mins)</label>
                    <input type="text" min="1" v-model="preparationTime" class="input" placeholder="Time">
                </div>
                <div class="mt-4" >
                    <label class="flex space-x-2" >
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                        </svg>
                        <p>Upload file</p>
                        <input type="file" ref="headerImg" @input="previewImg" class="hidden" name="file" id="file">
                    </label>
                </div>
                <div ref="imgHolder" class="h-24 w-24 bg-gray-100 rounded-sm hidden" >
                    <img src="#" ref="preview" class="h-24 w-24 rounded-md object-cover" alt="">
                </div>
                <div class="mt-4 flex-grow flex space-x-2 justify-end">
                    <button @click="toggleModal" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                        Close
                    </button>
                    <button type="submit" ref="submit" class="bg-brand px-3 text-sm py-2 font-semibold rounded">
                        <Preloader v-if="showPreloader"/>
                        <span v-else >Add meal</span>      
                    </button>
                </div>
            </form>

        </Modal>
   </Admin>
</template>

<script>
import Admin from "@/components/admin/AdminTemplate.vue";
import Table from "@/components/admin/Tables/MealsTable.vue";
import Modal from "@/components/Modal.vue";
import Preloader from "@/components/Preloader.vue";

export default {
    name:['Menus'],
    components:{
        Admin,Table,Modal,Preloader
    },
    data(){
        return{
            mealName:"",
            price:"",
            description:"",
            RestaurantId:"",
            preparationTime:"",
            sendingTimebelt:[],
            error:"",
            Restaurants:[],
            timebelts:[],
            showModal:false,
            showPreloader:false,
            rows:["Meal","Restaurant",'Price','Food Category','Date Added','Status','Actions'],
            columns:[],
            page:0,
            size:10,
            msg:"Loading ...",
            search:""
        }
    },
    methods:{
        toggleModal(){
            this.showModal = !this.showModal
            this.error =null;   
            this.mealName = this.price = this.RestaurantId = this.description = "";
            this.sendingTimebelt =[]     
        },
        previewImg(event){
            let type = ["image/jpeg", "image/png", "image/gif"]
            if (type.find( (item) => item === event.target.files[0].type ) ) {
                this.$refs.imgHolder.classList.remove('hidden')
                this.$refs.preview.src = URL.createObjectURL(event.target.files[0])
                this.error = null;
            }else{
                this.$refs.imgHolder.classList.add('hidden')
                this.error = "Invaild file format"
            }
           
        },
        async addMeal(){
            this.error = (!this.mealName || !this.RestaurantId || !this.price || !this.description || this.sendingTimebelt.length < 1 || !this.$refs.headerImg.files[0].name) ? "Invaild details in the form" : null
            //Uploading fist            
            if (!this.error) {
                this.showPreloader = true;
                this.$refs.submit.disabled = true;
                let formData = new FormData();
                let file = this.$refs.headerImg.files[0];
                formData.append("file", file);
                let url = this.$store.state.base_url + 'file/upload/';
                let token = await this.$store.getters.getToken;
                let res = await this.axios.post(url,formData,{
                    headers: {
                        Authorization: 'Bearer ' + token,
                        'Content-Type': 'multipart/form-data'
                    }
                })
                let image_url = res.data;
                let data = {
                    "amount": parseInt(this.price),
                    "available": true,
                    "description": this.description,
                    "foodCategoriesId": [],
                    "foodImages": [
                        image_url
                    ],
                    "name": this.mealName,
                    "preparationTime": this.preparationTime,
                    "restaurantId": this.RestaurantId,
                    "timeBeltsId": this.sendingTimebelt
                }
                try{
                    url = this.$store.state.base_url + 'vendor/food';
                    let token = await this.$store.getters.getToken
                    res = await this.axios.post(url,data,{
                        headers: {
                            Authorization: 'Bearer ' + token
                        }
                    })
                    await this.getAllMeals();
                    this.showPreloader = false;
                    this.$refs.submit.disabled = false;
                    this.toggleModal();
                }catch(error){
                    this.$refs.submit.disabled = false;
                    this.showPreloader = false;
                    this.error = error.response.data.message
                }
            }
        },
        async getAllResturants(){
            try{
                let url = this.$store.state.base_url + 'admin/all-restaurant';
                let token = await this.$store.getters.getToken
                let res = await this.axios.get(url,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                this.Restaurants = res.data;
            }catch(error){
                console.log(error)
            }
        },
        async getAllMeals(){
            let url = this.$store.state.base_url + 'food';
            try{
                let token = await this.$store.getters.getToken
                let res = await this.axios.get(url,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    },
                    params:{
                        searchTerm:this.search,
                        page:this.page,
                        size:this.size
                    }
                })
                this.columns = res.data
                if(!this.columns.content.length){
                    this.msg = "No data found"
                }
            }catch(error){
                console.log(error)
            }
        },
        async getAllTimebelt(){
            let url = this.$store.state.base_url + 'time-belt';
            try {
                let token = await this.$store.getters.getToken
                let res = this.axios.get(url,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                this.timebelts = res.data
            } catch (error) {
                console.log(err);
            }
        },
        async changePage(data){
            this.columns.content = [] 
            this.msg = "Loading ..."
            this.page = data.page
            this.size = data.size
            await this.getAllMeals()           
        },
        searchResult(data){
            this.search = data
            this.getAllMeals();
        }
    },
    beforeMount(){
        this.getAllMeals();
        this.getAllResturants();
        this.getAllTimebelt();
    },
}
</script>

<style>
:root{
	--loader-width: 20px;
	--loader-height: 20px;
	--loader-color-primary: #000000;
	--loader-color-secondary: #eee;
	--line-width: 2px;
	--animation-duration: 2s;
	--loader-initial-scale: 0.1;
}
</style>