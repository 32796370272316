<template>
    <Auth>
        <div class="md:mx-14 mx-3 w-screen h-screen   flex items-center">
            <div class="flex-grow lg:flex-grow-0">
                <div v-if="success">
                    <h1 class="font-semibold text-4xl" >Thank you for confirming your account</h1>
                    <p class="mt-4 text-sm">Your email has been verified. <router-link :to="{name:'Login'}" class="text-brand">Click here</router-link> to Sign in</p>
                </div>
                <div v-else>
                    <p class="mt-4 text-sm">Activating your account ...</p>
                </div>  
            </div>        
        </div>
    </Auth>
</template>
<script>
import Auth from "@/components/admin/Auth.vue";

import Preloader from "@/components/Preloader.vue";
export default {
    name:['Login'],
    components:{
        Auth,Preloader
    },
    data(){
        return {
            token:(this.$route.query.token) ? this.$route.query.token : null,
            success:false
        }
    },
    methods:{
        async activateAccount(){
            if(this.token){
                let url = this.$store.state.base_url + 'activate-account';
                await this.axios.post(url,null,{
                    params:{
                        token:this.token
                    }
                })
                this.success = true;
            }           
        }
    },
    created(){
        this.activateAccount()
    }
 
}
</script>

<style>
:root{
--loader-width: 20px;
--loader-height: 20px;
--loader-color-primary: #000000;
--loader-color-secondary: #eee;
--line-width: 2px;
--animation-duration: 2s;
--loader-initial-scale: 0.1;
}
</style>