<template>
  <Admin>
    <div class="mb-6">
      <div class="flex justify-between">
        <div class="flex">
          <div class="w-2 h-2 bg-brand"></div>
          <h1 class="text-3xl font-semibold">Settings</h1>
        </div>
      </div>
      <div class="my-8">
        <div>
          <h1 class="text-xl font-semibold">Set Geo-fencing</h1>
          <form @submit.prevent="setKilometerRad" class="mt-4">
            <label class="text-sm">Set Kilometer Radius(KM)</label>
            <p v-if="error.kilometer" class="text-sm text-red-500">
              {{ error.kilometer }}
            </p>
            <p v-if="success.kilometer" class="text-sm text-green-500">
              {{ success.kilometer }}
            </p>
            <div class="flex space-x-2 w-36">
              <input
                class="input"
                type="number"
                mini="1"
                v-model="kilometer"
                placeholder="KM"
              />
              <button class="px-3 mt-2 text-sm rounded bg-brand">Set</button>
            </div>
          </form>
        </div>
      </div>
      <div class="my-8">
        <div>
          <h1 class="text-xl font-semibold">Set Minimum order cost</h1>
          <form @submit.prevent="setMinimumOrderCost" class="mt-4">
            <label class="text-sm">Set Minimum order cost</label>
            <p v-if="error.minimumOrderCost" class="text-sm text-red-500">
              {{ error.minimumOrderCost }}
            </p>
            <p v-if="success.minimumOrderCost" class="text-sm text-green-500">
              {{ success.minimumOrderCost }}
            </p>
            <div class="flex space-x-2 w-36">
              <input
                class="input"
                type="number"
                mini="1"
                v-model="minimumOrderCost"
                placeholder="Minimum order cost"
              />
              <button class="px-3 mt-2 text-sm rounded bg-brand">Set</button>
            </div>
          </form>
        </div>
      </div>
      <div class="my-8">
        <div>
          <h1 class="text-xl font-semibold">Set Delivery Cost</h1>
          <form @submit.prevent="setDeliveryCost" class="mt-4">
            <p v-if="error.deliveryCost" class="text-sm text-red-500">
              {{ error.deliveryCost }}
            </p>
            <p v-if="success.deliveryCost" class="text-sm text-green-500">
              {{ success.deliveryCost }}
            </p>
            <div class="flex items-center space-x-2">
              <div class="">
                <label for="">Base Distance (KM)</label> <br />
                <input
                  class="input"
                  type="number"
                  v-model="baseDistanceInKilometer"
                  min="0"
                  placeholder="baseDistanceInKilometer"
                />
              </div>
              <div class="">
                <label for="">Base Fare (N)</label> <br />
                <input
                  class="input"
                  type="number"
                  v-model="baseFare"
                  min="0"
                  placeholder="baseFare"
                />
              </div>
              <div class="">
                <label for="">Rate/Additional Km (N)</label> <br />
                <input
                  class="input"
                  type="number"
                  v-model="ratePerAdditionalKilometer"
                  min="0"
                  placeholder="ratePerAdditionalKilometer"
                />
              </div>
              <button
                type="submit"
                class="mt-8 px-3 bg-brand rounded text-sm w-[200px] h-12"
              >
                Set
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="my-8">
        <div>
          <h1 class="text-xl font-semibold">Set Delivery Window</h1>
          <form @submit.prevent="setDeliveryWindow" class="mt-4">
            <div v-if="error.window" class="my-4 text-sm text-red-500">
              {{ error.window }}
            </div>
            <div v-if="success.window" class="my-4 text-sm text-green-500">
              {{ success.window }}
            </div>
            <label class="text-sm"></label>
            <div class="flex space-x-2 w-36">
              <input
                class="input"
                type="number"
                max="30"
                min="10"
                v-model="window"
                placeholder="KM"
              />
              <button class="px-3 mt-2 text-sm rounded bg-brand">Set</button>
            </div>
          </form>
        </div>
      </div>
      <div class="my-8">
        <div>
          <h1 class="text-xl font-semibold">Set Commission</h1>
          <form @submit.prevent="setCommision" class="mt-4">
            <label class="text-sm">Set Commission</label>
            <p v-if="error.commission" class="text-sm text-red-500">
              {{ error.commission }}
            </p>
            <p v-if="success.commission" class="text-sm text-green-500">
              {{ success.commission }}
            </p>
            <div class="flex space-x-2 w-36">
              <input
                class="input"
                type="number"
                mini="1"
                max="50"
                v-model="Commission"
                placeholder="Commission"
              />
              <button type="submit" class="px-3 mt-2 text-sm rounded bg-brand">
                Set
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="my-8">
        <div>
          <h1 class="text-xl font-semibold">Set restaurant settlement</h1>
          <form @submit.prevent="setSettlement" class="mt-4">
            <label class="text-sm">Set restaurant settlement</label>
            <p v-if="error.settlement" class="text-sm text-red-500">
              {{ error.settlement }}
            </p>
            <p v-if="success.settlement" class="text-sm text-green-500">
              {{ success.settlement }}
            </p>
            <div class="my-4 text-sm">
              <input
                v-model="automaticSettlementEnabled"
                type="checkbox"
                name=""
                id=""
              />
              Set Automatic settlement
            </div>
            <div class="flex w-full space-x-2">
              <select
                v-model="hourOfDayForAutomaticSettlement"
                class="w-2/3 input"
              >
                <option value="">Select Hour</option>
                <option v-for="i in 24" :key="i" :value="i - 1">
                  {{ changeTo24format(i - 1) }}
                </option>
              </select>
              <select
                v-model="minuteOfHourForAutomaticSettlement"
                class="w-2/3 input"
              >
                <option value="">Select Hour</option>
                <option v-for="i in 60" :key="i" :value="i - 1">
                  {{ i - 1 }} mins
                </option>
              </select>
              <input
                class="input"
                type="number"
                v-model="minimumSettlementAmount"
                mini="2500"
                placeholder="Minimum settlement"
              />
              <input
                class="input"
                type="number"
                v-model="maximumSettlementAmount"
                mini="2500"
                placeholder="Maxium settlement"
              />
              <input
                class="input"
                type="number"
                v-model="numberOfDaysBeforeCompulsorySettlement"
                mini="2500"
                placeholder="Days intervals"
              />
              <button
                type="submit"
                class="w-2/3 px-3 mt-2 text-sm rounded bg-brand"
              >
                Set
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </Admin>
</template>
<script>
import Admin from "@/components/admin/AdminTemplate.vue";

export default {
  name: "AdminSettings",
  components: {
    Admin,
  },
  data() {
    return {
      kilometer: 0,
      window: 0,
      error: {
        window: null,
        kilometer: null,
        dellyman: null,
        commission: null,
        settlement: null,
        minimumOrderCost: null,
        deliveryCost: null,
      },
      dellyman: {
        email: process.env.VUE_APP_DELLYMAN_EMAIL,
        password: process.env.VUE_APP_DELLYMAN_PASSWORD,
      },
      Commission: 0,
      timebelt: [],
      success: {
        window: null,
        kilometer: null,
        dellyman: null,
        commission: null,
        settlement: null,
        minimumOrderCost: null,
        deliveryCost: null,
      },
      hourOfDayForAutomaticSettlement: "",
      maximumSettlementAmount: "",
      minimumSettlementAmount: "",
      numberOfDaysBeforeCompulsorySettlement: "",
      automaticSettlementEnabled: false,
      minuteOfHourForAutomaticSettlement: "",
      minimumOrderCost: 0,
      baseDistanceInKilometer: 0,
      ratePerAdditionalKilometer: 0,
      baseFare: 0,
    };
  },
  methods: {
    async setKilometerRad() {
      if (!this.kilometer) {
        this.error.kilometer = "Kilometer raduis is required";
      } else {
        this.error.kilometer = null;
        let url = this.$store.state.base_url + "admin/geofence-distance";
        let token = await this.$store.getters.getToken;
        this.axios
          .post(url, null, {
            headers: {
              Authorization: "Bearer " + token,
            },
            params: {
              distance: parseInt(this.kilometer),
            },
          })
          .then((res) => {
            this.success.kilometer = "Successfully set geofencing";
            setTimeout(() => {
              this.success.kilometer = null;
            }, 5000);
          });
      }
    },
    setDeliveryWindow() {
      if (this.window > 10 && this.window < 30) {
        this.error.window = "Set delivery window";
      } else {
        this.getAllTimebelt();
      }
    },
    dellymanApi() {
      if (!this.dellyman.email || !this.dellyman.password) {
        this.error.dellyman = "Email and password fields are required";
      } else {
        const editDotenv = require("edit-dotenv");
        const changes = {
          VUE_APP_DELLYMAN_EMAIL: this.dellyman.email,
          VUE_APP_DELLYMAN_PASSWORD: this.dellyman.password,
        };
        const envString = `VUE_APP_DELLYMAN_EMAIL=${this.dellyman.email} VUE_APP_DELLYMAN_PASSWORD=${this.dellyman.password}`;
        console.log(envString);
        editDotenv(envString, changes);

        let dellymanUrl = this.$store.state.dellyman_base_url + "Login";
        let data = {
          Email: this.dellyman.email,
          Password: this.dellyman.password,
        };
        this.axios
          .post(dellymanUrl, data, {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          })
          .then(function (response) {
            console.log(JSON.stringify(response.data));
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    async getAllTimebelt(edit) {
      let url = this.$store.state.base_url + "time-belt";
      let token = await this.$store.getters.getToken;
      this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          this.timebelts = res.data;
          if (edit) {
            this.window = this.timebelts[0].deliveryWindow;
          } else {
            this.updateTB();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getDeliveryFee() {
      let url =
        this.$store.state.base_url +
        "admin/configs/delivery-fee-billing-template";
      let token = await this.$store.getters.getToken;
      this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          this.baseDistanceInKilometer = res.data.baseDistanceInKilometer;
          this.ratePerAdditionalKilometer = res.data.ratePerAdditionalKilometer;
          this.baseFare = res.data.baseFare;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async updateTB() {
      this.timebelts.map(async (timebelt) => {
        timebelt.deliveryWindow = this.window;
        let url = this.$store.state.base_url + `time-belt/edit/${timebelt.id}`;
        let token = await this.$store.getters.getToken;
        this.axios.put(url, timebelt, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
      });
      this.success.window = "Successfully set delivery window";
      setTimeout(() => {
        this.success.window = null;
      }, 5000);
    },
    async getGeoFence() {
      let url = this.$store.state.base_url + `admin/geofence-distance`;
      let token = await this.$store.getters.getToken;
      this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          this.kilometer = res.data;
        });
    },
    async setCommision() {
      if (!this.Commission) {
        this.error.commission = "Commission field is required";
      } else {
        let url = this.$store.state.base_url + `admin/restaurant/commission`;
        let token = await this.$store.getters.getToken;
        await this.axios.post(url, null, {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: {
            newCommission: this.Commission,
          },
        });
        this.success.commission = "Successfully set commission";

        setTimeout(() => {
          this.success.commission = null;
        }, 2000);
      }
    },
    async getCommission() {
      let url = this.$store.state.base_url + `admin/restaurant/commission`;
      let token = await this.$store.getters.getToken;
      let res = await this.axios.get(url, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      this.Commission = res.data;
    },
    async getCommission() {
      let url =
        this.$store.state.base_url + `admin/restaurant-settlement-settings`;
      let token = await this.$store.getters.getToken;
      let res = await this.axios.get(url, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      this.Commission = res.data;
      this.hourOfDayForAutomaticSettlement =
        this.Commission.hourOfDayForAutomaticSettlement;
      this.maximumSettlementAmount = this.Commission.maximumSettlementAmount;
      this.minimumSettlementAmount = this.Commission.minimumSettlementAmount;
      this.numberOfDaysBeforeCompulsorySettlement =
        this.Commission.numberOfDaysBeforeCompulsorySettlement;
      this.automaticSettlementEnabled =
        this.Commission.automaticSettlementEnabled;
      this.minuteOfHourForAutomaticSettlement =
        this.Commission.minuteOfHourForAutomaticSettlement;
    },
    changeTo24format(number) {
      if (number == 12) {
        return "12 PM";
      } else if (number == 0) {
        return "12 AM";
      } else {
        let time = number > 12 ? number - 12 + " PM" : number + " AM";
        return time;
      }
    },
    async setSettlement() {
      if (this.hourOfDayForAutomaticSettlement == "") {
        this.error.settlement = "Select hour for settlement";
      } else if (!this.maximumSettlementAmount) {
        this.error.settlement = "Enter maximum settlement amount";
      } else if (!this.minimumSettlementAmount) {
        this.error.settlement = "Enter minimum settlement amount";
      } else if (!this.numberOfDaysBeforeCompulsorySettlement) {
        this.error.settlement = "Enter day intervals";
      } else {
        let url =
          this.$store.state.base_url + `admin/restaurant-settlement-settings`;
        let data = {
          automaticSettlementEnabled: this.automaticSettlementEnabled,
          hourOfDayForAutomaticSettlement: this.hourOfDayForAutomaticSettlement,
          maximumSettlementAmount: this.maximumSettlementAmount,
          minimumSettlementAmount: this.minimumSettlementAmount,
          minuteOfHourForAutomaticSettlement:
            this.minuteOfHourForAutomaticSettlement,
          numberOfDaysBeforeCompulsorySettlement:
            this.numberOfDaysBeforeCompulsorySettlement,
        };
        try {
          let token = await this.$store.getters.getToken;
          let res = await this.axios.put(url, data, {
            headers: {
              Authorization: "Bearer " + token,
            },
          });
          this.success.settlement = "Successful set restaurant settlement";

          setTimeout(() => {
            this.success.settlement = "";
          }, 5000);
        } catch (error) {}
      }
    },
    async getMinimumCost() {
      try {
        let url =
          this.$store.state.base_url + `public/configs/minimum-order-amount`;
        let token = await this.$store.getters.getToken;
        let res = await this.axios.get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        this.minimumOrderCost = res.data;
      } catch (error) {
        console.log(error);
      }
    },
    async setMinimumOrderCost() {
      if (!this.minimumOrderCost) {
        this.error.minimumOrderCost = "Minimum order cost is required";
        setTimeout(() => {
          this.error.minimumOrderCost = null;
        }, 5000);
      } else {
        try {
          let url =
            this.$store.state.base_url + `admin/configs/minimum-order-amount`;
          let token = await this.$store.getters.getToken;
          let res = await this.axios.put(url, null, {
            headers: {
              Authorization: "Bearer " + token,
            },
            params: {
              minimumOrderAmount: this.minimumOrderCost,
            },
          });
          this.success.minimumOrderCost = "Successfully set minimum order cost";
          setTimeout(() => {
            this.success.minimumOrderCost = null;
          }, 5000);
        } catch (error) {
          console.log(error);
        }
      }
    },
    async setDeliveryCost() {
      if (
        !this.baseDistanceInKilometer ||
        !this.baseFare ||
        !this.ratePerAdditionalKilometer
      ) {
        this.error.deliveryCost = "All inputs required";
        setTimeout(() => {
          this.error.deliveryCost = null;
        }, 5000);
      } else {
        try {
          const data = {
            baseDistanceInKilometer: Number(this.baseDistanceInKilometer),
            ratePerAdditionalKilometer: Number(this.ratePerAdditionalKilometer),
            baseFare: Number(this.baseFare),
          };
          let url =
            this.$store.state.base_url +
            `admin/configs/delivery-fee-billing-template`;
          let token = await this.$store.getters.getToken;
          let res = await this.axios.put(url, data, {
            headers: {
              Authorization: "Bearer " + token,
            },
          });
          this.success.deliveryCost = "Successfully set delivery cost";
          setTimeout(() => {
            this.success.deliveryCost = null;
          }, 5000);
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
  created() {
    this.getAllTimebelt("placeholder");
    this.getGeoFence();
    this.getCommission();
    this.getMinimumCost();
    this.getDeliveryFee();
  },
};
</script>