<template>
    <div class="relative">
        <input @input="inputText" :placeholder="placeholder" v-model="text" type="text" class="input">
        <div v-if="results.length && showResults" :class="{'h-24': results.length > 3 }" class="absolute left-0 bg-white shadow-md rounded bottom w-full overflow-auto" >
            <div v-for="item in results" :key="item" @click="selectItem(item)" class="p-3 cursor-pointer text-sm hover:bg-gray-100">
                {{ item.name }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"Autocomplete",
    emits:['inputText','onSelect'],
    props:['results','placeholder'],
    data() {
        return {
            text:"",
            showResults:false
        }
    },
    computed:{
        getResult(){
            
        }
    },
    methods:{
        inputText(){
            this.$emit('inputText',this.text)
            this.showResults = true;
        },
        selectItem(item){
            this.$emit('onSelect',item)
            this.text = item.name.trim();
            this.showResults = false;
        }
    }
}
</script>

<style>

</style>