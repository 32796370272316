import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import customAxios from "./axios/index"
import global from "./function"
import moment  from 'moment'
import './index.css'


const app = createApp(App).use(store).use(router)
app.config.globalProperties.axios = customAxios
app.config.globalProperties.moment = moment
app.config.globalProperties.global = global
app.mount('#app')
