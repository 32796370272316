<template>
  <Admin>
    <div class="flex justify-between">
          <div class="flex" > 
              <div class="h-2 w-2 bg-brand" ></div>  
                <h1 class="font-semibold text-3xl" >Restaurants</h1>
          </div>
          <div class="flex space-x-3 items-center" >
             <button @click="toggleModal"  class="p-3 bg-brand rounded flex space-x-2" >
               <div>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
               </div>
               <p class="text-sm" >Create Restaurant</p>
             </button>
          </div>
    </div>
    <div class="mt-8 pb-8" >
      <Table :rows="rows" :columns="columns" :msg="msg" @changePage="changePage" :page="page" :states="states" @search="searchResult"/>
    </div>
     <Modal v-if="showModal" width="w-768"  :show="true">
        <div class="flex justify-between">
            <h1 class="text-xl font-semibold">Add Restaurant</h1>
            <div @click="toggleModal">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div>  
        <div v-if="error" class="text-sm text-red-500 mt-3">{{ error }}</div>
        <form enctype="multipart/form-data" @submit.prevent="addResturant" ref="addVendor" class="mt-3">
            <div class="grid grid-cols-2 gap-2 mb-3">
                <div>
                    <label for="restaurantName" class="text-sm" >Restaurant Name</label>
                    <input type="text" v-model="restaurantName" class="input" placeholder="Restaurant Name">
                </div>
                <div>
                    <label for="email" class="text-sm" >Email Address</label>
                    <input type="email" v-model="email" class="input" placeholder="Email address">
                </div>
            </div>
            <div class="grid grid-cols-2 gap-2 mb-3">
                <div>
                    <label for="phone" class="text-sm" >Phone Number</label>
                    <input type="text" v-model="phone" name="phone" id="phone" class="input" placeholder="Phone Number">
                </div>
                <div>
                    <label for="contactPerson" class="text-sm" >Contact Person</label>
                    <input type="text" v-model="contactPerson" class="input" placeholder="Contact Person">
                </div>
            </div>
           <div class="grid grid-cols-2 gap-2 mb-3"> 
                <div class="mb-3">
                    <label for="address" class="text-sm" >Restaurant Address</label>
                    <vue-google-autocomplete
                        ref="address"
                        id="map"
                        placeholder="Restaurant Address"
                        classname="input" 
                        v-on:placechanged="getAddressData"
                        country="ng"
                        v-model="resturantAddress"
                        autocomplete="off" 
                    ></vue-google-autocomplete> 
                </div>
                <div>
                <label for="vendor" class="text-sm" >Select Vendor</label>
                    <select v-model="vendorId" class="input" >
                        <option value="">Select Vendor</option>
                        <option v-for="vendor in vendors" :key="vendor" :value="vendor.userId">{{ vendor.firstName }} {{ vendor.lastName }} ({{ vendor.email }})</option>
                    </select>
                </div>
            </div>
             <div class="grid grid-cols-4 gap-2 mb-3">
                <div>
                    <label for="shortestTime" class="text-sm" >Earliest Time(mins)</label>
                    <input type="number" v-model="miniTime"  class="input" placeholder="Earliest Time">
                </div>
                <div>
                    <label for="longestTime" class="text-sm" >Latest Time(mins)</label>
                    <input type="number" v-model="maxTime" class="input" placeholder="Latest Time">
                </div>
                <div>
                    <label for="vendor" class="text-sm" >Select State</label>
                    <select @change="findCities()" v-model="stateId" class="input" >
                        <option value="">Select State</option>
                        <option v-for="state in states" :key="state" :value="state.StateID">{{ state.Name }}</option>
                    </select>
                </div>
                <div>
                    <label for="vendor" class="text-sm" >Select City</label>
                    <select v-model="cityId" class="input" >
                        <option value="">Select City</option>
                        <option v-for="city in cities" :key="city" :value="city.CityID">{{ city.Name }}</option>
                    </select>
                </div>
            </div>
            <div class="grid grid-cols-2 gap-2 mt-4" >
                <div>
                    <div class=" flex" >
                        <label class="flex space-x-2" >
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                            </svg>
                            <p>Upload Banner</p>
                            <input type="file" ref="headerImg" accept="image/*" @input="previewImg" class="hidden" name="file" id="file">
                        </label>
                    </div>
                    <div ref="imgHolder" class="h-24 w-24 bg-gray-100 rounded-sm hidden" >
                        <img src="#" ref="preview" class="h-24 w-24 rounded-md object-cover" alt="">
                    </div>
                </div>
                <div>
                    <div class=" flex" >
                        <label class="flex space-x-2" >
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                            </svg>
                            <p>Upload logo</p>
                            <input type="file" ref="headerLogo" accept="image/*" @input="previewLogo" class="hidden" name="logo" id="logo">
                        </label>
                    </div>
                    <div ref="LogoHolder" class="h-24 w-24 bg-gray-100 rounded-sm hidden" >
                        <img src="#" ref="logoPreview" class="h-24 w-24 rounded-md object-cover" alt="">
                    </div>
                </div>
            </div>
            <div class="flex space-x-2 items-center my-2">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </div>
                <div class="text-sm">File upload size should not exceed 2MB</div>
            </div>
            
           
            <div class="mt-4 flex-grow flex space-x-2 justify-end">
              <button @click="toggleModal" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                Close
              </button>
                <button type="submit" ref="submit" class="bg-brand px-3 text-sm py-2 font-semibold rounded">
                    <Preloader v-if="showPreloader"/>
                    <span v-else >Add Restaurant</span>      
                </button>
            </div>
        </form>
     </Modal>
  </Admin>
</template>
<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import Admin from "@/components/admin/AdminTemplate.vue";
import Table from "@/components/admin/Tables/ResturantsTable.vue";
import Preloader from "@/components/Preloader.vue";

import Modal from "@/components/Modal.vue";
export default {
    name:['AdminRestaurants'],
    components:{
        Admin,Table,Modal,Preloader,VueGoogleAutocomplete
    },
    data(){
        return{
            showModal:false,
            restaurantName:'',
            phone:"",
            email:"",
            error:null,
            resturantAddress:"",
            contactPerson:"", 
            vendors:null,
            miniTime:0,
            maxTime:0,
            filename:"",
            logoname:"",
            vendorId:"",   
            states:[],   
            stateId:"",
            cityId:"",
            cities:[],
            showPreloader:false,
            rows:['Name','Email Address','Phone number','Address','Contact Person','Status','Actions'],
            columns:[],
            page:0,
            size:10,
            msg:"Loading ...",
            search:""
        }
    },
    methods:{
        toggleModal(){
          this.showModal = !this.showModal
          this.error =null;  
        },
        async addResturant(){
            let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            let regexPhone = /^[0]\d{10}$/gm;
            if (!this.restaurantName){
                this.error = "Restaurant name is required"
            }else if(!this.email.match(regexEmail)){
                this.error = "Invaild email address"
            }else if(!regexPhone.test(this.phone)){
                this.error = "Invaild phone number"
            }else if(!this.contactPerson){
                this.error = "Contact person field is required"
            }      
            else if(!this.resturantAddress)  {
                this.error = "Restaurant address field is required"
            }else if(!this.address){
                this.error = "Restaurant address is not recognised by google"
            }else if(!this.vendorId ){
                this.error = "Vendor field is required"
            }else if(this.miniTime > this.maxTime || this.miniTime == this.maxTime){
                this.error = "Earliest Time is greater or equal to Latest Time"
            }else if(!this.stateId ){
                this.error = "State field is required"
            }else if(!this.cityId ){
                this.error = "Vendor field is required"
            }else if(!this.filename){
                this.error ="Upload an image";
            }
            else if(!this.logoname){
                this.error ="Upload a logo";
            }
            else{
                this.showPreloader = true;
                this.$refs.submit.disabled = true;                
                //Uplaod first
                let formData = new FormData();
                let file = this.$refs.headerImg.files[0];
                formData.append("file", file);
                let url = this.$store.state.base_url + 'file/upload/';
                let token = await this.$store.getters.getToken
                let res  = await this.axios.post(url,formData,{
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: 'Bearer ' + token
                    }
                })
                let image_url = res.data;
                formData = new FormData();
                let logo = this.$refs.headerLogo.files[0];
                formData.append("file", logo);
                url = this.$store.state.base_url + 'file/upload/';
                try{
                    res = await this.axios.post(url,formData,{
                        headers: {
                            Authorization: 'Bearer ' + token,
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    let logo_url = res.data;
                    let State = this.states.find(element => element.StateID == this.stateId);
                    let City = this.cities.find(element => element.CityID == this.cityId);
                    let data = {
                        "address": this.resturantAddress,
                        "contactPersonName": this.contactPerson,
                        "email": this.email,
                        "imageList": [
                            image_url
                        ],
                        "latitude": this.address.latitude,
                        "longitude": this.address.longitude,
                        "logo": logo_url,
                        "longestMealPreparationTime":parseInt(this.maxTime),
                        "phoneNumber": this.phone,
                        "restaurantName": this.restaurantName,
                        "restaurantOwnerId": this.vendorId,
                        "shortestMealPreparationTime": parseInt(this.miniTime),
                        "state":State.Name,
                        "city":City.Name,
                        "country":"NG"
                    }
                    url = this.$store.state.base_url + 'admin/new-restaurant';
                    try{
                        await this.axios.post(url,data,{
                            headers: {
                                Authorization: 'Bearer ' + token
                            }
                        })
                        await this.getAllResturants()
                        this.showPreloader = false;
                        this.$refs.submit.disabled = false;
                        this.toggleModal();
                    }
                    catch(error){
                        this.$refs.submit.disabled = false;
                        this.showPreloader = false;
                        this.error = error.response.data.message
                    }

                }catch(error){
                    console.log(error)
                }
            }
        },
        previewImg(event){
            let type = ["image/jpeg", "image/png", "image/gif"]
            if (type.find( (item) => item === event.target.files[0].type ) ) {
                this.$refs.imgHolder.classList.remove('hidden')
                this.$refs.preview.src = URL.createObjectURL(event.target.files[0])
                this.error = null;
                this.filename = event.target.files[0].name
            }else{
                this.$refs.imgHolder.classList.add('hidden')
                this.error = "Invaild file format";
            }
           
        },
        previewLogo(event){
            let type = ["image/jpeg", "image/png", "image/gif"]
            if (type.find( (item) => item === event.target.files[0].type ) ) {
                this.$refs.LogoHolder.classList.remove('hidden')
                this.$refs.logoPreview.src = URL.createObjectURL(event.target.files[0]);
                this.error = null;
                this.logoname = event.target.files[0].name;
            }else{
                this.$refs.LogoHolder.classList.add('hidden')
                this.error = "Invaild file format"
            }
           
        },
        getAddressData: function (addressData, placeResultData, id) {
            this.address = addressData;
            this.address.address = (addressData.street_number) ?`${addressData.street_number}, ${addressData.route}, ${addressData.locality}, ${addressData.country}`:`${addressData.route} ${addressData.locality}, ${addressData.country}`
            this.resturantAddress = this.address.address
            this.$refs.address.update(this.resturantAddress);
        },
        async getAllResturants(){
            let url = this.$store.state.base_url + 'admin/all-restaurant';
            try{
                let token = await this.$store.getters.getToken
                let res = await this.axios.get(url,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    },
                    params:{
                        searchTerm:this.search,
                        page:this.page,
                        size:this.size
                    }
                })
                this.columns = res.data;
            }catch(error){
                console.log(error)
            }
        },
        async getVendors(){
            let url = this.$store.state.base_url + 'admin/vendor';
            try {
                let token = await this.$store.getters.getToken
                let res = await this.axios.get(url,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                this.vendors = res.data
            } catch (error) {
                console.error(error)
            }
        },
        getStates(){
            let url = this.$store.state.dellyman_base_url + 'States'
            delete this.axios.defaults.headers.common['Authorization'];
            this.axios.get(url)
            .then((res)=>{
                this.states = res.data
            })
            .catch(err =>{
                console.error(err)
            })
        },
        findCities(){
            this.cityId = "";
            this.cities = [];
            let url = this.$store.state.dellyman_base_url + 'Cities'
            delete this.axios.defaults.headers.common['Authorization'];
            this.axios.get(url,{
                params:{
                "StateID": this.stateId
                }
            })
            .then((res)=>{
                this.cities = res.data
            })
        },
        async changePage(data){
            this.columns.content = [] 
            this.msg = "Loading ..."
            this.page = data.page
            this.size = data.size
            await this.getAllResturants()           
        },
        searchResult(data){
            this.search = data
            this.getAllResturants();
        }
    },
    beforeMount(){
        this.getAllResturants();
        this.getVendors();
        this.getStates();
    }
}
</script>

<style>
:root{
	--loader-width: 20px;
	--loader-height: 20px;
	--loader-color-primary: #000000;
	--loader-color-secondary: #eee;
	--line-width: 2px;
	--animation-duration: 2s;
	--loader-initial-scale: 0.1;
}

</style>