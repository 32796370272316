<template>
  <div class="flex justify-between mb-4">
    <div class="flex space-x-2">
      <p class="text-sm flex items-center">Rows per page</p>
      <select
        v-model="rowPerpage"
        class="border p-0.5 focus:outline-none"
        name=""
        id=""
      >
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
      </select>
    </div>
    <div class="flex space-x-3 border border-gray-100 p-0.5 px-3">
      <input
        type="text"
        @input="sortList"
        v-model="this.search"
        placeholder="Search"
        class="focus:outline-none p-2"
      />
      <button>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </button>
    </div>
  </div>
  <table class="w-full">
    <thead class="bg-gray-100 border-gray-100 border">
      <tr class="">
        <th v-for="row in rows" :key="row" class="text-sm py-3 px-3 text-left">
          {{ row }}
        </th>
      </tr>
    </thead>
    <tbody v-if="Resturants.length < 1">
      <tr class="border border-t-0">
        <td class="text-sm p-3 text-center" :colspan="rows.length">
          {{ msg }}
        </td>
      </tr>
    </tbody>
    <tbody>
      <tr
        v-for="col in Resturants.slice(startPage, Endpage)"
        :key="col"
        class="border border-t-0"
      >
        <td class="text-sm p-3">{{ col.name }}</td>
        <td class="text-sm p-3">{{ col.incentiveType }}</td>
        <td class="text-sm p-3">{{ col.incentiveValue }}</td>
        <td class="text-sm p-3">{{ col.requiredNumberOfOrders }}</td>
        <td class="text-sm p-3">{{ col.minRequiredOrderValue }}</td>
        <td class="text-sm p-3">
          {{ col.claimWindowDuration.value }} {{ col.claimWindowDuration.unit }}
        </td>
        <td class="text-sm p-3">{{ col.activationMode }}</td>
        <td class="text-sm p-3">{{ col.startDate }}</td>
        <td class="text-sm p-3">{{ col.endDate }}</td>
        <td class="w-32"></td>
      </tr>
    </tbody>
  </table>
  <div class="flex justify-between bg-gray-100 h-12 px-4 items-center">
    <div>
      {{ pageNumber + 1 }} of {{ numberofPages }} pages (Showing
      {{ getFirstItem }} - {{ getLastItem }} of
      {{ columns.totalElements }} Entries)
    </div>
    <div class="flex flex-grow justify-end">
      <div class="flex space-x-1">
        <button
          @click="prev(currentPage)"
          class="pagination-btn active text-sm"
        >
          Prev
        </button>
        <input
          type="number"
          :min="1"
          :max="numberofPages"
          :value="pageNumber + 1"
          @input="changeEnteredPage($event)"
          @change="changeEnteredPage($event)"
          class="border focus:outline-none w-14 border-gray-300 px-3"
        />
        <button
          @click="next(currentPage)"
          class="pagination-btn active text-sm"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import Preloader from "@/components/Preloader.vue";

export default {
  name: ["ReferralsTable"],
  props: ["rows", "columns", "states", "pageNumber", "msg"],
  components: {
    Modal,
    Preloader,
  },
  data() {
    return {
      search: "",
      error: null,
      errorServiceCharge: null,
      showPreloader: false,
      showModal: false,
      showeditModal: false,
      rowPerpage: 10,
      Resturants: [],
      showEditPreloader: false,
      showSetComission: false,
      singleResturantInfo: {},
      editRestaurantData: {},
      stateId: "",
      cityId: "",
      cities: [],
      Endpage: 10,
      startPage: 0,
      numberofPages: 0,
      currentPage: 1,
      comission: "",
      showComissionPreloader: false,
      assignRestaurant: {},
      showAssignModal: false,
      schemeId: "",
      serviceCharges: [],
    };
  },
  emits: ["cities", "changePage", "search"],
  computed: {
    getFirstItem() {
      return this.pageNumber * this.rowPerpage || 1;
    },
    getLastItem() {
      return this.pageNumber * this.rowPerpage + this.Resturants.length;
    },
  },
  methods: {
    sortList() {
      this.Resturants = [];
      this.$emit("search", this.search);
    },
    toggleeditModal() {
      this.showeditModal = !this.showeditModal;
      if (this.showeditModal) {
        setTimeout(() => {
          this.$refs.address.update(this.editRestaurantData.address);
        }, 300);
      }
    },
    async togglePublish(published, id) {
      let toogle = document.querySelector(`[data-name='restaurant${id}']`);
      let span = document.querySelector(
        `[data-name='restaurant${id}']`
      ).nextSibling;
      if (published) {
        //Unpublish
        toogle.classList.remove("bg-green-400");
        toogle.classList.add("bg-gray-300");
        toogle.lastElementChild.classList.remove("translate-x-5");
        span.innerText = "Inactive";
        this.Resturants.map((item) => {
          if (item.restaurantId == id) {
            item.available = false;
          }
        });
      } else {
        //publish
        toogle.classList.remove("bg-gray-300");
        toogle.classList.add("bg-green-400");
        toogle.lastElementChild.classList.add("translate-x-5");
        span.innerText = "Published";
        this.Resturants.map((item) => {
          if (item.restaurantId == id) {
            item.available = true;
          }
        });
      }
      let url =
        this.$store.state.base_url + "restaurant/" + id + "/change-status";
      let token = await this.$store.getters.getToken;
      this.axios.post(url, null, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },

    editModal(id) {
      this.editRestaurantData = this.columns.content.find(
        (item) => item.restaurantId === id
      );
      this.toggleeditModal();
    },
    async updateRestaurant() {
      let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      let regexPhone = /^[0]\d{10}$/gm;
      let RestaurantData = this.columns.content.find(
        (item) => item.id === this.editRestaurantData.id
      );
      if (!this.editRestaurantData.name) {
        this.error = "Restaurant name is required";
      } else if (!this.editRestaurantData.email.match(regexEmail)) {
        this.error = "Invaild email address";
      } else if (!regexPhone.test(this.editRestaurantData.phoneNumber)) {
        this.error = "Invaild phone number";
      } else if (!this.editRestaurantData.contactPersonName) {
        this.error = "Contact person field is required";
      } else if (!this.editRestaurantData.address) {
        this.error = "Restaurant address field is required";
      } else if (
        !this.address &&
        RestaurantData.address !== this.editRestaurantData.address
      ) {
        this.error = "Restaurant address is not recognised by google";
      } else if (
        this.editRestaurantData.shortestMealPreparationTime >
          this.editRestaurantData.longestMealPreparationTime ||
        this.editRestaurantData.shortestMealPreparationTime ==
          this.editRestaurantData.longestMealPreparationTime
      ) {
        this.error = "Earliest Time is greater or equal to Latest Time";
      } else {
        this.showEditPreloader = true;
        if (this.filename || this.logoname) {
          if (this.filename) {
            //Upload Restaurant banner first
            let formData = new FormData();
            let file = this.$refs.headerImg.files[0];
            formData.append("file", file);
            let url = this.$store.state.base_url + "file/upload/";
            let token = await this.$store.getters.getToken;
            let res = await this.axios.post(url, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + token,
              },
            });
            this.filenameUrl = res.data;
            this.putRestaurant(RestaurantData);
          }
          if (this.logoname) {
            let formData = new FormData();
            let logo = this.$refs.headerLogo.files[0];
            formData.append("file", logo);
            let url = this.$store.state.base_url + "file/upload/";
            let token = await this.$store.getters.getToken;
            let res = await this.axios.post(url, formData, {
              headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "multipart/form-data",
              },
            });

            this.logonameUrl = res.data;
            this.putRestaurant(RestaurantData);
          }
        } else {
          this.putRestaurant(RestaurantData);
        }
      }
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    prev() {
      if (this.pageNumber - 1 >= 0) {
        this.Resturants = [];
        this.$emit("changePage", {
          pageNumber: this.pageNumber - 1,
          pageSize: this.rowPerpage,
        });
      }
    },
    next() {
      if (this.pageNumber + 1 < this.columns.totalPages) {
        this.Resturants = [];
        this.$emit("changePage", {
          pageNumber: this.pageNumber + 1,
          pageSize: this.rowPerpage,
        });
      }
    },
    changeEnteredPage($event) {
      this.Resturants = [];
      this.$emit("changePage", {
        pageNumber: parseInt($event.target.value) - 1,
        pageSize: this.rowPerpage,
      });
    },
  },
  mounted() {},
  watch: {
    columns: function () {
      this.Resturants = this.columns.content;
      this.numberofPages = this.columns.totalPages;
    },
    rowPerpage: function () {
      this.Resturants = [];
      this.$emit("changePage", {
        pageNumber: 0,
        pageSize: this.rowPerpage,
      });
    },
  },
};
</script>