<template>
    <ul class="p-2 mt-2 bg-white rounded transition duration-300 ">
        <li v-for="link in links" :key="link" class="flex space-x-3 py-0.5">
            <div class="w-4 h-4"></div>
            <router-link :to="link.url" class="text-sm hover:text-brand transition ease-in-out duration-300" >{{ link.name }}</router-link>
        </li>
    </ul>
</template>

<script>
export default {
    name:['Collapse'],
    props:['links']
}
</script>