<template>
        <Auth>
            <div class="md:mx-14 mx-3 w-screen h-screen  flex items-center">
                <div class="flex-grow lg:flex-grow-0">
                    <h1 class="font-semibold text-4xl" >Log in</h1>
                    <p class="mt-4">Welcome back ! Please login to your account</p>
                    <form @submit.prevent="LoginUser" class="mt-4">
                        <div v-if="error.empty" class="text-sm text-red-500 my-4" >{{ error.empty }}</div>
                        <div v-if="success" class="text-sm text-green-500 my-4" >{{ success }}</div>
                        <div>
                            <label for="email">Email Address</label>
                            <input type="text" v-model="email" class="input" placeholder="Email Address">
                            <span v-if="error.email" class="text-sm text-red-500 my-4">{{ error.email }}</span>
                        </div>
                        <div class="mt-2" >
                            <label for="password">Password</label>
                            <input type="password" v-model="password" class="input" placeholder="Password"  autocomplete="off" >
                        </div>
                        <div class="mt-4" >
                            <button ref="loginBtn" class="bg-brand px-3 py-2 font-semibold rounded">
                                <Preloader v-if="showPreloader"/>
                                <span v-else >Log in</span> 
                            </button>
                        </div>
                    </form>
                </div>        
            </div>
        </Auth>
</template>
<script>
import Auth from "@/components/admin/Auth.vue";

import Preloader from "@/components/Preloader.vue";
export default {
    name:['Login'],
    components:{
        Auth,Preloader
    },
    data(){
    return{
            email:"",
            password:"",
            success:null,
            showPreloader:false,
            error:{
                email:null,
                password:null,
                empty:null,
            }
        }
    },
    methods: {
        async LoginUser(){
            if (!this.email || !this.password ) {
                this.error.empty = "Email and  password is required"
            }else{
                let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                this.error.empty = null
                this.error.email = (!this.email.match(regexEmail)) ? "Invaild email entered" : null; 
            }
            //Send credentials 
            if (!this.error.empty && !this.error.email) {
                this.$refs.loginBtn.disabled = true;
                this.showPreloader = true;
                let data = {
                    email:this.email,
                    password:this.password,
                    authenticationDomain: "NOSHTRYBE_BACKOFFICE",
                    stayLoggedIn: true
                }
                let url = this.$store.state.base_url + 'admin/login';
                try {
                    let res = await this.axios.post (url,data)      
                    this.showPreloader = false;
                    this.success = res.data.message;
                    var CryptoJS = require("crypto-js");
                    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(res.data), process.env.VUE_APP_SECRET_PHRASE).toString();
                    this.$store.dispatch('addUserToken',ciphertext);
                    setTimeout(() => {
                        this.$router.push({name: "Dashboard"})
                    }, 1000);
                } catch (err) {
                    this.showPreloader = false;
                    this.$refs.loginBtn.disabled = false;
                    let message = err.response.data.message;
                    this.error.empty = (message) ? message : null;
                }
            }
        }
    }
}
</script>

<style>
:root{
	--loader-width: 20px;
	--loader-height: 20px;
	--loader-color-primary: #000000;
	--loader-color-secondary: #eee;
	--line-width: 2px;
	--animation-duration: 2s;
	--loader-initial-scale: 0.1;
}
</style>