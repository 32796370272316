<template>
    <div class="flex justify-between mb-4" >
        <div class="flex space-x-2">
            <p class="text-sm flex items-center">Rows per page</p>
            <select v-model="rowPerpage" class="border p-0.5 focus:outline-none " name="" id="">
                <option value="10">10</option>
                <option value="25" >25</option>
                <option value="50" >50</option>
            </select>
        </div>
        <div class="flex space-x-3 border border-gray-100 p-0.5 px-3">
            <input type="text" @input="sortList" v-model="search" placeholder="Search" class="focus:outline-none" >
            <button>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
            </button>
        </div>
    </div>
    <table class="w-full" >
        <thead class="bg-gray-100 border-gray-100 border">
            <tr class="">
                <th v-for="row in rows" :key="row" class="text-sm py-3 px-3 text-left" >
                   <div class="flex justify-between" >
                        <p>{{ row }}</p>
                        <div class="flex items-center" >
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7l4-4m0 0l4 4m-4-4v18" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 17l-4 4m0 0l-4-4m4 4V3" />
                            </svg>
                        </div>
                   </div>
                    
                </th>
            </tr>
        </thead>
        <tbody v-if="meals.length < 1">
            <tr class="border border-t-0" >
                <td  class="text-sm p-3 text-center" :colspan="rows.length">{{ msg }}</td>  
            </tr>
        </tbody>
        <tbody v-else>
            <tr v-for="col in meals.slice(startPage, Endpage)" :key="col" class="border border-t-0">
                <td class="text-sm p-3 w-2/12" >{{ col.name }}</td>
                <td class="text-sm p-3" >{{ col.restaurant.name }}</td>
                <td class="text-sm p-3">&#8358;{{ formatNumber(col.price) }} </td>
                <td class="text-sm p-3 w-44">
                    <div class="grid grid-cols-2 gap-2">
                        <div v-for="timebelt in col.timeBelts" :key="timebelt">
                            <span v-if="timebelt.name ==='Instant'" >
                                <span  class="bg-purple-400 px-2 py-1 text-xs rounded-full" >{{ timebelt.name }}</span>
                            </span>
                            <span v-else-if="timebelt.name === 'Breakfast'" >
                                <span class="bg-red-400 px-2 py-1 text-xs rounded-full" >{{ timebelt.name }}</span>
                            </span>
                            <span v-else-if="timebelt.name === 'Lunch' " >
                                <span class="bg-yellow-400 px-2 py-1 text-xs rounded-full" >{{ timebelt.name }}</span>
                            </span>
                            <span v-else-if="timebelt.name === 'Brunch' " >
                                <span class="bg-green-400 px-2 py-1 text-xs rounded-full" >{{ timebelt.name }}</span>
                            </span>
                            <span v-else-if="timebelt.name === 'Dinner' " >
                                <span class="bg-indigo-400 px-2 py-1 text-xs rounded-full" >{{ timebelt.name }}</span>
                            </span>                
                        </div>
                    </div>
                </td>
                <td class="text-sm p-3">{{ changeDate(col.createdAt) }} </td>
                 <td class="text-sm p-3">
                     <button @click="togglePublish($event,col.available,col.foodId)" :data-name ="'food'+col.foodId" :class="col.available ? 'bg-green-400' : null" class="border border-gray-200 bg-gray-200 h-5 w-10 flex items-center rounded-full" >
                        <div :class="col.available ? 'translate-x-5' : null" class="h-4 w-4 flex bg-white rounded-full transition duration-500 transform" ></div>
                     </button>
                     <span v-if="col.available" class="text-xs" >Published</span>
                     <span v-else>Unpublished</span>
                 </td>
                <td class="w-1/12" >
                    <button @click="editMeal(col.foodId)" >
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                        </svg>
                    </button>
                    <button @click="getSingleMeal(col.foodId)" class="text-purple-500" >
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                      </svg>
                    </button>
                    <button @click="toogledelete(col.foodId)" class="text-red-500" >
                       <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="flex justify-between bg-gray-100 h-12 px-4  items-center " >
       <div> {{ page + 1 }} of {{ numberofPages }} pages (Showing {{ getFirstItem }} - {{ getLastItem }} of {{ columns.totalElements }} Entries)</div>
        <div class="flex flex-grow  justify-end" >
            <div class="flex space-x-1">
                <button @click="prev()" class="pagination-btn active text-sm">Prev</button>
                <input type="number" :min="1" :max="numberofPages" :value="page + 1 " @input="changeEnteredPage($event)"  @change="changeEnteredPage($event)"  class="border focus:outline-none w-14 border-gray-300  px-3 ">
                <!-- <div  class="border border-gray-300  px-3 py-0.5" > {{ page + 1 }} </div> -->
                <button @click="next()" class="pagination-btn active text-sm">Next</button>
            </div>
        </div>
    </div>

    <Modal v-if="showModal" width="w-768" :show="true">
        <div class="flex justify-between">
            <h1 class="text-xl font-semibold">View Meal</h1>
            <div @click="toggleModal">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div>
        <div class="grid grid-cols-3 mt-4 gap-4" >
            <div>
                <p class="text-sm" >Meal Name</p>
                <p class="font-semibold" >{{ singleMeal.name }}</p>
            </div>
            <div>
                <p class="text-sm" >Price</p>
                <p class="font-semibold" >N{{ formatNumber(singleMeal.price) }}</p>
            </div>
            <div>
                <p class="text-sm" >Preparation time</p>
                <p  v-if="singleMeal.preparationTime" class="font-semibold" >{{ singleMeal.preparationTime  }} mins</p>
                <p v-else class="font-semibold" >Completely Scheduled</p>
            </div>
            <div>
                <p class="text-sm" >Restaurant</p>
                 <p class="font-semibold" >{{singleMeal.restaurant.name }}</p>  
            </div>
              <div>
                <p class="text-sm" >Date added</p>
                 <p class="font-semibold" >{{singleMeal.preparationTime }} mins</p>  
            </div>
        </div>
        <div class="mt-4" >
            <p class="text-sm" >Timebelts</p>
            <div class="flex space-x-3">
                <div  v-for="timebelt in singleMeal.timeBelts" :key="timebelt">
                    <span v-if="timebelt.name ==='Instant'" >
                        <span  class="bg-purple-400 px-2 py-1 text-xs rounded-full" >{{ timebelt.name }}</span>
                    </span>
                    <span v-else-if="timebelt.name === 'Breakfast'" >
                        <span class="bg-red-400 px-2 py-1 text-xs rounded-full" >{{ timebelt.name }}</span>
                    </span>
                    <span v-else-if="timebelt.name === 'Lunch' " >
                        <span class="bg-yellow-400 px-2 py-1 text-xs rounded-full" >{{ timebelt.name }}</span>
                    </span>
                    <span v-else-if="timebelt.name === 'Brunch' " >
                        <span class="bg-green-400 px-2 py-1 text-xs rounded-full" >{{ timebelt.name }}</span>
                    </span>
                    <span v-else-if="timebelt.name === 'Dinner' " >
                        <span class="bg-indigo-400 px-2 py-1 text-xs rounded-full" >{{ timebelt.name }}</span>
                    </span>                
                </div> 
            </div>

        </div>
        <div class="mt-4" >
            <p class="font-semibold" >Description</p>
            <p>{{ singleMeal.description }} </p>
        </div>
        <div class="mt-4 flex-grow flex space-x-2 justify-end">
            <button @click="toggleModal" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                Close
            </button>
        </div>
    </Modal>
    <Modal v-if="showeditModal" width="w-768" :show="true">
        <div class="flex justify-between">
            <h1 class="text-xl font-semibold">Edit Meal</h1>
            <div @click="toggleeditModal">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div>
         <div v-if="error" class="text-sm text-red-500 mt-3">{{ error }}</div>
        <form @submit.prevent="updateMeal" method="post">
                <div class="grid grid-cols-2 gap-2 mb-3">
                    <div>
                        <label for="mealname" class="text-sm" >Meal name</label>
                        <input type="text" v-model="editMealname" class="input" placeholder="Meal name">
                    </div>
                    <div>
                        <label for="price" class="text-sm">Price</label>
                        <input type="number" v-model="editprice" class="input" placeholder="Price">
                    </div>
                </div>
                <div>
                    <div class="flex">
                        <label for="description" class="text-sm" >Description</label>
                        <span class="text-sm flex-grow flex justify-end" >{{ editdescription.length }}/200</span>
                    </div>
                    <textarea @input="checklenght" maxlength="200" type="text" v-model="editdescription" cols="10" rows="2" class="input" placeholder="Enter Description"></textarea>
                </div>
                <div>
                    <label for="" class="text-sm" >Select timebelt</label>
                    <div class="flex space-x-4" >
                       <div v-for="timebelt in timebelts" :key="timebelt">
                            <input type="checkbox"  v-model="editSendtimeBelts" :value="timebelt.id">
                             {{ timebelt.name }}
                        </div>
                    </div>
                </div>
                <div v-if="editSendtimeBelts.includes(5)" >
                    <label for="preparationTime" class="text-sm" >Time(Mins)</label>
                    <input type="text" min="1" v-model="editpreparationTime " class="input" placeholder="Time">
                </div>
                <div class="mt-4 flex-grow flex space-x-2 justify-end">
                    <button @click="toggleeditModal" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                        Close
                    </button>
                    <button type="submit" ref="submit" class="bg-brand px-3 text-sm py-2 font-semibold rounded">
                        <Preloader v-if="showPreloader"/>
                        <span v-else>Update meal</span>      
                    </button>
                </div>
        </form>
    </Modal>
    <Modal v-if="deleteModal" :show="true">
       <h1 class="text-2xl font-semibold">Are you sure</h1> 
       <p class="py-2" >Do you really want to delete this record? This action cannot be undone</p>
        <div class="mt-4 flex-grow flex space-x-2 justify-end">
            <button @click="toogledelete" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                No
            </button>
            <button @click="deleteMeal"  type="submit" ref="submit" class="bg-brand px-3 text-sm py-2 font-semibold rounded">
                <Preloader v-if="showPreloader"/>
                <span v-else>Yes</span>      
            </button>
        </div>
    </Modal>
</template>

<script>

import Preloader from "@/components/Preloader.vue";
import moment from "moment";
import Modal from "@/components/Modal.vue";
export default {
    name:['Table'],
    props:['rows','columns','timebelts','page','msg'],   
    emits:['changePage','search'], 
    components:{
        Modal,Preloader
    }, 
    data(){
        return{
            toggleValue: false,
            search:"",
            showPreloader:false,
            deleteModal:false,
            error:'',
            showModal:false,
            searchResult: [],
            meals:[],
            singleMeal:[],
            rowPerpage:10,
            Endpage: 10,
            startPage : 0,
            currentPage:1,
            numberofPages:0,
            showeditModal:false,
            editdescription:"",
            editSendtimeBelts:[]
        }
    },
    computed:{
        getFirstItem(){
            return this.page * this.rowPerpage || 1
        },
        getLastItem(){
            return  this.page * this.rowPerpage + this.meals.length
        },
    },
    methods:{
        formatNumber(num) {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        },
        toggleModal(){
            this.showModal = !this.showModal
        },
         toggleeditModal(){
            this.showeditModal = !this.showeditModal
        },
        editMeal(id){
            let singleMeal = this.meals.find( (item) => item.foodId === id);
            this.mealId = singleMeal.foodId 
            this.editRestaurantid = singleMeal.restaurant.id
            this.editMealname = singleMeal.name
            this.editprice = singleMeal.price;
            this.editdescription = singleMeal.description
            this.editSendtimeBelts = singleMeal.timeBelts.map((item) =>item.id);
            this.editpreparationTime = singleMeal.preparationTime;
            this.editImages = singleMeal.images[0].image;
            this.toggleeditModal();
        },
        getSingleMeal(id){
            let singleMeal = this.meals.find( (item) => item.foodId === id);
            this.singleMeal = singleMeal
            this.toggleModal();
        },
        async togglePublish($event,published,id){
            let toogle = document.querySelector(`[data-name='food${id}']`);
            let span = document.querySelector(`[data-name='food${id}']`).nextSibling;
            if (published) {
                //Unpublish
                toogle.classList.remove('bg-green-400');
                toogle.classList.add('bg-gray-300');
                toogle.lastElementChild.classList.remove('translate-x-5')
                span.innerText = "Unpublished" 
                this.meals.map(item =>{
                   if (item.foodId == id){
                       item.available = false
                   }
                })
            }else{
                //publish
                toogle.classList.remove('bg-gray-300');
                toogle.classList.add('bg-green-400');
                toogle.lastElementChild.classList.add('translate-x-5')
                span.innerText = "Published" 
                this.meals.map(item =>{
                   if (item.foodId == id){
                       item.available = true;
                   }
                })
            }
            let url = this.$store.state.base_url + 'food/'+ id + '/change-status';
            let token = await this.$store.getters.getToken
            this.axios.post(url,null,{
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
        },
        toogledelete(id){
            this.deleteModal = !this.deleteModal
            this.deleteid = id
        },
        async deleteMeal(){
            let url = this.$store.state.base_url + 'food/delete/'+ this.deleteid ;
            let token = await this.$store.getters.getToken
            this.axios.delete(url,{
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
            this.meals.filter((item)=> item.foodId !== this.deleteid )
            this.deleteModal = false;
        },
        async updateMeal(){
            this.error = (!this.editMealname || !this.editprice || !this.editdescription || this.editSendtimeBelts.length < 1 ) ? "Invaild details in the form" : null
            if (!this.error) {
                this.showPreloader = true;
                this.$refs.submit.disabled = true;
                let data = {
                    "amount": parseInt(this.editprice),
                    "available": true,
                    "description": this.editdescription,
                    "foodCategoriesId": [],
                    "foodImages": [
                        this.editImages 
                    ],
                    "name": this.editMealname,
                    "preparationTime": this.editpreparationTime,
                    "restaurantId": this.editRestaurantid ,
                    "timeBeltsId": this.editSendtimeBelts
                }
                let url = this.$store.state.base_url + 'admin/food/' + this.mealId+ '/update';
                try {
                    let token = await this.$store.getters.getToken
                    let res = await this.axios.put(url,data,{
                        headers: {
                            Authorization: 'Bearer ' + token
                        }
                    })
                    let newTimebelt = this.timebelts.filter((item) => this.editSendtimeBelts.find((timebeltId) => timebeltId == item.id))
                    this.meals.map( (item) => {
                        if(item.foodId === this.mealId){
                            item.description = data.description;
                            item.price = data.amount;
                            item.name = data.name; 
                            item.timeBelts = newTimebelt;
                            item.preparationTime = data.preparationTime; 
                        }
                    });
                    this.showPreloader = false;
                    this.$refs.submit.disabled = false;
                    this.toggleeditModal();
                } catch (error) {
                    this.$refs.submit.disabled = false;
                    this.showPreloader = false;
                    this.error = err.response.data.message
                }
            }
        },
        prev(){
            if(this.page - 1 >= 0){
                this.meals = []
                this.$emit('changePage',{
                    page: this.page - 1,
                    size: this.rowPerpage
                })
            }
        },
        next(){
            if(this.page + 1 < this.columns.totalPages){
                this.meals = []
                this.$emit('changePage',{
                    page: this.page + 1,
                    size: this.rowPerpage
                })
            }
        },
        changeEnteredPage($event){
            this.meals = []
            this.$emit('changePage',{
                page: parseInt($event.target.value) - 1,
                size: this.rowPerpage
            })
        },
        changeDate(date){
           return moment(date).format('llll');
        },
        sortList(){
            this.meals = []
            this.$emit('search',this.search)
        }
    },
    watch: {
        columns: function () {
            this.meals = this.columns.content
            this.numberofPages =this.columns.totalPages
        },
        rowPerpage : function () {
            this.meals = []
            this.$emit('changePage',{
                page: 0,
                size: this.rowPerpage
            })
        }
    },
}
</script>

<style>

</style>