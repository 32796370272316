<template>
    <Admin>
        <div class="flex justify-between">
            <div class="flex" > 
                <div class="h-2 w-2 bg-brand" ></div>  
                 <h1 class="font-semibold text-3xl" >Rejection Reason</h1>
            </div>
            <div class="flex space-x-3 items-center" >
                <button @click="toggleModal" class="p-3 bg-brand rounded flex space-x-2" >
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    <p class="text-sm font-semibold" >Add Rejection Reason</p>
                </button>
            </div>
        </div>
        <div class="mt-8 pb-8" >
            <Table :rows="rows" :msg="msg" :columns="columns"/>
        </div>
    </Admin>
    <Modal v-if="showModal" width="w-500"  :show="true">
        <div class="flex justify-between">
            <h1 class="text-xl font-semibold">Add Rejection Reason</h1>
            <div @click="toggleModal">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div>  
        <div v-if="error" class="text-sm text-red-500 mt-3">{{ error }}</div>
        <form @submit.prevent="addReason">
            <textarea class="input" name="" id="" placeholder="Reason Comment" v-model="reason" cols="30" rows="10"></textarea>
            <div class="mt-4 flex-grow flex space-x-2 justify-end">
                <button @click="toggleModal" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                    Close
                </button>
                <button type="submit" ref="submit" class="bg-brand text-black px-3 text-sm py-2 font-semibold rounded">
                    <Preloader v-if="showPreloader"/>
                    <span v-else >Add Rejection Reason</span>      
                </button>
            </div>  
        </form>
    </Modal>
</template>
<script>
import Admin from "@/components/admin/AdminTemplate.vue";
import Modal from "@/components/Modal.vue";
import Preloader from "@/components/Preloader.vue";
import Table from "@/components/admin/Tables/ReasonTable.vue";

    export default{
        name:"Reason",
        components:{
            Admin,Modal,Preloader,Table
        },
        data(){
            return{
                columns:[],
                rows:['Rejection Reasons', 'Date Added','Action'],
                showModal:false,
                error:null,
                reason:"",
                showPreloader:false,
                msg:"Loading ..."
            }
        },
        methods:{
            toggleModal(){
                this.showModal = !this.showModal
                this.error =null; 
            },
            async addReason(){
                if(!this.reason){
                    this.error = "Reasons Comment is required"
                }else{
                    this.error = null
                    this.showPreloader = true
                    let url = this.$store.state.base_url + `admin/order-rejection-reason`;
                    let data ={
                        "id": 0,
                        "reason": this.reason
                    }
                    let token = await this.$store.getters.getToken
                    let res = await this.axios.post(url,data,{
                        headers: {
                            Authorization: 'Bearer ' + token
                        }
                    })
                    this.getReasons("newdata")

                }
            },
            async getReasons(data){
                let url = this.$store.state.base_url + `order-rejection-reason`;
                let token = await this.$store.getters.getToken
                let res = await this.axios.get(url,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
  
                this.columns = res.data;
                if (this.msg.length > 1) {
                    this.msg = "No data found";
                } 
                if(data){
                    this.showPreloader = false;
                    this.reason = null;
                    this.toggleModal() 
                }
            }
        },
        beforeMount(){
            this.getReasons();
        }
    }
</script>