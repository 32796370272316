<template>
  <Admin>
        <div class="flex justify-between">
            <div class="flex" > 
                <div class="h-2 w-2 bg-brand" ></div>  
                 <h1 class="font-semibold text-3xl" >Timebelts</h1>
            </div>
            <div class="flex space-x-3 items-center" >
                <button @click="toogleCreate"  class="p-3 bg-brand rounded flex space-x-2" >
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </div>
                <p class="text-sm" >Create Timebelt</p>
                </button>
            </div>
        </div>
        <Table :rows="rows" :columns="columns" :timebeltNames="timebeltNames" :msg="msg"/>
  </Admin>
  <Modal v-if="showcreateModal" width="w-500" :show="true">
        <div class="flex justify-between">
            <h1 class="text-xl font-semibold">Create Timebelt</h1>
            <div @click="toogleCreate">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div> 
        <div v-if="error" class="text-sm text-red-500">{{ error }}</div>
        <form @submit.prevent="addTimebelt" ref="formTimebelt" class="mt-3" method="post">
            <div>
                <select class="input" v-model="timebeltName">
                    <option value="">Select Timebelt Name</option>
                    <option v-for="name in timebeltNames" :key="name" :value="name">{{ name }}</option>
                </select>
            </div>
            <div class="grid grid-cols-2 gap-2 mt-3">
                <select class="input" v-model="startTime">
                    <option value="">Start time</option>
                    <option v-for="name in 12" :key="name" :value="name">{{ name }}</option>
                </select>
                <select class="input" v-model="startType">
                    <option value="am">AM</option>
                    <option value="pm">PM</option>
                </select>
            </div>
            <div class="grid grid-cols-2 gap-2 mt-3">
                <select class="input" v-model="endTime">
                    <option value="">End time</option>
                    <option v-for="name in 12" :key="name" :value="name">{{ name }}</option>
                </select>
                <select class="input" v-model="endType">
                    <option value="am">AM</option>
                    <option value="pm">PM</option>
                </select>
            </div>
            <div class="mt-4 flex-grow flex space-x-2 justify-end">
                <button @click="toogleCreate" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                    Close
                </button>
                <button type="submit" :disabled="showPreloader" ref="submit" class="bg-brand px-3 text-sm py-2 font-semibold rounded">
                    <Preloader v-if="showPreloader"/>
                    <span v-else>Create Timebelt</span>      
                </button>
            </div>
        </form>
    </Modal>
</template>

<script>
import Admin from "@/components/admin/AdminTemplate.vue";
import Modal from "@/components/Modal.vue";
import Preloader from "@/components/Preloader.vue";
import Table from "@/components/admin/Tables/TimebeltsTable.vue"

export default {
    name:"Settlements",
    components:{
        Admin,Modal,Preloader,Table
    },
    data(){
        return{
            rows:['Name','Start time','End time', 'Actions'],
            columns:[],
            showcreateModal:false,
            showPreloader:false,
            error:"",
            startTime:"",
            endTime:"",
            endType:"pm",
            timebeltName:"",
            startType:"am",
            deliveryWindow:0,
            msg:"Loading ...",
            timebeltName:"",
            timebeltNames:['Breakfast','Lunch','Brunch','Dinner']
        }
    },
    methods: {
        toogleCreate(){
            this.showcreateModal = !this.showcreateModal
            this.timebeltName = this.endTime = this.startTime = "";
            //this.$refs.formTimebelt.reset()
        },
        async addTimebelt(){
            if (!this.timebeltName || !this.startTime || !this.endTime){
                this.error = 'Invaild details in the form'
            }
            else{
                this.showPreloader = true;
                this.$refs.submit.disabled = true;
                let data = {
                    name : this.timebeltName ,
                    startTime:this.startTime + this.startType ,
                    endTime: this.endTime + this.endType
                }    
                let url = this.$store.state.base_url + 'time-belt/new-timebelt';
                try {
                    let token = await this.$store.getters.getToken;
                    let res = await this.axios.post(url,data,{
                            headers: {
                                Authorization: 'Bearer ' + token
                            }
                        })
                    this.showPreloader = false;
                    await this.getAllTimebelt();  
                    this.toogleCreate();
                } catch (error) {
                    this.showPreloader = false;
                }
            }
        },
        async getAllTimebelt(){
            let url = this.$store.state.base_url + 'time-belt';
            let token = await this.$store.getters.getToken;
            let res  = await this.axios.get(url,{
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });
            this.columns = res.data;       
        }
        
    },
    mounted(){
        this.getAllTimebelt();
    }

}
</script>

<style>

</style>