<script>
import Admin from "@/components/admin/AdminTemplate.vue";
import Modal from "@/components/Modal.vue";
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import Preloader from "@/components/Preloader.vue";
import Table from "@/components/admin/Tables/ZoneTable.vue"

export default {
    name:"Zones",
    components:{
        Admin,Modal,Preloader,Table,VueGoogleAutocomplete
    },
    data(){
        return {
            showModal:false,
            address:{
                address:""
            },
            description:"",
            error:"",
            radius:"",
            showPreloader:false,
            page:0,
            size:10,
            columns:{
                totalPages:0,
                content:[]
            },
            msg:"",
            name:"",
            search:""
        }
    },  
    methods:{
        toggleModal(){
            this.showModal = !this.showModal
            this.address = {}
            this.description = this.radius = ""
        },
        getAddressData: function (addressData, placeResultData, id) {
            this.address = addressData;
            this.address.address = (addressData.street_number) ?`${addressData.street_number}, ${addressData.route}, ${addressData.locality}, ${addressData.country}`:`${addressData.route} ${addressData.locality}, ${addressData.country}`
            this.$refs.address.update(this.resturantAddress);
        },
        async createZone(){

            if(!this.address.address){
                return this.error  = "Enter your desired address"
            }

            if(!this.radius){
                return this.error  = "Enter your radius address"
            }


            let payload = {
                "centralLocationAddress": this.address.address,
                "description": this.description,
                "latitude": this.address.latitude,
                "longitude": this.address.longitude,
                "name": this.name,
                "radiusInKm": this.radius
            }

            try{
                this.showPreloader = true;
                let url = this.$store.state.base_url + 'restaurant-availability-zone';
                let token = await this.$store.getters.getToken
                let res = await this.axios.post(url,payload,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                });
                this.toggleModal();
                this.getZones()
            }catch(error){
                this.showPreloader = false;
                console.log(error)
            }
        },
        async getZones(){
            let url = this.$store.state.base_url + 'restaurant-availability-zone';
            try{
                let token = await this.$store.getters.getToken
                let res = await this.axios.get(url,{
                    headers: {
                        Authorization: 'Bearer ' + token
                    },
                    params:{
                        name:(this.search) ? this.search :"",
                        page:this.page,
                        size:this.size
                    }
                })
                this.columns = res.data;
                this.msg = "No data found";
            }catch(error){
                console.log(error)
            }
        },
        async changePage(data){
            this.columns.content = [] 
            this.msg = "Loading ..."
            this.page = data.page
            this.search = (data.name) ? data.name : ""
            this.size = data.size
            await this.getZones()           
        }

    },
    mounted(){
        this.getZones()
    }

}
</script>

<template>
    <Admin>
        <div class="flex justify-between">
            <div class="flex"> 
                <div class="h-2 w-2 bg-brand" ></div>  
                 <h1 class="font-semibold lg:text-3xl text-xl" >Zones</h1>
            </div>
            <div>
                <button @click="toggleModal" class="p-3 bg-brand rounded flex space-x-2" >
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </div>
                    <p class="text-sm" >Add Zone</p>
                </button>
            </div>
        </div>
        <div class="my-8">
            <Table :columns="columns" :msg="msg" :page="page" @changePage="changePage"/>
        </div>
    </Admin>
    <Modal v-if="showModal" width="w-500"  :show="true">
        <div class="flex justify-between">
            <h1 class="text-xl font-semibold">Add Zone</h1>
            <div @click="toggleModal">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </div>  
        <div v-if="error" class="text-red-500 my-4">
            {{ error  }}
        </div>
        <form  @submit.prevent="createZone" class="mt-3">
            <div class="mb-3"> 
                <label for="name" class="text-sm" >Zone Name</label>
                <div>
                    <input type="text" placeholder="Zone Name" class="input" v-model="name" name="name" id="name">
                </div>
            </div>
            <div class="mb-3">
                <label for="address" class="text-sm" >Address</label>
                <vue-google-autocomplete
                    ref="address"
                    id="map"
                    placeholder="Enter zone address"
                    classname="input" 
                    v-on:placechanged="getAddressData"
                    country="ng"
                    v-model="address"
                    autocomplete="off" 
                ></vue-google-autocomplete> 
            </div>
            <div class="mb-3"> 
                <label for="radius" class="text-sm" >Radius (KM)</label>
                <div>
                    <input type="number" placeholder="Radius (KM)" class="input" v-model="radius" name="radius" id="radius">
                </div>
            </div>
            <div>
                <label for="description" class="text-sm" >Description</label>
                <textarea name="" id="description" v-model="description" placeholder="Description"  class="input" cols="30" rows="5"></textarea>
            </div>
            <div class="mt-4 flex-grow flex space-x-2 justify-end">
                <button @click="toggleModal" class="bg-gray-300 px-3 text-sm py-2 font-semibold rounded">
                Close
                </button>
                <button type="submit" ref="submit" class="bg-brand px-3 text-sm py-2 font-semibold rounded">
                    <Preloader v-if="showPreloader"/>
                    <span v-else >Add Zone</span>      
                </button>
            </div>  
        </form>
    </Modal>
  
</template>
